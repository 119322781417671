import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-baixar-app',
  templateUrl: './baixar-app.component.html',
  styleUrls: ['./baixar-app.component.scss'],
})
export class BaixarAppComponent implements OnInit {

  @Output() downloadApp: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private platform: Platform,
    private utilsService: UtilsService,
  ) {

  }

  ngOnInit() { }

  downloadAppLink() {
    if (this.platform.is('iphone') || this.platform.is('ios')) {
      this.utilsService.link('https://apps.apple.com/br/app/fome-de-q/id1642530822');
    } else {
      this.utilsService.link('https://play.google.com/store/apps/details?id=br.com.trigo.app');
    }
  }

  fechar() {
    this.downloadApp.emit(false);
  }
}
