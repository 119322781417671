import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class AutenticacaoService {
  constructor(private utils: UtilsService, private platform: Platform) {}

  async canActivate(): Promise<boolean> {
    const usuario = this.utils.getStorage('usuario');

    await this.platform.ready().then(async () => {
      if (usuario !== null) {
        this.utils.navegarPaginaRoot('/tabs/home');
        return false;
      }
    });

    return true;
  }
}
