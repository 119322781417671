import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-fidelidade-regulamento',
  templateUrl: './fidelidade-regulamento.page.html',
  styleUrls: ['./fidelidade-regulamento.page.scss'],
})
export class FidelidadeRegulamentoPage implements OnInit {
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  fecharModal() {
    this.modalController.dismiss();
  }
}
