import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-generica',
  templateUrl: './modal-generica.page.html',
  styleUrls: ['./modal-generica.page.scss'],
})
export class ModalGenericaPage implements OnInit {
  public data;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  public dismiss(acao: string) {
    this.modalController.dismiss(acao);
  }
}
