// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Loader } from '@googlemaps/js-api-loader';

export const environment = {
  production: false,
  apiUrl: 'http://localhost:8100/',
  clientId:
    '776044976683-vobndif6i2lrhiilgkppn42vqrbnt3fg.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyDhzIkHMQv-MbFhyOtbQr5AnR7Wp4y8f-E',
    authDomain: 'trigo-358311.firebaseapp.com',
    projectId: 'trigo-358311',
    storageBucket: 'trigo-358311.appspot.com',
    messagingSenderId: '776044976683',
    appId: '1:776044976683:web:24ed82684d17a0060fa86e',
    measurementId: 'G-0GVGSYEHGX',
  },
};

export const loader = new Loader({
  apiKey: 'AIzaSyD9AkDWwXaHyOriXbO1KaJgYyYHb-SrpEc',
  version: 'weekly',
  language: 'pt-BR',
  libraries: ['places'],
});

// export const API_URL: string = 'http://trigo.local/api/';
// export const API_URL: string = "https://trigo.mac/api/";
// export const API_URL: string = 'https://trigo.alphacode.mobi/api/';
export const API_URL: string = "https://trigo.alphacode.com.br/api/";

export const PASSPHRASE_AUTH =
  '[Q*ZM$O8jPQKGhxW}BesZpFKz7*[}jJ0q5DFad1![C,R}?SzTU}*&N3s4xi#6}cG';

// export const MERCADOPAGO_TOKEN = "TEST-ac02ce29-c0dd-4152-93fc-8f66bcf0fa8d";

export const MERCADOPAGO_TOKEN = 'APP_USR-335abbf2-f47d-467f-831b-8a4ab5c7ec9c';

export const SOFTWARE_VERSION = '1.0.9';

export const ENCRYPT = false;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
