import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';

import {
  celularMask,
  cpfMask,
  dataMask,
  nomeMask,
} from 'src/app/constantes/mascaras';

import { ApiService } from 'src/app/services/api.service';
import { ControladorContaService } from 'src/app/services/usuario/controlador-conta.service';
import { FormService } from 'src/app/services/form/form.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

import { AlterarSenhaPage } from 'src/app/modals/alterar-senha/alterar-senha.page';
import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';

@Component({
  selector: 'app-dados-pessoais-web',
  templateUrl: './dados-pessoais-web.component.html',
  styleUrls: ['./dados-pessoais-web.component.scss'],
})
export class DadosPessoaisWebComponent implements OnInit {
  public formulario: FormGroup;
  public usuario: any;

  public carregando: boolean = false;

  readonly celularMask: MaskitoOptions = celularMask;
  readonly cpfMask: MaskitoOptions = cpfMask;
  readonly dataMask: MaskitoOptions = dataMask;
  readonly nomeMask: MaskitoOptions = nomeMask;

  readonly maskPredicate: MaskitoElementPredicateAsync = async (
    el: HTMLElement
  ) => (el as HTMLIonInputElement).getInputElement();

  constructor(
    private apiService: ApiService,
    private controladorContaService: ControladorContaService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private modalService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );

    this.formulario = this.formBuilder.group({
      nome: [this.usuario.nome, Validators.required],
      cpf: [
        this.usuario.cpf,
        Validators.required,
        this.formService.validaCPF(),
      ],
      celular: [
        this.usuario.celular,
        [Validators.required, this.formService.validaCelular()],
      ],
      data_nascimento: [
        this.usuario.dataNascimento,
        [this.formService.validaData()],
      ],
      email: [this.usuario.email, [Validators.required, Validators.email]],
      senha: [{ value: '', disabled: true }],
    });
  }

  ngOnInit() {}

  public validarForm(): void {
    if (
      this.formService.validaForm(this.formulario, {
        nome: 'nome',
        celular: 'celular',
        cpf: 'CPF',
        email: 'e-mail',
        senha: 'senha',
      })
    ) {
      if (this.formulario.dirty) {
        this.editar();
      } else {
        this.utilsService.exibirToast('Nenhum dado foi alterado!', 'erro');
      }
    }
  }

  private async editar(): Promise<void> {
    const req = {
      jwt: this.usuario.jwt,
      nome: this.formulario.get('nome').value,
      celular: this.formulario.get('celular').value,
      cpf: this.formulario.get('cpf').value,
      dataNascimento: this.formulario.get('data_nascimento').value,
      email: this.formulario.get('email').value,
      senha: this.formulario.get('senha').value,
    };

    this.carregando = true;

    const res = await this.apiService.put('cliente', req);

    this.carregando = false;

    if (res.status === 'success') {
      this.usuario = res.data;
      this.usuarioService.atualizarUsuario(this.usuario);

      this.formulario.patchValue({ senha: '' });

      this.utilsService.exibirToast(res.message, 'sucesso');
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
    }
  }

  public desabilitarConta(acao: string): void {
    let dados = {
      header: '',
      subheader: '',
      message: '',
    };

    switch (acao) {
      case 'excluir-conta':
        dados = {
          header: 'Excluir sua conta',
          subheader: 'Você tem certeza que quer excluir sua conta?',
          message:
            'De acordo com LGPD, n 13.709, ao excluir sua conta, todos seus dados serão removidos permanentemente. Caso tenha interesse em voltar, é só fazer um novo cadastro.',
        };
        break;
      case 'inativar-conta':
        dados = {
          header: 'Inativar sua conta',
          subheader: 'Você tem certeza que quer inativar sua conta?',
          message: 'Ao inativar sua conta nenhuma informação será perdida.',
        };
        break;
      default:
        break;
    }

    this.modalService.abrirModal(
      'modal-generica',
      'modal-generica',
      ModalGenericaPage,
      {
        data: {
          titulo: dados.header,
          paragrafos: [dados.subheader],
          paragrafo_secundario: dados.message,
          label_acao:
            acao === 'excluir-conta' ? 'Excluir conta' : 'Inativar conta',
          acao: acao,
        },
      },
      (params: { data: string }) => {
        if (params.data === 'executar') {
          if (acao === 'excluir-conta') {
            this.controladorContaService.excluirConta();
          } else {
            this.controladorContaService.inativarConta();
          }
        }
      }
    );
  }

  public alterarSenha(): void {
    this.modalService.abrirModal(
      'modal-alterar-senha',
      'modal-alterar-senha',
      AlterarSenhaPage
    );
  }

  public voltar(): void {
    this.utilsService.voltarPagina('tabs/perfil');
  }
}
