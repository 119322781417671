import { Component, OnInit } from '@angular/core';

import { PerfilService } from 'src/app/pages/usuario/perfil/perfil.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

import { ProgramaFidelidadePage } from 'src/app/modals/fidelidade/programa-fidelidade/programa-fidelidade.page';

@Component({
  selector: 'app-cashback',
  templateUrl: './cashback.component.html',
  styleUrls: ['./cashback.component.scss'],
})
export class CashbackComponent implements OnInit {
  public usuario: any;

  constructor(
    private perfilService: PerfilService,
    private usuarioService: UsuarioService,
    private modalService: ModalsService
  ) {
    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );
  }

  ngOnInit(): void {}

  public irParaFidelidade(): void {
    this.perfilService.updateTab('Fidelidade');

    this.modalService.abrirModal(
      'modal-programa-fidelidade',
      'modal-programa-fidelidade',
      ProgramaFidelidadePage,
      {},
      true,
      true
    );
  }
}
