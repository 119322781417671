import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  private cardapioAcessadoSubject = new BehaviorSubject<string>('');
  public cardapioAcessado$: Observable<string> =
    this.cardapioAcessadoSubject.asObservable();

  private tabSubject = new BehaviorSubject<string>('');
  public tab$: Observable<string> = this.tabSubject.asObservable();

  private usuario: any;

  constructor(
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );

    if (this.utilsService.getStorage('cardapio-acessado'))
      this.updateCardapio(this.utilsService.getStorage('cardapio-acessado'));
  }

  public async buscarSaldo(idMarca: any): Promise<void> {
    if (this.usuario != null) {
      const res = await this.apiService.get('fidelidade_saldo', {
        jwt: this.usuario.jwt,
        marcaId: idMarca,
      });

      if (res.status == 'success') {
        this.usuario.cashback = res.data.valor;
        this.usuario.percentual_valor = res.data.percentual_valor;

        this.usuarioService.atualizarUsuario(this.usuario);
      }
    }
  }

  public updateTab(tab: string): void {
    this.tabSubject.next(tab);
  }

  public updateCardapio(cardapio: string): void {
    this.cardapioAcessadoSubject.next(cardapio);
  }
}
