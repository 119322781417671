import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() apresentar_logo: boolean = false;
  @Input() texto: string = '';
  @Input() voltar: boolean = true;

  @Input() personalizado: string = '';
  @Output() navegar: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
