import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { ISegmentButton } from 'src/app/componentes/segment/segment.component';
import { IUsuario } from 'src/app/interfaces/IUsuario.model';
import { CarrinhoService } from 'src/app/services/carrinho/carrinho.service';
import { CelularService } from './../../services/celular/celular.service';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { EventsService } from 'src/app/services/events.service';
import { LojaService } from 'src/app/services/loja/loja.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ModalidadeService, IModalidade} from 'src/app/services/modalidade/modalidade.service';
import { MudarVisibilidadeService } from 'src/app/services/mudarVisibilidade/mudar-visibilidade.service';
import { PerfilService } from 'src/app/pages/usuario/perfil/perfil.service';
import { UsuarioService } from './../../services/usuario/usuario.service';
import { UtilsService } from '../../services/utils.service';
import { MeusEnderecosComponent } from 'src/app/componentes/meus-enderecos/meus-enderecos.component';
import { EscolherRestauranteRetiradaPage } from 'src/app/modals/fluxo-retirada/escolher-restaurante-retirada/escolher-restaurante-retirada.page';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-topo-browser',
  templateUrl: './topo-browser.component.html',
  styleUrls: ['./topo-browser.component.scss'],
})
export class TopoBrowserComponent implements OnInit {
  @Input() elementos: Record<string, boolean> = {
    nav: true,
    carrinho: true,
    cashback: true,
    endereco: true,
    home: false,
  };

  @Input() redeId: number;
  @ViewChild('dadosEndereco') dadosEndereco: ElementRef;

  private celular: boolean;

  public usuario: IUsuario;
  public endereco: any = null;
  public loja: any;
  public modalidade: IModalidade;
  public links: Array<{ link: string; label: string }> = [];
  public segment: ISegmentButton;

  public dados_carrinho: any;
  public banners: Array<any> = [];
  public sugestoes: Array<any> = [];

  private cardapioAcessado: string = '';
  private enderecos: Array<any> = [];

  private marcas = [
    'spoleto',
    'chinainbox',
    'koni',
    'gurume',
    'kisso',
    'lebonton',
    'risie',
    'ctcburguer',
    'strogonosso',
    'giusto',
    'kanePoke',
    'hoaPoke',
    'kapuapoke',
    'banketto',
    'owan',
    'gokei',
    'kohalaacai',
    'kapuaacai',
    'lefrango',
    'gendai',
    'kohalapoke',
    'pokeschinainbox'
  ];


  constructor(
    private apiService: ApiService,
    private carrinhoService: CarrinhoService,
    private perfilService: PerfilService,
    private celularService: CelularService,
    private enderecoService: EnderecoService,
    private events: EventsService,
    private lojaService: LojaService,
    private modalService: ModalsService,
    private modalidadeService: ModalidadeService,
    private routerOutlet: IonRouterOutlet,
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private mudarVisibilidadeService: MudarVisibilidadeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.carrinhoService.dadosCarrinho$.subscribe(
      (dadosCarrinho) => (this.dados_carrinho = dadosCarrinho)
    );

    this.perfilService.cardapioAcessado$.subscribe(
      (cardapio) => (this.cardapioAcessado = cardapio)
    );

    this.celularService.celular$.subscribe((celular) => {
      this.celular = celular;

      this.links = [
        {
          link:
            this.cardapioAcessado !== ''
              ? `/tabs${this.cardapioAcessado}`
              : '/tabs/home',
          label: 'In�cio',
        },
        {
          link: this.celular ? '/tabs/historico' : '/historico-browser',
          label: 'Pedidos',
        },
        {
          link: '/tabs/cupons',
          label: 'Cupons',
        },
        {
          link: '/tabs/perfil',
          label: 'Perfil',
        },
      ];
    });

    this.lojaService.loja$.subscribe((loja) => (this.loja = loja));

    const modalidade = combineLatest([
      this.modalidadeService.modalidadesDisponiveis$,
      this.modalidadeService.modalidade$,
    ]);

    modalidade.subscribe(([disponiveis, selecionada]) => {
      if (disponiveis !== null) this.segment = disponiveis;
      else this.segment = null;

      this.modalidade = selecionada;
    });

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    this.enderecoService.enderecos$.subscribe((enderecos) => {
      if (enderecos !== null && enderecos.length > 0) {
        this.enderecos = enderecos;
        const principalEndereco = enderecos.find(endereco => endereco.principal === true);
        this.endereco = principalEndereco ? principalEndereco : enderecos[0];
      } else {
        this.endereco = null;
      }
    });


    this.events.subscribe('atualizar-carrinho', (res) => {
      if (res.limpar === true) {
        this.utils.removeStorage('carrinho');
        return;
      }

      this.carregarCarrinho();
    });

    this.buscarSaldo();
  }

  async ngOnInit() {
    this.events.publish('atualizar-carrinho', { limpar: false });
    this.mudarVisibilidadeService.visibility$.subscribe((isVisible) => {
      if (isVisible && this.redeId) {
        this.dadosEndereco.nativeElement.classList.add('inativo');
      } else {
        this.dadosEndereco.nativeElement.classList.remove('inativo');
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      const params = await this.route.params.pipe(first()).toPromise();
      const jwt = params['jwt'];

      if(jwt){
        await this.login(jwt);
      }

      const currentUrl = event.urlAfterRedirects;
      const pagina = currentUrl.split('/')[1];

      if(this.marcas.includes(pagina) && this.enderecos.length > 1){
        this.enderecoService.selecionarEndereco(this.endereco, true);
        this.abrirModal('modal-enderecos', 'modal-enderecos');
        this.utils.navegarPagina('home');
      }else if(this.marcas.includes(pagina) && !this.usuario){
        this.utils.navegarPagina('home');
      }
    });

  }

  private carregarCarrinho(): void {
    this.utils.removeStorage('tipo-compra');
    this.utils.removeStorage('periodo-agendado');
    this.utils.removeStorage('horario-agendado');

    if (this.utils.getStorage('carrinho') != null) {
      const carrinho =
        this.utils.getStorage('carrinho') == null
          ? []
          : this.utils.getStorage('carrinho');

      this.carrinhoService.calcular(carrinho);
    }
  }

  public abrirCarrinho(): void {
    if (this.dados_carrinho == null || this.dados_carrinho.qtd == 0) {
      this.utils.exibirToast('Sua sacola est� vazia :(', 'erro');
      return;
    }

    if (this.celular) {
      this.utils.navegarPagina('carrinho');
    } else {
      this.buscarSaldo();
      this.modalService
        .modalCarrinhoBrowser()
        .then()
        .then((res) => {
          if (res.role === 'sugestao') {
            this.modalService
              .modalProduto(this.routerOutlet.nativeEl)
              .then(() => {
                this.carregarCarrinho();
                this.utils.alteraStatusBar('claro');
              });
          } else {
            this.carregarCarrinho();
            this.utils.alteraStatusBar('claro');
          }
        });
    }
  }

  public alterarOpcao(event: any): void {
    const codigo: string = event.target.value;

    if (codigo === 'TTA' && this.modalidade.codigo !== codigo) {
      this.opcaoRetirada(codigo);
    }

    if (codigo === 'DLV' && this.modalidade.codigo !== codigo) {
      this.opcaoDLV();
    }
  }

  public opcaoRetirada(codigo: string, abrir: boolean = false): void {
    if (codigo === 'TTA' && (this.modalidade.codigo !== codigo || abrir)) {
      this.modalService
        .abrirModal(
          'modal-retirada',
          'modal-retirada',
          EscolherRestauranteRetiradaPage,
          this.redeId
        )
        .then((response) => {
          if (response)
            if (response.data === 'cancelar' || response.role == 'backdrop') {
              this.modalidadeService.atualizarModalidade('DLV');
            }
        });
    }
  }

  private opcaoDLV(): void {
    this.enderecoService
      .verificarCarrinho(null, false, this.modalidade.codigo, this.redeId)
      .then(async (response) => {
        if (response) {
          this.modalidadeService.atualizarModalidade('DLV');

          const modal = await this.modalService.existeModal();
          if (modal) this.modalService.fecharModal();

          this.lojaService.buscarLoja(this.endereco, 'DLV', this.redeId);
        } else {
          this.modalidadeService.atualizarModalidade('TTA');
        }
      });
  }

  public abrirModal(id: string, cssClass: string): void {
    let elemento!: Function;

    if (id === 'modal-enderecos') elemento = MeusEnderecosComponent;

    this.modalService.abrirModal(id, cssClass, elemento, { data: this.redeId });
  }

  public navegar(caminho: string): void {
    if (
      this.usuario == null &&
      (caminho == '/historico-browser' || caminho == '/tabs/historico')
    ) {
      this.utils.exibirToast('Fa�a login para continuar.', 'erro');
      this.utils.navegarPagina('login');
      return;
    }

    this.buscarSaldo();
    this.utils.navegarPagina(caminho);
  }

  async buscarSaldo() {
    if (this.usuario != null) {
      const res = await this.apiService.get('fidelidade_saldo', {
        jwt: this.usuario.jwt,
        marcaId: this.utils.getStorage('loja').marcaId,
      });

      if (res.status == 'success') {
        this.usuario.cashback = res.data.valor;
        this.usuario.percentual_valor = res.data.percentual_valor;

        this.usuarioService.atualizarUsuario(this.usuario);
        this.events.publish('atualizar-cashback');
      }
    }
  }

  private async login(jwt: string) {
    const req = {
      jwt: jwt,
    };

    const res = await this.apiService.post('login_institucional', req);

    if (res.status === 'success') {
      const usuario = res.data;

      this.loginInstitucional(usuario);
    } else {
      this.loginInstitucional(null);
    }
  }

  private loginInstitucional(usuario: any): void {
    if (usuario !== null) {
      this.usuarioService.atualizarUsuario(usuario);
      this.carrinhoService.limparCarrinho();

      this.enderecoService.limparDadosArmazenados();
      this.enderecoService.atualizarListaEnderecos(usuario.enderecos);

      this.lojaService.buscarLoja(
        this.endereco,
        this.modalidade.codigo,
        this.redeId
      );
    }
  }

}
