import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-adicionar-cpf-browser',
  templateUrl: './adicionar-cpf-browser.page.html',
  styleUrls: ['./adicionar-cpf-browser.page.scss'],
})
export class AdicionarCpfBrowserPage implements OnInit {
  public formulario;
  public loading: boolean = false;
  public cpf: string;
  public cor: string;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private utils: UtilsService
  ) {
    this.formulario = this.formBuilder.group({
      apelido: ['', Validators.required],
      numero: ['', Validators.required],
      titular: ['', Validators.required],
      cpf: ['', Validators.required],
      validade: ['', Validators.required],
      cvv: ['', Validators.required],
    });

    const usuario = this.utils.getStorage('usuario');
    this.cpf = usuario.cpf !== '' ? usuario.cpf : '';
    var root = document.documentElement;
    this.cor = this.utils.getStorage('cor');
    root.style.setProperty('--cor', this.cor);
  }

  ngOnInit() {}

  async fechar() {
    await this.modalController.dismiss();
  }
  async adicionar() {
    if (!this.utils.validaCPF(this.formulario.value.cpf)) {
      this.utils.exibirToast('CPF inválido!', 'erro');
      return;
    }
    await this.modalController.dismiss(this.formulario.value.cpf, 'add');
  }
}
