import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseAnalyticsService } from 'src/app/services/firebase-analytics.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-busca-restaurantes',
  templateUrl: './busca-restaurantes.page.html',
  styleUrls: ['./busca-restaurantes.page.scss'],
})
export class BuscaRestaurantesPage implements OnInit {
  public localizacao = this.utils.getStorage('endereco');
  public modalidade = this.utils.getStorage('modalidade');
  public cardapios = [];
  public cardapiosFiltrados = [];
  public modalidadeAtiva: string = 'DLV';
  public no_results = false;
  public error_msg: any;
  public idLoja;
  public typingTimer;                //timer identifier
  public doneTypingInterval = 1000;  //time in ms, 5 seconds for example
  public spinner: boolean = false;

  constructor(
    private api: ApiService,
    private utils: UtilsService,
    public modalController: ModalController,
    private http: ApiService,
    private firebase: FirebaseAnalyticsService
  ) { 
    if (this.utils.getStorage('modalidade') != null) {
      this.modalidadeAtiva = this.utils.getStorage('modalidade').codigo;
    }
  }

  async ngOnInit() {
  }

  startCountdown(keyword) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchProducts(keyword)
    }, this.doneTypingInterval);
  }

  clearCountdown() {
    clearTimeout(this.typingTimer);
  }

  async searchProducts(keyword) {
    this.cardapios = [];

    let resData: any = {
      lat: this.localizacao ? this.localizacao.lat : '',
      long: this.localizacao ? this.localizacao.lng : '',
      modalidadeId: this.modalidadeAtiva
    };

    if (keyword != '') {
      resData.keyword = keyword;
    };

    if (keyword == '') {
      return
    };

    const res = await this.http.get('buscar_produtos', resData);

    if (res.status != 'success') {
      this.cardapios = [];
      this.no_results = true;
      return this.utils.exibirToast(res.message, 'erro');
    }

    this.idLoja = res.data.id;

    res.data.forEach(cardapio => {
      this.cardapios.push(cardapio);
    });

    this.cardapios = this.cardapios.filter(cardapio =>
      cardapio.produtos.some(produto => produto.result !== "empty")
    );

    this.no_results = false;

  }

  closeModal() {
    this.modalController.dismiss()
  }

  async abrirProduto(produto: any) {

    this.utils.setStorage('produto', produto);

    if (this.localizacao === null) {
      this.utils.setStorage('produto', produto);
      this.modalController.dismiss()
      return this.utils.navegarPagina('produto');
    }

    const req = {
      lojaId: produto.loja ? produto.loja.id : produto.lojaId,
      marcaId: produto.loja ? produto.loja.marcaId : produto.marcaId,
      produtoCode: produto.codigo ? produto.codigo : '',
      produtoId: produto.id,
      modalidadeId: this.modalidadeAtiva
    };

    await this.utils.exibirLoading();
    const res = await this.http.get('cardapio', req);

    this.utils.setStorage('loja', produto.loja);
    this.utils.setStorage('modalidade', { codigo: this.modalidadeAtiva });
    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      if (res.data != null && res.data.produto != null) {
        this.utils.setStorage('produto', res.data.produto);
        this.utils.setStorage('sugestoes-loja', []);
        this.modalController.dismiss()
        this.utils.navegarPagina('produto');
      } else {
        this.utils.exibirToast('Ops.. Infelizmente esse produto não está disponível para sua região!', 'erro');
      }
    }
  }

}
