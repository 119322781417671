import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { Endereco } from 'src/app/interfaces/endereco';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { EventsService } from 'src/app/services/events.service';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
@Component({
  selector: 'app-excluir-endereco-web',
  templateUrl: './excluir-endereco-web.page.html',
  styleUrls: ['./excluir-endereco-web.page.scss'],
})
export class ExcluirEnderecoWebPage implements OnInit {

  public loading: boolean = false;
  public endereco: any;
  public confirmaCarrinho;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private modalController: ModalController,
    private events: EventsService,
    private enderecoService: EnderecoService
  ) { }

  ngOnInit() {}

  async continuar() {

    let enderecos = this.utils.getStorage('enderecos');
    if (enderecos.length == 1) {
      this.utils.exibirToast('É preciso de no mínimo 1 endereço cadastrado!')
      return
    }
    else {

      const req = {
        enderecoId: this.endereco.id
      };

      this.loading = true;

      const res = await this.api.delete('endereco', req);

      this.loading = false;

      if (res.status === 'success') {
        let endereco_pedido = await this.api.get('endereco_pedido');

        let enderecos = endereco_pedido.data;
        enderecos.forEach((endereco) => {
          endereco.principal = false;
        });

        enderecos[0].principal = true;

        this.utils.removeStorage('enderecos');
        this.utils.setStorage('enderecos', enderecos);
        this.enderecoService.atualizarListaEnderecos(enderecos);
        this.events.publish('atualizar-lista-endereco');
        this.events.publish('atualizar-endereco', enderecos);
        this.utils.exibirToast(res.message);
        this.modalController.dismiss();
      }
    }
  }

  cancelar() {
    this.modalController.dismiss(false)
  }

}
