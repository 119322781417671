import { ModalsService } from 'src/app/services/modals/modals.service';
import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-avaliacao',
  templateUrl: './avaliacao.page.html',
  styleUrls: ['./avaliacao.page.scss'],
})
export class AvaliacaoPage implements OnInit {

  constructor(
    private utils: UtilsService,
    private inAppBrowser: InAppBrowser,
    private modals: ModalsService
  ) { }

  ngOnInit() {
  }

  abrir() {
    const browser = this.inAppBrowser.create('https://play.google.com/store/apps/details?id=br.com.trigo.app', '_system');
    browser.show();
    this.utils.setStorage('avaliacao-app', false);
    this.modals.fecharModal();
  }

}
