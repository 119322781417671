import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-duvidas-frequentes-web',
  templateUrl: './duvidas-frequentes-web.component.html',
  styleUrls: ['./duvidas-frequentes-web.component.scss'],
})
export class DuvidasFrequentesWebComponent implements OnInit {

  public duvidas: Array<any> = [];
  public duvidaSelecionada: number = 0;

  constructor(
    private utils: UtilsService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.buscarFAQ();
  }

  async buscarFAQ() {
    this.duvidas = [];
    const res = await this.api.get('faq');
    if (res.status == "success") {
      this.duvidas = res.data;
    }
  }

}
