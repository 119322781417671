import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { ModalsService } from '../modals/modals.service';
import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';
import { ModalidadeService } from '../modalidade/modalidade.service';

@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  private dadosCarrinhoSubject = new BehaviorSubject<any>({
    total: 0,
    qtd: 0,
  });
  dadosCarrinho$: Observable<any> = this.dadosCarrinhoSubject.asObservable();

  constructor(
    public utilsService: UtilsService,
    private modalsService: ModalsService,
    private modalidadeService: ModalidadeService
    ) {}

  public calcular(carrinho: Array<any> | null) {
    let quantidade: number = 0;
    let total: number = 0;

    if (carrinho !== null) {
      quantidade = carrinho
        .map((item) => item.qtd)
        .reduce((valor: number, item: number) => valor + item, 0);

      total = carrinho
        .map((item) => item.preco.total.valor)
        .reduce((valor: number, item: number) => valor + item, 0);
    }

    this.dadosCarrinhoSubject.next({ total: total, qtd: quantidade });
  }

  public limparCarrinho(): void {
    this.utilsService.removeStorage('carrinho');
    this.utilsService.removeStorage('sacola');
    this.utilsService.removeStorage('modalidade_carrinho');
    this.utilsService.removeStorage('marca_carrinho');
    this.utilsService.removeStorage('loja_carrinho');
    this.utilsService.removeStorage('qtdCarrinho');
    this.dadosCarrinhoSubject.next(null);
    this.calcular(null);
  }
}
