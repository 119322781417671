import { ApiService } from 'src/app/services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-caixa-cashback',
  templateUrl: './caixa-cashback.component.html',
  styleUrls: ['./caixa-cashback.component.scss'],
})
export class CaixaCashbackComponent implements OnInit {
  @Input() saldo: string;

  constructor(
    private api: ApiService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
  }
}
