import { ModalController } from '@ionic/angular';
import { UtilsService } from './../../services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cpf-nota',
  templateUrl: './cpf-nota.page.html',
  styleUrls: ['./cpf-nota.page.scss'],
})
export class CpfNotaPage implements OnInit {

  public cpf: string;
  public cor: string;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController
  ) {
    const usuario = this.utils.getStorage('usuario');
    this.cpf = usuario.cpf !== '' ? usuario.cpf : '';
    var root = document.documentElement;
    this.cor = this.utils.getStorage('cor');
    root.style.setProperty('--cor', this.cor);
  }

  ngOnInit() {
  }

  async addCpf() {

    if (this.cpf === '') {
      this.utils.exibirToast('O CPF é obrigatório', 'erro');
      return;
    }

    if (!this.utils.validaCPF(this.cpf)) {
      this.utils.exibirToast('CPF inválido!', 'erro');
      return;
    }

    await this.modalController.dismiss(this.cpf, 'add');

  }

}
