import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dados-carrinho',
  templateUrl: './dados-carrinho.component.html',
  styleUrls: ['./dados-carrinho.component.scss'],
})
export class DadosCarrinhoComponent implements OnInit {

  @Input() dados_carrinho;
  @Input() celular;
  @Input() loja;

  constructor(private utilsService: UtilsService, private modalsService: ModalsService, private analyticsService: AnalyticsService) { }

  ngOnInit() { }

  public irPara(
    pagina: string,
    produto: any = null,
    categoria: any = null,
    carrinho: any = null
  ): void {
    if (produto !== null) {
      this.selecionarProduto(produto, categoria);
    }

    if (carrinho !== null && this.carrinhoVazio()) {
      this.utilsService.exibirToast('Sua sacola está vazia :(', 'erro');
      return;
    }

    if (!this.celular) {
      if (produto !== null) {
        this.modalsService.modalProdutoBrowser(!this.celular, '', this.loja.marcaId);
      }

      if (carrinho !== null) {
        this.modalsService.modalCarrinhoBrowser();
      }

      return;
    }

    this.utilsService.navegarPagina(pagina);
  }

  private selecionarProduto(produto: any, categoria: any): void {
    this.utilsService.setStorage('produto', produto);

    const event = this.analyticsService.select_item(
      produto,
      categoria,
      this.loja
    );

    this.analyticsService.registrarEvento(event);
  }

  private carrinhoVazio(): boolean {
    return this.dados_carrinho.qtd === 0 || this.dados_carrinho.qtd === null;
  }

}
