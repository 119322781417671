import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Endereco } from 'src/app/interfaces/endereco';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-novo-chamado',
  templateUrl: './novo-chamado.page.html',
  styleUrls: ['./novo-chamado.page.scss'],
})
export class NovoChamadoPage implements OnInit {
  public loading: boolean = false;
  public botao: boolean = true;

  public formulario: FormGroup;
  public controladores: Object;
  public marca;

  public endereco: Endereco;
  public redes: Array<any> = [];

  public modalidade;
  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform,
    private modalController: ModalController
  ) {
    this.modalidade = this.utils.getStorage('modalidade').codigo
  }

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      nome: [this.utils.getStorage('usuario')['nome'], Validators.required],
      email: [this.utils.getStorage('usuario')['email'], Validators.required],
      assunto: ['', Validators.required],
      pedido: [''],
      marca: [''],
      loja: [''],
      mensagem: ['', Validators.required],
    });


    this.controladores = {
      nome: 'O nome',
      email: 'O e-mail',
      assunto: 'O assunto',
      mensagem: 'A mensagem ',
    };


    this.platform.ready().then(() => {
      this.keyboard.onKeyboardDidShow().subscribe(() => (this.botao = false));
      this.keyboard.onKeyboardDidHide().subscribe(() => (this.botao = true));
    });

    this.buscarRedes();
  }

  ionViewWillEnter() {
    this.buscarRedes();
  }

  fechar() {
    this.modalController.dismiss();
  }

  async buscarRedes() {
    this.redes = [];

    const endereco: Endereco = this.utils.getStorage('enderecos')[0];

    const req = {
      endereco: endereco.logradouro,
      numero: endereco.numero,
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      estado: endereco.estado,
      lat: endereco.lat,
      lng: endereco.lng,
      modalidadeId: this.modalidade,
    };


    const res = await this.api.get('lojas', req);

    if (res.status === 'success') {
      setTimeout(() => {
        this.redes = res.data;
        this.loading = false;
      }, 500);
    } else if (res.responseCode == 404) {
      this.loading = false;
    }
  }



  async enviarChamado() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      const req = {
        nome: this.formulario.get('nome').value,
        email: this.formulario.get('email').value,
        assunto: this.formulario.get('assunto').value,
        pedidoId: this.formulario.get('pedido').value,
        loja: this.formulario.get('loja').value,
        marcaId: this.formulario.get('marca').value,
        mensagem: this.formulario.get('mensagem').value,
      };
      this.loading = true;

      const res = await this.api.post('fale_conosco', req);

      this.loading = false;

      if (res.status !== 'success') return this.utils.exibirToast(res.message, 'erro');

      this.fechar();
    }
  }
}
