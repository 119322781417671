import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from './../../../services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { IonContent, IonInfiniteScroll } from '@ionic/angular';
import { ConfirmarRestauranteRetiradaPage } from '../confirmar-restaurante-retirada/confirmar-restaurante-retirada.page';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';
import { LojaService } from 'src/app/services/loja/loja.service';

export interface ILojaRetirada {
  cidade: string;
  default: boolean;
  distancia: string;
  endereco: string;
  estado: string;
  header: string;
  horario: string;
  icone: string;
  latitude: string;
  loja_id: number;
  logo: string;
  longitude: string;
  marca_id: number;
  nome: string;
  statusLoja: boolean;
  tempo_entrega: string;
}

@Component({
  selector: 'app-escolher-restaurante-retirada',
  templateUrl: './escolher-restaurante-retirada.page.html',
  styleUrls: ['./escolher-restaurante-retirada.page.scss'],
})
export class EscolherRestauranteRetiradaPage implements OnInit {
  @ViewChild(IonContent, { static: false }) ionContent: IonContent;

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  private endereco_selecionado: any = null;
  private localizacao_atual: any = null;
  public lojas: ILojaRetirada[] = [];
  public loja: any;
  public carregando: boolean = true;
  private pagina: number = 1;
  public data: number;

  constructor(
    private apiService: ApiService,
    private lojaService: LojaService,
    private enderecoService: EnderecoService,
    private modalsService: ModalsService,
    private modalidadeService: ModalidadeService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.lojaService.loja$.subscribe((loja) => {
      this.loja = loja;
    });

    this.enderecoService.enderecos$.subscribe((enderecos) => {
      this.endereco_selecionado = enderecos[0];
    });

    this.buscarLojas(this.endereco_selecionado);
  }

  private async buscarLojas(
    localizacao: any,
    paginacao: boolean = false
  ): Promise<void> {
    if (!paginacao) {
      this.carregando = true;
      await this.utilsService.exibirLoading();
    }

    const req = {
      latitude: localizacao ? localizacao.lat : '',
      longitude: localizacao ? localizacao.lng : '',
      marca_id: this.loja.marcaId,
      start: this.pagina,
      limit: 30,
    };

    await this.apiService.get('pedido_retirada', req).then((response) => {
      if (response.status === 'success') {
        response.data.forEach((item: any) => {
          this.lojas.push(item);
        });

        this.pagina++;
      } else if (response.responseCode == 404) {
        this.infiniteScroll.disabled = true;
      }
    });

    if (!paginacao) {
      this.carregando = false;
      await this.utilsService.ocultarLoading();
    }
  }

  public buscar(event: any): void {
    setTimeout(async () => {
      if (this.localizacao_atual !== null) {
        await this.buscarLojas(this.localizacao_atual, true);
      } else if (this.utilsService.getStorage('usuario')) {
        await this.buscarLojas(this.endereco_selecionado, true);
      } else {
        await this.buscarLojas(null, true);
      }

      event.target.complete();
    }, 500);
  }

  private getLocation(): void {
    this.utilsService
      .getLocationService()
      .then(async (resp) => {
        const coordenadas: any = resp;

        await this.apiService.get('geocode', coordenadas).then((response) => {
          const data = response;

          if (data.status === 'success') {
            this.localizacao_atual = data.data;
            this.buscarLojas(this.localizacao_atual);
          } else {
            this.buscarLojas(this.endereco_selecionado);
          }
        });
      })
      .catch(async () => {
        this.buscarLojas(this.endereco_selecionado);
      });
  }

  public selecionarLoja(loja: ILojaRetirada) {

    if (!loja.statusLoja) {
      this.utilsService.exibirToast('Restaurante fechado no momento!', 'erro');
      return;
    }

    this.modalsService.fecharModal();

    let localizacao: any;
    const redeId = this.data;

    if (this.localizacao_atual !== null) localizacao = this.localizacao_atual;
    else if (this.utilsService.getStorage('usuario'))
      localizacao = this.endereco_selecionado;

    this.modalsService.abrirModal(
      'modal-retirada',
      'modal-retirada',
      ConfirmarRestauranteRetiradaPage,
      { data: { data: loja, localizacao: localizacao, redeId: redeId } },
      (params: any) => {
        if (params.data === 'cancelar' || params.role == 'backdrop') {
          this.modalidadeService.atualizarModalidade('DLV');
        }
      },
      true,
      false
    );
  }

  public async navegar(caminho: string): Promise<void> {
    const modal = await this.modalsService.existeModal();
    if (modal) {
      this.modalsService.fecharModal('cancelar');
      return;
    }

    this.utilsService.navegarPagina(caminho);
  }
}
