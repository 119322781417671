import { CarrinhoService } from './../../services/carrinho/carrinho.service';
import { ApiService } from '../../services/api.service';
import { IonInput, IonRouterOutlet, ModalController } from '@ionic/angular';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'moment';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { Location } from '@angular/common';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';
import { SOFTWARE_VERSION } from 'src/environments/environment';

declare let dataLayer: any;
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-finalizar-pedido',
  templateUrl: './finalizar-pedido.page.html',
  styleUrls: ['./finalizar-pedido.page.scss'],
})
export class FinalizarPedidoPage implements OnInit {
  @ViewChild('version') version: IonInput;
  @ViewChild('cavv') cavv: IonInput;
  @ViewChild('xid') xid: IonInput;
  @ViewChild('referenceId') referenceId: IonInput;
  @ViewChild('eci') eci: IonInput;
  @ViewChild('status') status: IonInput;

  public subtotal: any = {
    valor: 0,
    texto: 'R$ 0,00',
  };

  public total: any = {
    valor: 0,
    texto: 'R$ 0,00',
  };

  public total_sem_cashback: any = {
    valor: 0,
    texto: 'R$ 0,00',
  };

  public carrinho: Array<any>;
  public loja: any = {};
  public endereco: any;
  public usuario: any;
  public isCpfNota: boolean;
  public cpf: string;

  public frete: any;

  public tipoCompra: string;
  public formaPagamento: string;
  public tipoformaPagamento = 'online';

  public cor: string;
  public marca: string;
  public observacao: string;

  public tempoEntrega: any = {};
  public agendamento: any = null;

  public cartao: any;
  public loading: boolean = false;

  public desconto: any = {};

  public modalidade: any;

  public pagamentos: any;

  public saldo: any = {};

  public troco: boolean = false;
  public valor_troco = '0,00';
  public cashback: number = 0;

  public cartoesCorporativos: Array<any> = [];
  public cartoesCorporativo: any;

  public pagamento_info: any = null;

  public token: any = null;

  public brand: any = {};
  public cupom;

  public adicionais_selecionado: any;
  public dados_produto: any;
  public paymentType: string;

  public metadata: any = [];
  public fingerPrintId: string;
  public dados_debito: any = {};
  public cartao_vale_refeicao: any;
  public cartao_pagamento: any;
  public auth_3ds: any = {};
  public cartoes_gateway_encontrados: boolean = false;
  public cartoes_outro_gateway_encontrados: boolean = false;
  public cartao_debito: any;

  constructor(
    private el: ElementRef,
    private utils: UtilsService,
    private modalController: ModalController,
    private api: ApiService,
    private events: EventsService,
    private modals: ModalsService,
    private location: Location,
    private enderecoService: EnderecoService,
    private carrinhoService: CarrinhoService,
    private modalidadeService: ModalidadeService,
    private readonly renderer: Renderer2,
  ) {
    this.marca = this.utils.getStorage('loja_carrinho').marcaId;
    this.carrinho = this.utils.getStorage('carrinho');
    this.cashback = this.utils.getStorage('cashback');
    this.loja = this.utils.getStorage('loja_carrinho');
    this.enderecoService.enderecos$.subscribe(enderecos => this.endereco = enderecos[0]);
    this.usuario = this.utils.getStorage('usuario');
    this.tipoCompra = this.utils.getStorage('tipo-compra-carrinho');
    this.desconto = this.utils.getStorage('desconto');

    this.cpf = this.usuario.cpf !== '' ? this.usuario.cpf : 'Informe o CPF';
    this.isCpfNota = this.usuario.cpf !== 'Informe o CPF' ? true : false;
    this.cartao =
      this.usuario.cartoes.length > 0 ? this.usuario.cartoes[0] : null;

    this.events.publish('atualizar-cartoes');

    this.modalidadeService.modalidade$.subscribe((modalidade) => this.modalidade = modalidade);

    this.carrinho.forEach((item: any) => {
      this.subtotal.valor += item.preco.total.valor;
      this.subtotal.texto = this.utils.floatToReal(this.subtotal.valor);
    });

    if (this.modalidade.codigo === 'DLV') {
      this.frete =
        this.desconto.frete > 0
          ? (this.loja.frete.valor * this.desconto.frete) / 100
          : 0;

      // this.loja.frete.valor -=
      //   (this.loja.frete.valor * this.desconto.frete) / 100;

      if (this.loja.frete.valor < 0) {
        this.loja.frete.valor = 0;
      }

      this.loja.frete.texto = this.utils.floatToReal(this.loja.frete.valor);

      this.total.valor = this.subtotal.valor - this.desconto.valor;

      if (this.total.valor < 0) {
        this.total.valor = 0;
      };
      this.total.valor +=
        this.desconto.frete > 0
          ? this.loja.frete.valor -
          (this.loja.frete.valor * this.desconto.frete) / 100
          : this.loja.frete.valor;

      this.total_sem_cashback.valor = this.total.valor;
      this.total.valor -= this.cashback;

      this.total.texto = this.utils.floatToReal(this.total.valor);
      this.total_sem_cashback.texto = this.utils.floatToReal(
        this.total_sem_cashback.valor
      );
    } else {
      this.endereco = {
        id: 0,
        principal: false,
        apelido: '-',
        logradouro: '-',
        numero: 'S/n',
        complemento: '-',
        bairro: '-',
        cidade: '-',
        estado: '-',
        cep: '-',
        ponto_referencia: '-',
        lat: 0,
        lng: 0,
      };

      this.total.valor =
        this.subtotal.valor - this.desconto.valor - this.cashback;

      if (this.total.valor < 0) {
        this.total.valor = 0;
      }

      this.total.texto = this.utils.floatToReal(this.total.valor);

      this.total_sem_cashback.valor = this.total.valor + this.cashback;
      this.total_sem_cashback.texto = this.utils.floatToReal(
        this.total_sem_cashback.valor
      );
    }

    this.tempoEntrega = {
      tempo1: moment().add(this.loja.tempoEntrega, 'minutes').format('HH:mm'),
      tempo2: moment()
        .add(this.loja.tempoEntrega + 10, 'minutes')
        .format('HH:mm'),
    };


    // if (this.tipoCompra === 'agendado') {
    //   this.agendamento = {
    //     periodo: this.utils.getStorage('periodo-agendado-carrinho'),
    //     horario: this.utils.getStorage('horario-agendado-carrinho'),
    //   };
    // } else {
    this.agendamento = null;
    // }

    var root = document.documentElement;
    this.cor = this.utils.getStorage('loja_carrinho').paletaCor;
    root.style.setProperty('--cor', this.cor);

    this.saldo = {
      texto: 'R$ 0,00',
      valor: 0,
    };

    if (this.utils.getStorage('enderecos') != null) {
      this.endereco = this.utils.getStorage('enderecos')[0];
      this.dados_debito = {
        ...this.dados_debito,
        "bpmpi_billto_street1": this.endereco.logradouro,
        "bpmpi_billto_street2": this.endereco.complemento,
        "bpmpi_billto_city": this.endereco.cidade,
        "bpmpi_billto_state": this.endereco.estado,
        "bpmpi_billto_zipcode": this.endereco.cep.replace(/[^0-9]/g,'')
      }
      if (this.endereco.complemento != null) {
        this.endereco.complemento = '-' + this.endereco.complemento;
      }
    }
  }

  ngOnInit() {
    this.buscarPagamentos();

    this.events.subscribe('atualizar-cartoes', () => {
      this.usuario = this.utils.getStorage('usuario');
      this.cartao =
        this.usuario.cartoes.length > 0 ? this.usuario.cartoes[0] : null;
    });

    this.events.subscribe('fechar-modal', async () => {
      await this.modalController.dismiss('sucesso', '', 'finalizar-pedido');
    });

    this.events.subscribe('atualizar-desconto', () => {
      this.desconto = this.utils.getStorage('desconto');
      this.aplicarDesconto(this.desconto.porcentagem);
    });

    let eventBeginCheckout = {
      event: 'begin_checkout',
      ecommerce: {
        affiliation: this.loja.nome,
        currency: 'BRL',
        value: parseFloat(this.total.valor.toFixed(2)),
        coupon: this.desconto.cupom,
        ...(this.modalidade.codigo === 'DLV'
          ? { shipping: this.loja.frete.valor }
          : ''),
        items: this.itemsEvent('begin_checkout'),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(eventBeginCheckout);

    let shippingTier =
      this.modalidade.codigo == 'DLV'
        ? 'Delivery'
        : this.modalidade.codigo == 'TTA'
          ? 'Retirar'
          : this.modalidade.codigo == 'TGO'
            ? 'Local'
            : '';

    let eventAddShippingInfo = {
      event: 'add_shipping_info',
      ecommerce: {
        affiliation: this.loja.nome,
        currency: 'BRL',
        shipping_tier: shippingTier,
        ...(this.modalidade.codigo === 'DLV'
          ? { shipping: this.loja.frete.valor }
          : ''),
        value: parseFloat(this.total.valor.toFixed(2)),
        coupon: this.desconto.cupom,
        items: this.itemsEvent('add_shipping_info'),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(eventAddShippingInfo);
  }

  async ionViewWillEnter() {
    this.fingerPrintId = document.getElementById('metrix_body').getAttribute('src').split('braspag_grupotrigo_split')[1];
    setTimeout(() => {
      const script_config = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptBody = this.renderer.createText(`
      function bpmpi_config() {
        return {
          onReady: function () {
            // Evento indicando quando a inicializa��o do script terminou.
          },
          onSuccess: function (e) {
            // Cart�o eleg�vel para autentica��o, e portador autenticou com sucesso.
            document.getElementById('status_auth').value = 'success';
            document.getElementById('cavv').value = e.Cavv;
            document.getElementById('xid').value = e.Xid;
            document.getElementById('eci').value = e.Eci;
            document.getElementById('version').value = e.Version;
            document.getElementById('referenceId').value = e.ReferenceId;
          },
          onFailure: function (e) {
            // Cart�o eleg�vel para autentica��o, por�m o portador finalizou com falha.
            document.getElementById('status_auth').value = 'failure';

          },
          onUnenrolled: function (e) {
            // Cart�o n�o eleg�vel para autentica��o (n�o autentic�vel).
            document.getElementById('status_auth').value = 'unenrolled';
          },
          onDisabled: function () {
            // Loja n�o requer autentica��o do portador (classe "bpmpi_auth" false -> autentica��o desabilitada).
            document.getElementById('status_auth').value = 'disabled';

          },
          onError: function (e) {
            // Erro no processo de autentica��o.
            document.getElementById('status_auth').value = 'error';

          },
          onUnsupportedBrand: function (e) {
            // Bandeira n�o suportada para autentica��o.
            document.getElementById('status_auth').value = 'unsupported';
          },
          Environment: "PRD",
          Debug: false
        };
      }
        `);
      this.renderer.appendChild(script_config, scriptBody);
      this.renderer.appendChild(this.el.nativeElement, script_config);

      const script_3ds = this.renderer.createElement('script') as HTMLScriptElement;
      script_3ds.src = `https://mpi.braspag.com.br/Scripts/BP.Mpi.3ds20.min.js`;
      script_3ds.type = 'text/javascript';
      this.renderer.appendChild(document.head, script_3ds);
    }, 2000);

    this.dados_debito = {
      ...this.dados_debito,
      "bpmpi_totalamount": this.total.valor.toFixed(2) * 100,
      "bpmpi_billto_name": this.usuario.nome,
      "bpmpi_billto_phonenumber": this.usuario.celular.replace(/[^0-9]/g, ''),
      "bpmpi_billto_email": this.usuario.email
    }

    const carteira = await this.api.get('carteira');

    if(carteira.data){
      const metodo_pagamento =  (this.loja.metodo_pagamento != null) ? this.loja.metodo_pagamento : 'mercadopago';
      carteira.data.forEach(cartao => {
          if (cartao.metodo_pagamento === metodo_pagamento) {
            this.cartoes_gateway_encontrados = true;
          }else{
            this.cartoes_outro_gateway_encontrados = true;
          }
      });
    }

  }

  public resetarLinkMetrix() {
    let uuid = uuidv4();

    document.getElementById('metrix_head').remove();
    document.getElementById('metrix_noscript').remove();

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src =
      `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` +
      uuid;
    script.id = 'metrix_head';
    script.async = true;
    this.renderer.appendChild(document.head, script);

    const script2 = this.renderer.createElement(
      'noscript'
    ) as HTMLScriptElement;
    const scriptBody = this.renderer.createElement(
      'iframe'
    ) as HTMLScriptElement;
    scriptBody.src =
      `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` +
      uuid;
    scriptBody.id = 'metrix_body';
    script2.id = 'metrix_noscript';
    scriptBody.style.width = '100px';
    scriptBody.style.height = '100px';
    scriptBody.style.border = '0';
    scriptBody.style.position = 'absolute';
    scriptBody.style.top = '-5000px';
  }

  voltar() {
    this.location.back();
  }

  async finalizar() {
    if (this.formaPagamento === '' && this.total.valor > 0) {
      this.utils.exibirToast('Selecione uma forma de pagamento', 'erro');
      return;
    }

    if (!this.usuario.cpf.length) {
      this.utils.setStorage('cadastro_cpf', 'true');
      this.modalController.dismiss('sucesso_cpf');
      return;
    }

    this.carrinho.forEach((item) => {
      if (item.adicionais.length > 0) {
        item.adicionais = this.filtroAdicionais(item.adicionais);
      }

      if (item.opcionais.length > 0) {
        item.opcionais = this.filtroOpcionais(item.opcionais);
      }
      if (item.obrigatorios.length > 0) {
        item.obrigatorios = this.filtroObrigatorios(item.obrigatorios);
      }

      if (item.opcionais_gerais) {
        if (item.opcionais_gerais.length > 0) {
          item.opcionais_gerais = this.filtroOpcionaisGerais(
            item.opcionais_gerais
          );
        }
      }

      if (item.tipo === 'Combo') {
        item.produtos.forEach((produto: any) => {
          if (produto.adicionais.length > 0) {
            produto.adicionais = this.filtroAdicionais(produto.adicionais);
          }

          if (produto.opcionais.length > 0) {
            produto.opcionais = this.filtroOpcionais(produto.opcionais);
          }
          if (item.obrigatorios.length > 0) {
            item.obrigatorios = this.filtroObrigatorios(item.obrigatorios);
          }
          if (item.opcionais_gerais.length > 0) {
            item.opcionais_gerais = this.filtroOpcionaisGerais(
              item.opcionais_gerais
            );
          }
        });
      }
    });

    let cartaoId = 0;

    if (
      this.formaPagamento == 'CREDIT' &&
      (this.cartao == null || this.cartao == undefined) &&
      this.total.valor > 0
    ) {
      this.utils.exibirToast('Selecione um cart�o para fazer o pedido!', 'erro');
      return;
    }

    if (
      this.formaPagamento == 'DEBIT' &&
      !this.cartao_debito
    ) {
      this.utils.exibirToast('Selecione um cart�o para fazer o pedido!', 'erro');
      return;
    }


    if (this.formaPagamento == 'CASH' && this.troco) {
      var frete =
        this.modalidade.codigo == 'DLV'
          ? this.loja.frete.valor
          : 0;

      if (Number(this.valor_troco.replace(',', '.')) < this.total.valor) {
        this.utils.exibirToast('Valor de troco menor que o valor do pedido!', 'erro');
        return;
      }
    }

    if (this.utils.checkStorage('teste-ab-upselling')) {
      const upselling = this.utils.getStorage('teste-ab-upselling');

      this.carrinho.filter(async (u) => {
        if (u.cod === upselling.cod) {
          const req = {
            jwt: this.usuario.jwt,
            upsellingId: upselling.testeAB.id,
            testeId: upselling.testeAB.id,
            tipo: upselling.testeAB.tipo,
          };

          await this.api.post('teste_ab_upselling', req);
          this.utils.removeStorage('teste-ab-upselling');
        }
      });
    }

    if (this.formaPagamento === 'CREDIT' && this.cartao !== null) {
      cartaoId = this.cartao.id;

      if(this.loja.metodo_pagamento == 'braspag'){
        this.finalizarPedido(cartaoId);
      }else{
        this.modals.modalConfirmaCartao(this.cartao).then(async (res) => {
          if (res.data.token != null) {
            this.token = res.data.token.id;

            this.verificarPedido(cartaoId);
          }
        });
      }
    } else {
      this.verificarPedido();
    }
  }

  async finalizarPedido(cartaoId = 0) {
    const req = {
      jwt: this.usuario.jwt,
      lojaId: this.loja.id,
      marcaId: this.loja.marcaId,
      tipoPagamento:
        this.pagamento_info != null && this.tipoformaPagamento == 'entrega'
          ? this.pagamento_info.tipo
          : this.formaPagamento,
      formaPagamento: this.tipoformaPagamento,
      bandeira:
        this.pagamento_info != null ? this.pagamento_info.bandeira : null,
      cupomId: this.desconto.cupomId,
      desconto: Number(this.desconto.valor.toFixed(2)),
      cartaoId:
        this.formaPagamento === 'CREDIT' && this.tipoformaPagamento === 'online'
          ? cartaoId
          : 0,
      tipoPedido: this.tipoCompra,
      tempoEntrega: this.loja.tempoEntrega,
      observacao: this.observacao,
      horarioAgendamento: '',
      cpfNota: this.isCpfNota ? this.cpf : '',
      modalidade: this.modalidade.codigo,
      total: this.subtotal.valor,
      troco: this.troco ? Number(this.valor_troco.replace(',', '.')) : 0.0,
      endereco: this.endereco,
      frete:
        this.utils.getStorage('loja-fora-area') != 'true'
          ? this.loja.frete.valor
          : 0,
      frete_desconto: this.frete,
      carrinho: this.carrinho,
      cashback: this.cashback,
      token: this.token,
      origin: 'PWA',
      version: SOFTWARE_VERSION,
      metadata: this.metadata,
      fingerPrintId: this.fingerPrintId,
      dados_cartao:
        this.formaPagamento == 'CREDIT'
          ? this.cartao
          : this.formaPagamento == 'DEBIT'
          ? this.cartao_debito
          : this.formaPagamento == 'MEAL_VOUCHER'
          ? this.cartao_vale_refeicao
          : '',
        dados_auth: {
          cavv: this.cavv.value,
          xid: this.xid.value,
          eci: this.eci.value,
          version: this.version.value,
          referenceId: this.referenceId.value,
          status: this.status.value,
          }
    };

    this.loading = true;
    await this.utils.exibirLoading();

    if (req.tipoPagamento === 'PIX') {
      const res = await this.api.post('pix', req);
      this.utils.setStorage('pedido', req);

      this.loading = false;

      if (res.status === 'success') {

        this.utils.setStorage('pixId', res.data);
        this.utils.navegarPagina('pagamento-pedido-pix');
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }

    } else {
      const res = await this.api.post('pedidos', req);

      this.loading = false;

      if (res.status === 'success') {
        this.usuario.cashback -= this.cashback;

        let shippingTier =
          this.modalidade.codigo == 'DLV'
            ? 'Delivery'
            : this.modalidade.codigo == 'TTA'
              ? 'Retirar'
              : this.modalidade.codigo == 'TGO'
                ? 'Local'
                : '';

        let event = {
          event: 'purchase',
          transaction_cashback: this.cashback,
          payment_type: this.formaPagamento,
          shipping_tier: shippingTier,
          transaction_discount: this.desconto.tipo == 'fixo' ? this.desconto.valor : 0,
          discount_type: this.desconto.tipo == 'fixo' ? 'transa��o' : 'item',
          telefone: this.usuario.celular,
          email: this.usuario.email,
          ecommerce: {
            transaction_id: res.data.toString(),
            affiliation: this.loja.nome,
            value: this.total.valor,
            ...(this.modalidade.codigo === 'DLV'
              ? { shipping: this.loja.frete.valor }
              : ''),
            currency: 'BRL',
            coupon: this.desconto.cupom,
            items: this.itemsEvent('purchase'),
          },
        };

        dataLayer.push({ ecommerce: null });
        dataLayer.push(event);

        this.utils.exibirToast(res.message);
        this.utils.removeStorage('tipo-compra-carrinho');
        this.utils.removeStorage('periodo-agendado-carrinho');
        this.utils.removeStorage('horario-agendado-carrinho');
        this.utils.removeStorage('confirma-modalidade');
        this.utils.removeStorage('cashback');
        this.utils.removeStorage('carrinho');
        this.utils.removeStorage('modalidade_carrinho');
        this.utils.removeStorage('marca_carrinho');
        this.utils.removeStorage('loja-fora-area');
        this.utils.removeStorage('cupomDesconto');
        this.utils.setStorage('usuario', this.usuario);
        this.utils.setStorage('pedidoId', res.data);
        this.utils.setStorage('pedidoAndamento', true);
        this.events.publish('atualizar-pedidos');
        this.events.publish('pedido-finalizado');
        this.utils.navegarPagina('tabs/historico');

        this.carrinhoService.limparCarrinho();
      } else {
        this.utils.exibirToast(res.message, 'erro');
      }
    }


  }

  abrirModalCPF() {
    this.modals.modalCPFNota().then((res) => {
      if (res.role === 'add') {
        this.cpf = res.data;
        this.isCpfNota = true;
      }
    });
  }

  // abrirModalEnderecos() {
  //   this.modals.modalMeusEnderecos();
  // }

  mudarTipoFormaPagamento(tipo) {
    this.formaPagamento = '';
  }

  async selecionarMetodoPagamento(pagamento: string, index: number = 0) {
    this.formaPagamento = pagamento;

    let pagamentoEvento = '';
    if (pagamento === 'cartao-entrega') {
      await this.modals
        .modalFormasPagamento(this.pagamentos.entrega.cartao, this.loja)
        .then(async (res) => {
          if (res.data != undefined) {
            this.pagamento_info = res.data;
          } else {
            this.formaPagamento = '';
            this.pagamento_info = null;
          }
        });
      pagamentoEvento = 'Cart�o Entrega';
    } else {
      pagamentoEvento = pagamento;
      this.loja = this.utils.getStorage('loja_carrinho');
      this.desconto = this.utils.getStorage('desconto');
      this.pagamento_info = null;

      if (this.desconto.valor > 0) {
        if (this.modalidade.codigo === 'DLV') {
          // this.loja.frete.valor -=
          //   (this.loja.frete.valor * this.desconto.frete) / 100;

          this.frete =
            this.desconto.frete > 0
              ? (this.loja.frete.valor * this.desconto.frete) / 100
              : 0;

          if (this.loja.frete.valor < 0) {
            this.loja.frete.valor = 0;
          }

          this.loja.frete.texto = this.utils.floatToReal(this.loja.frete.valor);

          this.total.valor =
            this.subtotal.valor +
            this.loja.frete.valor -
            this.desconto.valor -
            this.cashback;
          this.total.texto = this.utils.floatToReal(this.total.valor);

          this.total_sem_cashback.valor = this.total.valor + this.cashback;
          this.total_sem_cashback.texto = this.utils.floatToReal(
            this.total_sem_cashback.valor
          );
        } else {
          this.total.valor =
            this.subtotal.valor - this.desconto.valor - this.cashback;
          this.total.texto = this.utils.floatToReal(this.total.valor);

          this.total_sem_cashback.valor = this.total.valor + this.cashback;
          this.total_sem_cashback.texto = this.utils.floatToReal(
            this.total_sem_cashback.valor
          );
        }
      } else {
        this.aplicarDesconto(this.desconto.porcentagem);
      }
    }

    if (pagamento === 'CREDIT') {
      this.paymentType = `online - cr�dito - ${this.cartao.bandeira}`;
    } else if (pagamento === 'PIX') {
      this.paymentType = `online - PIX`;
    } else if (pagamento === 'cartao-entrega') {
      if (this.pagamento_info != null) {
        let paymentTypeInfo =
          this.pagamento_info.tipo == 'CREDIT'
            ? 'cr�dito'
            : this.pagamento_info.tipo == 'DEBIT'
              ? 'd�bito'
              : this.pagamento_info.tipo == 'MEAL_VOUCHER'
                ? 'voucher'
                : '';

        this.paymentType = `entrega - ${paymentTypeInfo} - ${this.pagamento_info.bandeira}`;
      }
    } else if (pagamento == 'CASH') {
      this.paymentType = 'entrega - dinheiro';
    }

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        affiliation: this.loja.nome,
        currency: 'BRL',
        ...(this.modalidade.codigo === 'DLV'
          ? { shipping: this.loja.frete.valor }
          : ''),
        value: parseFloat(this.total.valor.toFixed(2)),
        coupon: this.desconto.cupom,
        payment_type: this.paymentType,
        items: this.itemsEvent('add_payment_info'),
      },
    });
  }

  itemsEvent(event = '') {
    let items: any = [];
    let opcionais = '';
    let obrigatorios = '';
    let preco = '';

    this.carrinho.forEach((item) => {
      this.dados_produto = item;
      this.adicionais_selecionado = this.filtroAdicionais(item.adicionais);

      item.opcionais.forEach((opc) => {
        if (opc.selecionado) {
          opcionais = opc.opcional;
        }
      });

      item.obrigatorios.forEach((obr) => {
        if (obr.selecionado) {
          obrigatorios = obr.opcional;
          preco = obr.preco.unitario.valor;
        }
      });

      items.push({
        item_list_id: item.categoriaId.toString(),
        item_list_name: item.categoria_nome,
        item_name: item.nome,
        item_id: item.cod,
        item_category: item.categoria_nome,
        item_category2: false,
        item_brand: this.loja.nome_rede,
        item_variant: obrigatorios,
        price: item.preco.unitario.valor,
        currency: 'BRL',
        quantity: item.qtd,
      });

      this.adicionais_selecionado.forEach((adicional) => {
        items.push({
          item_list_id: item.categoriaId.toString(),
          item_list_name: item.categoria_nome,
          item_name: adicional.nome,
          item_id: adicional.cod,
          item_category: item.categoria_nome,
          item_category2: adicional.itemCategory2,
          item_category3: item.cod,
          item_brand: this.loja.nome_rede,
          item_variant: '',
          price: adicional.preco.unitario.valor,
          quantity: adicional.qtd,
        });
      });
    });

    if (event === 'purchase') {
      items.forEach(item => {
        item.discount = this.desconto.tipo == 'fixo' ? 0 : this.desconto.valor / items.length;
      });
    }

    return items;
  }

  mudarStatus3DS(event){
    this.utils.ocultarLoading();

    if (event.detail.value == 'unsupported') {
      this.utils.exibirToast('Bandeira n�o suportada, por favor troque de cart�o', 'erro');
      this.cartao_debito = null;
    }else if(event.detail.value != 'success'){
      this.utils.exibirToast('Ocorreu um erro na autentica��o do cart�o, tente novamente ou troque de cart�o', 'erro');
      this.cartao_debito = null;
    }
  }

  selecionarTipoPagamento(tipo) {
    this.tipoformaPagamento = tipo;
  }

  selecionarTroco(tipo) {
    this.troco = tipo == 'true' ? true : false;
  }

  abrirModalRestaurante() {
    this.modals.modalEditarRestaurante().then(async (res) => {
      if (res.data === 'sim') {
        this.utils.navegarPaginaRoot('/tabs/home');
      }
    });
  }

  adicionarCartao(tipo: string) {

    if (tipo === 'CREDIT') {

      if (this.loja.metodo_pagamento == 'braspag') {
        this.modals.modalAdicionarCartao().then((res) =>{
          this.paymentType = 'online - credito';
        });
      }else{
        this.modals.modalAdicionarCartaoMP().then((res) =>{
          this.paymentType = 'online - credito';
        });
      }

    }

    if (tipo === 'DEBIT') {


      this.modals.modalAdicionarPagamento('', tipo,'modal-add-cartao', true).then(async (res) => {

        this.cartao_debito = res.data;
        this.dados_debito = {
          ...this.dados_debito,
          "bpmpi_cardnumber": res.data.numero.replace(' ','').replace(' ','').replace(' ',''),
          "bpmpi_cardexpirationmonth": res.data.validade.split('/')[0],
          "bpmpi_cardexpirationyear": "20" + res.data.validade.split('/')[1]
        }
        this.paymentType = 'online - d�dito';

        this.utils.exibirLoading();

        setTimeout(() => {
          const script_3ds = this.renderer.createElement('script') as HTMLScriptElement;
          const scriptBody = this.renderer.createText(`bpmpi_authenticate()`);
          this.renderer.appendChild(script_3ds, scriptBody);
            this.renderer.appendChild(this.el.nativeElement, script_3ds);
        }, 1000);
      });
    }

    if (tipo === 'MEAL_VOUCHER') {
      this.modals.modalAdicionarPagamento('', tipo,'modal-add-cartao', true).then(async (res) => {
        this.cartao_vale_refeicao = res.data;
        this.paymentType = 'online - voucher';
      });
    }
  }

  modalAdicionarCartao() {
    this.modals.modalAdicionarCartao();
  }

  modalCartoesCadastrados() {
    this.modals.modalCartoesCadastrados();
  }

  erroImagem(img: any, imagemURL: string) {
    img.src = imagemURL;
  }

  filtroAdicionais(adicionais: Array<any>) {
    return adicionais.filter((item: any) => item.qtd > 0);
  }

  filtroOpcionais(opcionais: Array<any>) {
    return opcionais.filter((item: any) => item.selecionado);
  }
  filtroObrigatorios(obrigatorios: Array<any>) {
    return obrigatorios.filter((item: any) => item.selecionado);
  }
  filtroOpcionaisGerais(opcionais: Array<any>) {
    return opcionais.filter(
      (item: any) =>
        ((item.tipo == 'opcional' || item.tipo == 'obrigatorio') &&
          item.selecionado) ||
        (item.tipo == 'adicional' && item.qtd > 0)
    );
  }

  async buscarPagamentos() {
    const req = {
      lojaId: this.loja.id,
    };

    await this.utils.exibirLoading();

    const res = await this.api.get('pagamentos', req);

    if (res.status === 'success') {
      this.pagamentos = res.data;
      const listaPagamentos = Object.keys(this.pagamentos.online);

      for (let pagamento of listaPagamentos) {
        if (pagamento === 'cartao') {
          this.formaPagamento = 'CREDIT';
          break;
        }

        if (pagamento === 'pix') {
          this.formaPagamento = 'PIX';
          break;
        }
      }
    }

  }

  aplicarDesconto(percentual: number) {
    let totalDesconto: number = 0;

    this.carrinho.forEach((item) => {
      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * percentual) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * percentual) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * percentual) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * percentual) / 100;
          }
        });
      }

      totalDesconto += Number(item.desconto.total.valor.toFixed(2));
    });

    this.desconto.valor = this.subtotal.valor - totalDesconto;
    this.desconto.texto = this.utils.floatToReal(this.desconto.valor);

    if (percentual === 99) {
      this.desconto.frete = 100;
    } else {
      this.desconto.frete = 0;
    }

    this.desconto.porcentagem = percentual;

    if (this.modalidade.codigo === 'DLV') {
      this.frete =
        this.desconto.frete > 0
          ? (this.loja.frete.valor * this.desconto.frete) / 100
          : 0;

      // this.loja.frete.valor -=
      //   (this.loja.frete.valor * this.desconto.frete) / 100;

      if (this.loja.frete.valor < 0) {
        this.loja.frete.valor = 0;
      }

      this.loja.frete.texto = this.utils.floatToReal(this.loja.frete.valor);

      this.total.valor =
        this.subtotal.valor +
        this.loja.frete.valor -
        this.desconto.valor -
        this.cashback;

      this.total.texto = this.utils.floatToReal(this.total.valor);

      this.total_sem_cashback.valor = this.total.valor + this.cashback;
      this.total_sem_cashback.texto = this.utils.floatToReal(
        this.total_sem_cashback.valor
      );
    } else {
      this.total.valor =
        this.subtotal.valor - this.desconto.valor - this.cashback;
      this.total.texto = this.utils.floatToReal(this.total.valor);

      this.total_sem_cashback.valor = this.total.valor + this.cashback;
      this.total_sem_cashback.texto = this.utils.floatToReal(
        this.total_sem_cashback.valor
      );
    }
  }

  aplicarPorcentagem(porcentagem: number) {
    let totalDesconto: number = 0;

    this.carrinho.forEach((item) => {
      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * porcentagem) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * porcentagem) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * porcentagem) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * porcentagem) / 100;
          }
        });
      }

      totalDesconto += Number(item.desconto.total.valor.toFixed(2));
    });

    this.desconto.valor = this.subtotal - totalDesconto;
  }

  private async verificarPedido(cartaoId: number = 0): Promise<void> {
    const res = await this.api.post('verificar_pedido', []);

    const dadosPedido = res.data;
    if (dadosPedido) {
      const data = {
        titulo: 'Voc� j� possui um pedido em andamento.',
        paragrafos: [
          `Voc� j� possui um pedido com a loja ${dadosPedido.loja}.`,
          'Deseja continuar com o novo pedido?',
        ],
        label_acao: 'Continuar com o pedido',
        acao: 'continuar-pedido',
      };

      await this.modals.abrirModal(
        'modal-generica',
        'modal-generica',
        ModalGenericaPage,
        { data: data },
        (params: any) => {
          if (params.data === 'executar') {
            this.finalizarPedido(cartaoId);
          } else {
            this.loading = false;
            return;
          }
        }
      );
    } else {
      this.finalizarPedido(cartaoId);
    }
  }
}
