import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BrMaskerModule } from 'br-mask';
import { MaskitoModule } from '@maskito/angular';
import { TopoComponent } from './components/topo/topo.component';
import { TopoBrowserComponent } from './components/topo-browser/topo-browser.component';
import { CaixaLogoComponent } from './components/caixa-logo/caixa-logo.component';
import { DadosPessoaisWebComponent } from './components/dados-pessoais-web/dados-pessoais-web.component';
import { MeusCartoesWebComponent } from './components/meus-cartoes-web/meus-cartoes-web.component';
import { MeusEnderecosWebComponent } from './components/meus-enderecos-web/meus-enderecos-web.component';
import { NotificacoesWebComponent } from './components/notificacoes-web/notificacoes-web.component';
import { FaleConoscoWebComponent } from './components/fale-conosco-web/fale-conosco-web.component';
import { FooterWebComponent } from './components/footer-web/footer-web.component';
import { TermosPoliticasWebComponent } from './components/termos-politicas-web/termos-politicas-web.component';
import { DuvidasFrequentesWebComponent } from './components/duvidas-frequentes-web/duvidas-frequentes-web.component';
import { SobreWebComponent } from './components/sobre-web/sobre-web.component';
import { CuponsWebComponent } from './components/cupons-web/cupons-web.component';
import { CaixaCashbackComponent } from './components/caixa-cashback/caixa-cashback.component';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { ProgramaFidelidadeWebComponent } from './components/programa-fidelidade-web/programa-fidelidade-web.component';
import { CardProdutoComponent } from './components/card-produto/card-produto.component';
import { SkeletonCardProdutoComponent } from './components/skeletons/skeleton-card-produto/skeleton-card-produto.component';
import { CashbackComponent } from './componentes/cashback/cashback.component';
import { MeusEnderecosComponent } from './componentes/meus-enderecos/meus-enderecos.component';
import { BannersComponent } from './componentes/banners/banners.component';
import { InformacoesLojaComponent } from './componentes/informacoes-loja/informacoes-loja.component';
import { LocalizacaoAtualComponent } from './componentes/localizacao-atual/localizacao-atual.component';
import { LojaCardComponent } from './componentes/loja-card/loja-card.component';
import { PoliticasPrivacidadeWebComponent } from './components/politicas-privacidade-web/politicas-privacidade-web.component';
import { LojaRetiradaComponent } from './componentes/loja-retirada/loja-retirada.component';
import { ToolbarComponent } from './componentes/toolbar/toolbar.component';
import { CardapioComponent } from './components/cardapio/cardapio.component';
import { SliderCategoriasComponent } from './components/slider-categorias/slider-categorias.component';
import { SemResultadoComponent } from './components/sem-resultado/sem-resultado.component';
import { DadosCarrinhoComponent } from './components/dados-carrinho/dados-carrinho.component';
import { BaixarAppComponent } from './components/baixar-app/baixar-app.component';
import { DadosDebitoComponent } from './components/dados-debito/dados-debito.component';
import { MeusCartoesMpWebComponent } from './components/meus-cartoes-mp-web/meus-cartoes-mp-web.component';
import { SegmentComponent } from './componentes/segment/segment.component';
@NgModule({
  declarations: [
    TopoComponent,
    CaixaLogoComponent,
    DadosPessoaisWebComponent,
    MeusCartoesWebComponent,
    MeusEnderecosWebComponent,
    NotificacoesWebComponent,
    FaleConoscoWebComponent,
    TermosPoliticasWebComponent,
    PoliticasPrivacidadeWebComponent,
    DuvidasFrequentesWebComponent,
    SobreWebComponent,
    CuponsWebComponent,
    TopoBrowserComponent,
    ShowHidePasswordComponent,
    FooterWebComponent,
    CaixaCashbackComponent,
    ProgramaFidelidadeWebComponent,
    CardProdutoComponent,
    SkeletonCardProdutoComponent,
    LojaCardComponent,
    BannersComponent,
    InformacoesLojaComponent,
    MeusEnderecosComponent,
    LocalizacaoAtualComponent,
    CashbackComponent,
    SegmentComponent,
    LojaRetiradaComponent,
    ToolbarComponent,
    CardapioComponent,
    SliderCategoriasComponent,
    SemResultadoComponent,
    DadosCarrinhoComponent,
    BaixarAppComponent,
    DadosDebitoComponent,
    MeusCartoesMpWebComponent
  ],
  imports: [
    BrMaskerModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    MaskitoModule,
  ],
  exports: [
    TopoComponent,
    CaixaLogoComponent,
    DadosPessoaisWebComponent,
    MeusCartoesWebComponent,
    MeusEnderecosWebComponent,
    NotificacoesWebComponent,
    FaleConoscoWebComponent,
    TermosPoliticasWebComponent,
    PoliticasPrivacidadeWebComponent,
    DuvidasFrequentesWebComponent,
    SobreWebComponent,
    CuponsWebComponent,
    TopoBrowserComponent,
    ShowHidePasswordComponent,
    FooterWebComponent,
    CaixaCashbackComponent,
    ProgramaFidelidadeWebComponent,
    CardProdutoComponent,
    SkeletonCardProdutoComponent,
    LojaCardComponent,
    BannersComponent,
    InformacoesLojaComponent,
    MeusEnderecosComponent,
    LocalizacaoAtualComponent,
    CashbackComponent,
    SegmentComponent,
    LojaRetiradaComponent,
    ToolbarComponent,
    CardapioComponent,
    SliderCategoriasComponent,
    SemResultadoComponent,
    DadosCarrinhoComponent,
    BaixarAppComponent,
    DadosDebitoComponent,
    MeusCartoesMpWebComponent
  ],
})
export class ComponentsModule { }
