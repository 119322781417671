import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss'],
})
export class FooterWebComponent implements OnInit {
  constructor(
    private inAppBrowser: InAppBrowser,
    public utilsService: UtilsService
  ) { }

  ngOnInit() { }

  facebook() {
    const browser = this.inAppBrowser.create(
      'https://pt-br.facebook.com/grupotrigooficial/',
      '_system'
    );
    browser.show();
  }
  instagram() {
    const browser = this.inAppBrowser.create(
      'https://instagram.com/fomedeqapp?igshid=MzRlODBiNWFlZA==',
      '_system'
    );
    browser.show();
  }
  linkedin() {
    const browser = this.inAppBrowser.create(
      'https://www.linkedin.com/company/grupotrigo/',
      '_system'
    );
    browser.show();
  }

  neoAssist() {
    this.utilsService.navegarPaginaRoot('/tabs/fale-conosco');
  }
}
