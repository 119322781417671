import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-termos',
  templateUrl: './politica-termos.page.html',
  styleUrls: ['./politica-termos.page.scss'],
})
export class PoliticaTermosPage implements OnInit {

  constructor(
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }

  fechar() {
    this.modalController.dismiss();
  }

}
