import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HostListener, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { EventsService } from './events.service';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile();
  }
  constructor(
    private alertController: AlertController,
    private navController: NavController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private statusBar: StatusBar,
    private iab: InAppBrowser,
    private events: EventsService
  ) { }

  alteraStatusBar(tipo: string) {
    if (tipo === 'claro') {
      this.statusBar.backgroundColorByHexString('#FFFFFF');
      this.statusBar.styleDefault();
    } else {
      this.statusBar.backgroundColorByHexString('#000000');
      this.statusBar.styleLightContent();
    }
  }

  navegarPagina(pagina: string, animacao: boolean = true) {
    this.navController.navigateForward(pagina, { animated: animacao });
  }

  navegarPaginaRoot(pagina: string) {
    this.navController.navigateRoot(pagina);
  }

  private dados: any;
  setDados(dados: any) {
    this.dados = dados;
  }

  getDados() {
    const dados = this.dados;
    this.dados = null;
    return dados;
  }

  voltarPagina(pagina: string, animacao: boolean = true) {
    this.navController.navigateBack(pagina, { animated: animacao });
  }

  voltarUltima() {
    this.navController.back();
  }

  public setStorage(key: string, valor: any) {
    localStorage.setItem(`trigo.${key}`, JSON.stringify(valor));
  }

  public getStorage(key: string) {
    const dados: any = localStorage.getItem(`trigo.${key}`);

    if (dados !== undefined) {
      return JSON.parse(dados);
    }
  }

  public removeStorage(key: string) {
    localStorage.removeItem(`trigo.${key}`);
  }

  public checkStorage(key: string) {
    return localStorage.getItem(`trigo.${key}`) == null ? false : true;
  }

  public async exibirLoading() {
    const loading = await this.loadingController.create({
      message: '<ion-spinner name="crescent"></ion-spinner>',
      spinner: null,
      cssClass: 'loading',
    });
    return await loading.present();
  }

  public async exibirToast(mensagem: string, status: string = 'sucesso') {
    const toast = await this.toastController.create({
      position: 'top',
      message: mensagem,
      buttons: [
        {
          icon: status === 'erro' ? 'close-circle-sharp' : '',
        },
      ],
      cssClass: 'toast-' + status,
      duration: 1600,
    });

    if (status == 'erro') {
      dataLayer.push({
        event: 'showErrorMessage',
        message: mensagem,
      });
    }

    return await toast.present();
  }

  public async ocultarLoading() {
    try {
      return await this.loadingController.dismiss();
    } catch (error) {
      return false;
    }
  }

  public async exibirAlert(header: string, message: string): Promise<any> {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });

    return await alert.present();
  }

  public validaCelular(numero: string) {
    const regExp =
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
    return regExp.test(numero);
  }

  public validaSenha(senha: string) {
    const regExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return regExp.test(senha);
  }

  public passwordValidator(string: string) {
    // Express�es regulares para verificar a presen�a de cada tipo de caractere
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /[0-9]/;
    const regexSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    let errorMessage = '';

    if (string.length < 8) {
      errorMessage = 'A senha deve conter pelo menos 8 caracteres.';
    } else if (!regexUpperCase.test(string)) {
      errorMessage = 'A senha deve conter pelo menos uma letra mai�scula.';
    } else if (!regexLowerCase.test(string)) {
      errorMessage = 'A senha deve conter pelo menos uma letra min�scula.';
    } else if (!regexNumber.test(string)) {
      errorMessage = 'A senha deve conter pelo menos um n�mero.';
    } else if (!regexSpecialChar.test(string)) {
      errorMessage = 'A senha deve conter pelo menos um caractere especial.';
    }

    if (errorMessage) {
      this.exibirToast(errorMessage, 'erro');
      return false;
    } else {
      return true;
    }
  }

  public validaCEP(cep: string) {
    const regExp = /[0-9]{5}-[\d]{3}/g;
    return regExp.test(cep);
  }

  public removeSpaces(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof FormGroup) {
        this.removeSpaces(control);
      } else if (control.value && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  public validaForm(form: FormGroup, control: Object) {
    this.removeSpaces(form);
    let errors = 0;
    if (form.valid) {
      return true;
    } else {
      for (const key in form.controls) {
        if (
          form.controls[key].value == null ||
          form.controls[key].value == ''
        ) {
          if (control[key]) {
            let pronome =
              control[key].charAt(0) === 'A' ? 'obrigat�ria' : 'obrigat�rio';
            errors++;
            this.exibirToast(`${control[key]} � ${pronome}!`, 'erro');
            // this.exibirToast('H� campos n�o preenchidos no formul�rio!', 'erro');
            return false;
          }
        }

        if (
          form.controls[key].errors != null &&
          form.controls[key].errors?.required
        ) {
          if (control[key]) {
            let pronome =
              control[key].charAt(0) === 'A' ? 'obrigat�ria' : 'obrigat�rio';
            errors++;
            this.exibirToast(`${control[key]} � ${pronome}!`, 'erro');
            // this.exibirToast('H� campos n�o preenchidos no formul�rio!', 'erro');
            return false;
          }
        }

        if (form.controls[key].errors?.email) {
          this.exibirToast('E-mail inv�lido!', 'erro');
          errors++;
          return false;
        }

        if (form.controls[key].errors?.minlength) {
          if (
            form.controls[key].errors?.minlength.requiredLength >
            form.controls[key].errors?.minlength.actualLength
          ) {
            this.exibirToast(
              'A senha deve conter no m�nimo 8 caracteres!',
              'erro'
            );
            errors++;
            return false;
          }
        }
      }

      if (errors == 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  public validaCPF(cpf: string) {
    cpf = cpf.replace(/\D/g, '');

    let soma: number = 0;
    let resto: number;

    const cpfs = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];

    if (cpfs.includes(cpf)) return false;

    for (let index = 1; index <= 9; index++) {
      soma += parseInt(cpf.substring(index - 1, index)) * (11 - index);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;

    for (let index = 1; index <= 10; index++) {
      soma += parseInt(cpf.substring(index - 1, index)) * (12 - index);
    }

    resto = (soma * 10) % 11;
    resto = resto === 10 || resto === 11 ? 0 : resto;

    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  }

  public validaValidade(data: string) {
    let [mes, ano] = data.split('/');

    ano = Number(ano) > 40 ? '19' + ano : '20' + ano;

    let hoje = new Date();
    let validade = new Date(Number(ano), Number(mes), 1);

    if (hoje.getTime() > validade.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  public floatToReal(valor: number) {
    return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  public realToFloat(valor: string) {
    return Number.parseFloat(valor.replace('R$ ', '').replace(',', '.'));
  }

  public formataDecimal(valor: number) {
    return Number(valor.toFixed(2));
  }

  openLink(link) {
    this.iab.create(link, '_system');
  }

  getLocationService(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((resp) => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      });
    });
  }

  getLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  empty(data: any) {
    if (
      data?.length === 0 ||
      data === '' ||
      data === undefined ||
      data === null ||
      Number.isNaN(data)
    ) {
      return true;
    }
    if (Object.keys(data).length === 0 && data?.constructor === Object) {
      return true;
    }
    return false;
  }

  async limparCarrinho() {
    this.removeStorage('carrinho');
    this.removeStorage('loja_carrinho');
    this.removeStorage('modalidade_carrinho');
    this.events.publish('atualizar-carrinho', {
      limpar: true,
    });
  }

  public removeChar(string: String) {
    return string.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  limparLoja(): void {
    this.removeStorage('sugestoes-loja');
    this.removeStorage('loja_carrinho');
    this.removeStorage('produto');
    this.removeStorage('loja');
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  public copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.exibirToast(
          'Texto copiado para a �rea de transfer�ncia!',
          'sucesso'
        );
      })
      .catch((error) => {
        this.exibirToast(
          'Erro ao copiar para a �rea de transfer�ncia:',
          'erro'
        );
      });
  }

  public validUri(text: string): string {
    if (text !== undefined) {
      return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[\W_]+/g, '')
        .toLowerCase()
        .replace(/^-+|-+$/g, '');
    } else return text;
  }

  public scroll(elemento: string = '', offsetTop: number = 0): void {
    document.getElementById('content').scroll({
      top:
        elemento !== ''
          ? document.getElementById(elemento).offsetTop - offsetTop
          : offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }

  link(link: string, isALink: boolean = false) {
    const links = {
      facebook: 'https://pt-br.facebook.com/',
      instagram: 'https://www.instagram.com/',
      linkedin: 'https://www.linkedin.com/company/grupotrigo/',
      twitter: 'https://twitter.com/',
      playstore: 'https://.page.link/pop-upweb',
      appstore: 'https://.page.link/pop-upweb',
      franqueado: 'https://www.grupotrigo.com.br/?utm_source=chinainbox.com.br&utm_medium=button_menu&utm_campaign=EXPANSAO&utm_content=SEJA_UM_FRANQUEADO',
    };

    const url = links[link];
    if (url) {
      this.iab.create(url, '_system').show();
    } else if (isALink) {
      this.iab.create(link, '_system').show();
    }
  }

  public applyMascaraCEP(cep: string) {
    cep = cep.replace(/\D/g, '');
    cep = cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');

    return cep;
  }

  public checkValueCep(valor: string) {
    return /^\d{8}$|^\d{5}-\d{3}$/.test(valor);
  }
}
