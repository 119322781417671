import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutenticacaoService } from './services/autenticacao.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'gendai',
    loadChildren: () => import('./pages/gendai/gendai.module').then(m => m.GendaiPageModule)
  },
  {
    path: 'gendai/:jwt',
    loadChildren: () => import('./pages/gendai/gendai.module').then(m => m.GendaiPageModule)
  },
  {
    path: 'gokei',
    loadChildren: () => import('./pages/gokei/gokei.module').then(m => m.GokeiPageModule)
  },
  {
    path: 'owan',
    loadChildren: () => import('./pages/owan/owan.module').then(m => m.OwanPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'produto',
    loadChildren: () => import('./pages/produto/produto.module').then(m => m.ProdutoPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/usuario/perfil/perfil.module').then(m => m.PerfilPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'dados-pessoais',
    loadChildren: () => import('./pages/usuario/dados-pessoais/dados-pessoais.module').then(m => m.DadosPessoaisPageModule)
  },
  {
    path: 'alterar-senha',
    loadChildren: () => import('./modals/alterar-senha/alterar-senha.module').then(m => m.AlterarSenhaPageModule)
  },
  {
    path: 'meus-enderecos',
    loadChildren: () => import('./pages/usuario/meus-enderecos/meus-enderecos.module').then(m => m.MeusEnderecosPageModule)
  },
  {
    path: 'excluir-endereco',
    loadChildren: () => import('./modals/excluir-endereco/excluir-endereco.module').then(m => m.ExcluirEnderecoPageModule)
  },
  {
    path: 'inativar-conta',
    loadChildren: () => import('./modals/inativar-conta/inativar-conta.module').then(m => m.InativarContaPageModule)
  },
  {
    path: 'simples',
    loadChildren: () => import('./modals/simples/simples.module').then(m => m.SimplesPageModule)
  },
  {
    path: 'excluir-conta',
    loadChildren: () => import('./modals/excluir-conta/excluir-conta.module').then(m => m.ExcluirContaPageModule)
  },
  {
    path: 'finalizar-pedido',
    loadChildren: () => import('./pages/finalizar-pedido/finalizar-pedido.module').then(m => m.FinalizarPedidoPageModule)
  },
  {
    path: 'historico',
    loadChildren: () => import('./pages/historico/historico.module').then(m => m.HistoricoPageModule)
  },
  {
    path: 'detalhes-pedido',
    loadChildren: () => import('./pages/pedidos/detalhes-pedido/detalhes-pedido.module').then(m => m.DetalhesPedidoPageModule)
  },
  {
    path: 'detalhes-pedido',
    loadChildren: () => import('./modals/detalhes-pedido/detalhes-pedido.module').then(m => m.DetalhesPedidoPageModule)
  },
  {
    path: 'detalhes-loja',
    loadChildren: () => import('./pages/detalhes-loja/detalhes-loja.module').then(m => m.DetalhesLojaPageModule)
  },
  {
    path: 'meus-cartoes',
    loadChildren: () => import('./pages/usuario/meus-cartoes/meus-cartoes.module').then(m => m.MeusCartoesPageModule)
  },
  {
    path: 'adicionar-cartao',
    loadChildren: () => import('./modals/adicionar-cartao/adicionar-cartao.module').then(m => m.AdicionarCartaoPageModule)
  },
  {
    path: 'notificacoes',
    loadChildren: () => import('./pages/usuario/notificacoes/notificacoes.module').then(m => m.NotificacoesPageModule)
  },
  {
    path: 'configurar-notificacoes',
    loadChildren: () => import('./pages/usuario/configurar-notificacoes/configurar-notificacoes.module').then(m => m.ConfigurarNotificacoesPageModule)
  },
  {
    path: 'termos-politicas',
    loadChildren: () => import('./pages/usuario/termos-politicas/termos-politicas.module').then(m => m.TermosPoliticasPageModule)
  },
  {
    path: 'sobre',
    loadChildren: () => import('./pages/usuario/sobre/sobre.module').then(m => m.SobrePageModule)
  },
  {
    path: 'cupons',
    loadChildren: () => import('./pages/cupons/cupons.module').then(m => m.CuponsPageModule)
  },
  {
    path: 'cpf-nota',
    loadChildren: () => import('./modals/cpf-nota/cpf-nota.module').then(m => m.CpfNotaPageModule)
  },
  {
    path: 'cartoes-cadastrados',
    loadChildren: () => import('./modals/cartoes-cadastrados/cartoes-cadastrados.module').then(m => m.CartoesCadastradosPageModule)
  },
  {
    path: 'excluir-cartao',
    loadChildren: () => import('./modals/excluir-cartao/excluir-cartao.module').then(m => m.ExcluirCartaoPageModule)
  },
  {
    path: 'limpar-sacola',
    loadChildren: () => import('./modals/limpar-sacola/limpar-sacola.module').then(m => m.LimparSacolaPageModule)
  },
  {
    path: 'produto',
    loadChildren: () => import('./modals/produto/produto.module').then(m => m.ProdutoPageModule)
  },
  {
    path: 'editar-produto',
    loadChildren: () => import('./modals/editar-produto/editar-produto.module').then(m => m.EditarProdutoPageModule)
  },
  {
    path: 'limpar-sacola-modalidade',
    loadChildren: () => import('./modals/limpar-sacola-modalidade/limpar-sacola-modalidade.module').then(m => m.LimparSacolaModalidadePageModule)
  },
  {
    path: 'pagamento-pix',
    loadChildren: () => import('./modals/pagamento-pix/pagamento-pix.module').then(m => m.PagamentoPixPageModule)
  },
  {
    path: 'cliente-importado',
    loadChildren: () => import('./modals/cliente-importado/cliente-importado.module').then(m => m.ClienteImportadoPageModule)
  },
  {
    path: 'avaliacao',
    loadChildren: () => import('./modals/avaliacao/avaliacao.module').then(m => m.AvaliacaoPageModule)
  },
  {
    path: 'confirma-modalidade',
    loadChildren: () => import('./modals/confirma-modalidade/confirma-modalidade.module').then(m => m.ConfirmaModalidadePageModule)
  },
  {
    path: 'confirmar-edicao-restaurante',
    loadChildren: () => import('./modals/confirmar-edicao-restaurante/confirmar-edicao-restaurante.module').then(m => m.ConfirmarEdicaoRestaurantePageModule)
  },
  {
    path: 'trocar-restaurante',
    loadChildren: () => import('./modals/trocar-restaurante/trocar-restaurante.module').then(m => m.TrocarRestaurantePageModule)
  },
  {
    path: 'editar-produto',
    loadChildren: () => import('./modals/editar-produto/editar-produto.module').then(m => m.EditarProdutoPageModule)
  },
  {
    path: 'mudar-lojas',
    loadChildren: () => import('./modals/mudar-lojas/mudar-lojas.module').then(m => m.MudarLojasPageModule)
  },
  {
    path: 'duvidas-frequentes',
    loadChildren: () => import('./pages/usuario/duvidas-frequentes/duvidas-frequentes.module').then(m => m.DuvidasFrequentesPageModule)
  },
  {
    path: 'busca-restaurantes',
    loadChildren: () => import('./modals/busca-restaurantes/busca-restaurantes.module').then(m => m.BuscaRestaurantesPageModule)
  },
  {
    path: 'novo-chamado',
    loadChildren: () => import('./pages/usuario/novo-chamado/novo-chamado.module').then(m => m.NovoChamadoPageModule)
  },
  {
    path: 'ver-chamado/:id',
    loadChildren: () => import('./pages/usuario/ver-chamado/ver-chamado.module').then(m => m.VerChamadoPageModule)
  },
  {
    path: 'sugestoes',
    loadChildren: () => import('./modals/sugestoes/sugestoes.module').then(m => m.SugestoesPageModule)
  },
  {
    path: 'selecionar-modalidade',
    loadChildren: () => import('./modals/selecionar-modalidade/selecionar-modalidade.module').then(m => m.SelecionarModalidadePageModule)
  },
  {
    path: 'historico-browser',
    loadChildren: () => import('./pages/historico-browser/historico-browser.module').then(m => m.HistoricoBrowserPageModule)
  },
  {
    path: 'novo-chamado',
    loadChildren: () => import('./modals/novo-chamado/novo-chamado.module').then(m => m.NovoChamadoPageModule)
  },
  {
    path: 'inativar-conta-web',
    loadChildren: () => import('./modals/inativar-conta-web/inativar-conta-web.module').then(m => m.InativarContaWebPageModule)
  },
  {
    path: 'excluir-conta-web',
    loadChildren: () => import('./modals/excluir-conta-web/excluir-conta-web.module').then(m => m.ExcluirContaWebPageModule)
  },
  {
    path: 'alterar-senha-web',
    loadChildren: () => import('./modals/alterar-senha-web/alterar-senha-web.module').then(m => m.AlterarSenhaWebPageModule)
  },
  {
    path: 'simples-web',
    loadChildren: () => import('./modals/simples-web/simples-web.module').then(m => m.SimplesWebPageModule)
  },
  {
    path: 'excluir-cartao-web',
    loadChildren: () => import('./modals/excluir-cartao-web/excluir-cartao-web.module').then(m => m.ExcluirCartaoWebPageModule)
  },
  {
    path: 'excluir-endereco-web',
    loadChildren: () => import('./modals/excluir-endereco-web/excluir-endereco-web.module').then(m => m.ExcluirEnderecoWebPageModule)
  },
  {
    path: 'search-produto-browser',
    loadChildren: () => import('./modals/search-produto-browser/search-produto-browser.module').then(m => m.SearchProdutoBrowserPageModule)
  },
  {
    path: 'modal-produto',
    loadChildren: () => import('./modals/modal-produto/modal-produto.module').then(m => m.ModalProdutoPageModule)
  },
  {
    path: 'browser-confirma-modalidade',
    loadChildren: () => import('./modals/browser-confirma-modalidade/browser-confirma-modalidade.module').then(m => m.BrowserConfirmaModalidadePageModule)
  },
  {
    path: 'browser-sugestoes',
    loadChildren: () => import('./modals/browser-sugestoes/browser-sugestoes.module').then(m => m.BrowserSugestoesPageModule)
  },
  {
    path: 'modal-carrinho-browser',
    loadChildren: () => import('./modals/modal-carrinho-browser/modal-carrinho-browser.module').then(m => m.ModalCarrinhoBrowserPageModule)
  },
  {
    path: 'finalizar-pedido-browser',
    loadChildren: () => import('./pages/finalizar-pedido-browser/finalizar-pedido-browser.module').then(m => m.FinalizarPedidoBrowserPageModule)
  },
  {
    path: 'adicionar-cartao-browser',
    loadChildren: () => import('./modals/adicionar-cartao-browser/adicionar-cartao-browser.module').then(m => m.AdicionarCartaoBrowserPageModule)
  },
  {
    path: 'adicionar-cpf-browser',
    loadChildren: () => import('./modals/adicionar-cpf-browser/adicionar-cpf-browser.module').then(m => m.AdicionarCpfBrowserPageModule)
  },
  {
    path: 'selecionar-modalidade-browser',
    loadChildren: () => import('./modals/selecionar-modalidade-browser/selecionar-modalidade-browser.module').then(m => m.SelecionarModalidadeBrowserPageModule)
  },
  {
    path: 'detalhes-loja-browser',
    loadChildren: () => import('./modals/detalhes-loja-browser/detalhes-loja-browser.module').then(m => m.DetalhesLojaBrowserPageModule)
  },
  {
    path: 'cartoes-cadastrados-browser',
    loadChildren: () => import('./modals/cartoes-cadastrados-browser/cartoes-cadastrados-browser.module').then(m => m.CartoesCadastradosBrowserPageModule)
  },
  {
    path: 'selecionar-forma-pagamento',
    loadChildren: () => import('./modals/selecionar-forma-pagamento/selecionar-forma-pagamento.module').then(m => m.SelecionarFormaPagamentoPageModule)
  },
  {
    path: 'programa-fidelidade',
    loadChildren: () => import('./modals/fidelidade/programa-fidelidade/programa-fidelidade.module').then(m => m.ProgramaFidelidadePageModule)
  },
  {
    path: 'regulamento',
    loadChildren: () => import('./pages/fidelidade/regulamento/regulamento.module').then(m => m.RegulamentoPageModule)
  },
  {
    path: 'extrato/:id',
    loadChildren: () => import('./pages/fidelidade/extrato/extrato.module').then(m => m.ExtratoPageModule)
  },
  {
    path: 'adicionar-cartao-browser',
    loadChildren: () => import('./modals/adicionar-cartao-browser/adicionar-cartao-browser.module').then(m => m.AdicionarCartaoBrowserPageModule)
  },
  {
    path: 'adicionar-cpf-browser',
    loadChildren: () => import('./modals/adicionar-cpf-browser/adicionar-cpf-browser.module').then(m => m.AdicionarCpfBrowserPageModule)
  },
  {
    path: 'selecionar-modalidade-browser',
    loadChildren: () => import('./modals/selecionar-modalidade-browser/selecionar-modalidade-browser.module').then(m => m.SelecionarModalidadeBrowserPageModule)
  },
  {
    path: 'detalhes-loja-browser',
    loadChildren: () => import('./modals/detalhes-loja-browser/detalhes-loja-browser.module').then(m => m.DetalhesLojaBrowserPageModule)
  },
  {
    path: 'cartoes-cadastrados-browser',
    loadChildren: () => import('./modals/cartoes-cadastrados-browser/cartoes-cadastrados-browser.module').then(m => m.CartoesCadastradosBrowserPageModule)
  },
  {
    path: 'selecionar-forma-pagamento',
    loadChildren: () => import('./modals/selecionar-forma-pagamento/selecionar-forma-pagamento.module').then(m => m.SelecionarFormaPagamentoPageModule)
  },
  {
    path: 'search/:path',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'termos-politica',
    loadChildren: () => import('./pages/termos-politica/termos-politica.module').then(m => m.TermosPoliticaPageModule)
  },
  {
    path: 'modal-confirmacao-web',
    loadChildren: () => import('./modals/modal-confirmacao-web/modal-confirmacao-web.module').then(m => m.ModalConfirmacaoWebPageModule)
  }, {
    path: 'politica-termos',
    loadChildren: () => import('./pages/politica-termos/politica-termos.module').then(m => m.PoliticaTermosPageModule)
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./pages/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadePageModule)
  },
  {
    path: 'fidelidade-regulamento',
    loadChildren: () => import('./pages/fidelidade-regulamento/fidelidade-regulamento.module').then(m => m.FidelidadeRegulamentoPageModule)
  },
  {
    path: 'confirma-cartao',
    loadChildren: () => import('./modals/confirma-cartao/confirma-cartao.module').then(m => m.ConfirmaCartaoPageModule)
  },
  {
    path: 'selecionar-forma-pagamento-web',
    loadChildren: () => import('./modals/selecionar-forma-pagamento-web/selecionar-forma-pagamento-web.module').then(m => m.SelecionarFormaPagamentoWebPageModule)
  },
  {
    path: 'modal-mudar-lojas-web',
    loadChildren: () => import('./modals/modal-mudar-lojas-web/modal-mudar-lojas-web.module').then(m => m.ModalMudarLojasWebPageModule)
  },
  {
    path: 'confirma-edicao-restaurante-web',
    loadChildren: () => import('./modals/confirma-edicao-restaurante-web/confirma-edicao-restaurante-web.module').then(m => m.ConfirmaEdicaoRestauranteWebPageModule)
  },
  {
    path: 'carrinho',
    loadChildren: () => import('./pages/carrinho/carrinho.module').then(m => m.CarrinhoPageModule),
  },
  {
    path: 'modal-generica',
    loadChildren: () => import('./modals/modal-generica/modal-generica.module').then(m => m.ModalGenericaPageModule)
  },

  //onboarding
  {
    path: 'login',
    loadChildren: () => import('./pages/onboarding/login/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AutenticacaoService],
  },
  {
    path: 'login-email',
    loadChildren: () => import('./pages/onboarding/login/login-email/login-email.module').then(m => m.LoginEmailPageModule)
  },
  {
    path: 'esqueci-senha-um',
    loadChildren: () => import('./pages/onboarding/esqueci-senha/esqueci-senha-um/esqueci-senha-um.module').then(m => m.EsqueciSenhaUmPageModule)
  },
  {
    path: 'esqueci-senha-dois',
    loadChildren: () => import('./pages/onboarding/esqueci-senha/esqueci-senha-dois/esqueci-senha-dois.module').then(m => m.EsqueciSenhaDoisPageModule)
  },
  {
    path: 'esqueci-senha-tres',
    loadChildren: () => import('./pages/onboarding/esqueci-senha/esqueci-senha-tres/esqueci-senha-tres.module').then(m => m.EsqueciSenhaTresPageModule)
  },
  {
    path: 'esqueci-senha-quatro',
    loadChildren: () => import('./pages/onboarding/esqueci-senha/esqueci-senha-quatro/esqueci-senha-quatro.module').then(m => m.EsqueciSenhaQuatroPageModule)
  },
  {
    path: 'login-telefone',
    loadChildren: () => import('./pages/onboarding/login/login-telefone/login-telefone.module').then(m => m.LoginTelefonePageModule)
  },
  {
    path: 'confirmar-codigo',
    loadChildren: () => import('./pages/onboarding/login/confirmar-codigo/confirmar-codigo.module').then(m => m.ConfirmarCodigoPageModule)
  },
  {
    path: 'cadastro-dados',
    loadChildren: () => import('./pages/onboarding/cadastro/cadastro-dados/cadastro-dados.module').then(m => m.CadastroDadosPageModule)
  },
  {
    path: 'buscar-endereco',
    loadChildren: () => import('./pages/onboarding/cadastro/buscar-endereco/buscar-endereco.module').then(m => m.BuscarEnderecoPageModule)
  },
  {
    path: 'cadastro-endereco',
    loadChildren: () => import('./pages/onboarding/cadastro/cadastro-endereco/cadastro-endereco.module').then(m => m.CadastroEnderecoPageModule)
  },
  {
    path: 'buscar-endereco/:pagina',
    loadChildren: () => import('./pages/onboarding/cadastro/buscar-endereco/buscar-endereco.module').then(m => m.BuscarEnderecoPageModule)
  },
  {
    path: 'cadastro-endereco/:pagina',
    loadChildren: () => import('./pages/onboarding/cadastro/cadastro-endereco/cadastro-endereco.module').then(m => m.CadastroEnderecoPageModule)
  },
  {
    path: 'cadastro-permissoes',
    loadChildren: () => import('./pages/onboarding/cadastro/cadastro-permissoes/cadastro-permissoes.module').then(m => m.CadastroPermissoesModule)
  },
  {
    path: 'politicas-privacidade',
    loadChildren: () => import('./pages/usuario/politicas-privacidade/politicas-privacidade.module').then(m => m.PoliticasPrivacidadePageModule)
  },
  {
    path: 'pagamento-pedido-pix',
    loadChildren: () => import('./pages/pagamento-pedido-pix/pagamento-pedido-pix.module').then(m => m.PagamentoPedidoPixPageModule)
  },
  {
    path: 'escolher-restaurante-retirada',
    loadChildren: () => import('./modals/fluxo-retirada/escolher-restaurante-retirada/escolher-restaurante-retirada.module').then(m => m.EscolherRestauranteRetiradaPageModule)
  },
  {
    path: 'confirmar-restaurante-retirada',
    loadChildren: () => import('./modals/fluxo-retirada/confirmar-restaurante-retirada/confirmar-restaurante-retirada.module').then(m => m.ConfirmarRestauranteRetiradaPageModule)
  },
  {
    path: 'aviso-endereco',
    loadChildren: () => import('./modals/aviso-endereco/aviso-endereco.module').then(m => m.AvisoEnderecoPageModule)
  },
  {
    path: 'adicionar-cartao-browser-mp',
    loadChildren: () => import('./modals/adicionar-cartao-browser-mp/adicionar-cartao-browser-mp.module').then(m => m.AdicionarCartaoBrowserMpPageModule)
  },
  {
    path: 'adicionar-cartao-mp',
    loadChildren: () => import('./modals/adicionar-cartao-mp/adicionar-cartao-mp.module').then(m => m.AdicionarCartaoMpPageModule)
  },
  {
    path: 'adicionar-pagamento',
    loadChildren: () => import('./modals/adicionar-pagamento/adicionar-pagamento.module').then(m => m.AdicionarPagamentoPageModule)
  },
  {
    path: 'modal-pagamento-pix-falha',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pix-falha/modal-pagamento-pix-falha.module').then(m => m.ModalPagamentoPixFalhaPageModule)
  },
  {
    path: 'modal-pagamento-pedido-pix',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pedido-pix/modal-pagamento-pedido-pix.module').then(m => m.ModalPagamentoPedidoPixPageModule)
  },
  {
    path: 'koni',
    loadChildren: () => import('./pages/koni/koni.module').then(m => m.KoniPageModule)
  },
  {
    path: 'koni/:jwt',
    loadChildren: () => import('./pages/koni/koni.module').then(m => m.KoniPageModule)
  },
  {
    path: 'pokes-do-china-in-box',
    loadChildren: () => import('./pages/pokes-do-china-in-box/pokes-do-china-in-box.module').then(m => m.PokesDoChinaInBoxPageModule)
  },
  {
    path: 'mapa-confirmacao',
    loadChildren: () => import('./pages/onboarding/cadastro/mapa-confirmacao/mapa-confirmacao.module').then(m => m.MapaConfirmacaoPageModule)
  },
  {
    path: 'fale-conosco',
    loadChildren: () => import('./pages/fale-conosco/fale-conosco.module').then(m => m.FaleConoscoPageModule)
  },
  {
    path: '**',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'modal-endereco-numero',
    loadChildren: () => import('./modals/modal-endereco-numero/modal-endereco-numero.module').then(m => m.ModalEnderecoNumeroPageModule)
  },  {
    path: 'modal-confirmar-endereco',
    loadChildren: () => import('./modals/modal-confirmar-endereco/modal-confirmar-endereco.module').then( m => m.ModalConfirmarEnderecoPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
