import { Injectable } from '@angular/core';

import { ApiService } from '../api.service';
import { CarrinhoService } from '../carrinho/carrinho.service';
import { EnderecoService } from '../endereco/endereco.service';
import { ModalsService } from '../modals/modals.service';
import { UsuarioService } from './usuario.service';
import { UtilsService } from '../utils.service';

import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';

@Injectable({
  providedIn: 'root',
})
export class ControladorContaService {
  private jwt: string = this.utilsService.getStorage('usuario').jwt;

  constructor(
    private apiService: ApiService,
    private enderecoService: EnderecoService,
    private carrinhoService: CarrinhoService,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {}

  public excluirConta(): void {
    this.handleRequest('cliente', 'delete');
  }

  public inativarConta(): void {
    this.handleRequest('inativar_conta', 'post').then((res) => {
      if (res) {
        this.modalsService.abrirModal(
          'modal-generica',
          'modal-generica',
          ModalGenericaPage,
          {
            data: {
              titulo: 'Conta inativada com sucesso',
              paragrafos: [
                'A sua conta está inativa, mas se quiser reativa-la é simples! Basta entrar em contato com nossa equipe que já vamos te ativar de novo. Esperamos por você.',
              ],
              paragrafo_secundario: '',
              label_acao: 'Fechar',
              acao: 'fechar',
            },
          }
        );
      }
    });
  }

  private async handleRequest(
    url: string,
    method: 'delete' | 'post'
  ): Promise<boolean> {
    this.utilsService.exibirLoading;

    const res = await this.apiService[method](url, { jwt: this.jwt });

    this.utilsService.ocultarLoading();

    if (res.status === 'success') {
      this.sair(res.message);

      return true;
    } else {
      this.utilsService.exibirToast(res.message, 'erro');

      return false;
    }
  }

  private sair(message: string): void {
    this.usuarioService.logOff(message);
    this.carrinhoService.limparCarrinho();
    this.enderecoService.atualizarListaEnderecos(null);
  }
}
