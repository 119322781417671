import { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export const cartaoMask: MaskitoOptions = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const celularMask: MaskitoOptions = {
  mask: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const cepMask: MaskitoOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
};

export const cpfMask: MaskitoOptions = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
};

export const currencyMask = maskitoNumberOptionsGenerator({
  min: 0,
  precision: 2,
  decimalSeparator: ',',
  decimalPseudoSeparators: ['.', ','],
  decimalZeroPadding: false,
  thousandSeparator: '.',
  prefix: 'R$ ',
});

export const cvvMask: MaskitoOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/],
};

export const dataMask: MaskitoOptions = {
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

export const nomeMask: MaskitoOptions = {
  mask: /^[^0-9!@#$%¨&*()_\-+{}\[\]<>,:;|\\\/?]+$/,
  postprocessors: [({ value, selection }) => ({ value: value, selection })],
};

export const validadeMask: MaskitoOptions = {
  mask: [/\d/, /\d/, '/', /\d/, /\d/],
};
