import { Component, OnInit } from '@angular/core';
import {  ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detalhes-loja-browser',
  templateUrl: './detalhes-loja-browser.page.html',
  styleUrls: ['./detalhes-loja-browser.page.scss'],
})
export class DetalhesLojaBrowserPage implements OnInit {
  public loja: any;
  public modalidade: any;
  public tipoCompra: any;
  private interval: any;
  public modalidadeAtiva: string;
  public mudarRestaurantes;
  public restaurante;
  public modalidades: Array<any> = [];

  public modalidade_codigo: any = {
    "DLV": 'delivery',
    "TTA": 'totake',
    "TGO": 'toGo'
  }

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private modalService: ModalsService,
    private api: ApiService,
    private events: EventsService,
  ) {
    this.loja = this.utils.getStorage('loja');
  
    this.events.subscribe('loja', (res)  => {
      console.log('evento',this.utils.getStorage('loja'));
      
      this.loja = this.utils.getStorage('loja');
      this.restaurante = this.loja;
    });
  }

  ngOnInit() {
    this.buscarLoja();
    
  }

  async buscarLoja() {
    const loja = this.utils.getStorage('loja');
    const req = {
      lojaId: loja.id,
      marcaId: loja.marcaId,
    };

    const res = await this.api.get('loja', req);

    if (res.status === 'success') {
      this.loja = res.data;

      this.abrirDetalhes(this.modalidade_codigo[this.utils.getStorage('modalidade').codigo])

      this.modalitySegment(this.loja.modalidades);
    }

  }

  modalitySegment(modalities) {
    let modalitiesArr = [];

    modalities.forEach(modality => {
      let modalitiesObj = {
        codigo: modality.codigo,
        label: '',
        asset: '',
        status: modality.status,
      }

      switch (modality.codigo) {
        case 'DLV':
          modalitiesObj.label = 'Delivery';
          modalitiesObj.asset = '/assets/moto.svg';
          break;
        case 'TTA':
          modalitiesObj.label = 'Retirar';
          modalitiesObj.asset = '/assets/hot-food.svg';
          break;
        case 'TGO':
          modalitiesObj.label = 'Local';
          modalitiesObj.asset = '/assets/main.svg';
          break;
        default:
          modalitiesObj.label = '';
          modalitiesObj.asset = '';
          break;
      }

      modalitiesArr.push(modalitiesObj);
      return modalitiesArr;
    });

    this.modalidades = modalitiesArr;
  }


  voltar() {
    this.utils.voltarPagina('tabs/home');
  }

  ionViewWillLeave() {
    clearTimeout(this.interval);

  }

  trocarModalidade(event) {
    this.abrirDetalhes(this.modalidade_codigo[this.utils.getStorage('modalidade').codigo])
    
    this.utils.setStorage('modalidade', { "codigo": event.target.value });
    this.modalidadeAtiva=event.target.value
    this.events.publish('atualizar-cardapio', event.target.value)

    this.abrirDetalhes(this.modalidade_codigo[this.utils.getStorage('modalidade').codigo])

  }

  abrirDetalhes(tipo: string) {
    if(this.loja[tipo] == true)
      this.loja[tipo] = false
    else
      this.loja[tipo] = true      
  }

  mudarLoja() {
    this.modalService.modalMudarLojaWeb().then((res) =>{
     if (res.data.close) {
      this.events.publish('atualizar-cardapio', this.utils.getStorage('modalidade').codigo);

      this.fechar()
     }
      
    });
    
  }

  async fechar() {
    this.modalController.dismiss(this.modalidadeAtiva)
    await this.modalController.dismiss();
  }

}
