import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-confirmacao-web',
  templateUrl: './modal-confirmacao-web.page.html',
  styleUrls: ['./modal-confirmacao-web.page.scss'],
})
export class ModalConfirmacaoWebPage implements OnInit {

  @Input() titulo: string;
  @Input() texto: string;
  @Input() pagina: string;
  @Input() elemento: string;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  irPara() {
    this.modalController.dismiss(true);
  }

  fechar() {
    this.modalController.dismiss('other');
  }

  cancelar(){
    this.modalController.dismiss(false);
  }
  
}
