import { Component, OnInit } from '@angular/core';
import { ILocalizacao } from 'src/app/interfaces/IEndereco.model';

import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-localizacao-atual',
  templateUrl: './localizacao-atual.component.html',
  styleUrls: ['./localizacao-atual.component.scss'],
})
export class LocalizacaoAtualComponent implements OnInit {
  public localizacao_atual: any | null = null;
  public texto_completo: string = '';

  public carregando: boolean = true;

  constructor(
    private apiService: ApiService,
    private modalsService: ModalsService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.localizacaoAtual();
  }

  // private localizacaoAtual(): any {
  //   this.utilsService.getLocationService().then(async (resp) => {
  //     let coordenadas: { lat: number; lng: number } = resp;

  //     const req = {
  //       lat: coordenadas.lat,
  //       lng: coordenadas.lng,
  //     };

  //     const res = await this.apiService.get('geocode', req);

  //     this.carregando = false;

  //     if (res.status === 'success') {
  //       this.localizacao_atual = res.data;

  //       if (this.localizacao_atual?.logradouro) {
  //         this.texto_completo =
  //           this.localizacao_atual?.logradouro +
  //           ', ' +
  //           this.localizacao_atual?.numero;
  //       } else {
  //         this.texto_completo =
  //           this.localizacao_atual?.bairro +
  //           ', ' +
  //           this.localizacao_atual?.cidade;
  //       }

  //       if (
  //         this.localizacao_atual.cep == undefined ||
  //         this.localizacao_atual.logradouro == undefined ||
  //         this.localizacao_atual.bairro == undefined
  //       ) {
  //         await this.getOpenStreetMap(req.lat, req.lng);
  //       }

  //       if (this.localizacao_atual.lat !== null) {
  //         const localizacao = this.changeCoordinates(this.localizacao_atual);
  //         this.utilsService.setStorage('localizacao-atual', localizacao);
  //       } else {
  //         this.localizacao_atual = null;
  //       }
  //     } else {
  //       this.utilsService.exibirToast(
  //         'Ops.. Tivemos um problema em pegar sua localiza��o, por favor tente novamente.',
  //         'erro'
  //       );

  //       this.localizacao_atual = null;
  //     }
  //   });

  //   return this.localizacao_atual;
  // }

  private localizacaoAtual(): ILocalizacao {
    this.utilsService.getLocationService().then(async (resp) => {
      let coordenadas: { lat: number; lng: number } = resp;

      const req = {
        lat: coordenadas.lat,
        lng: coordenadas.lng,
      };

      const res = await this.apiService.get('geocode', req);

      this.carregando = false;

      if (res.status === 'success') {
        this.localizacao_atual = res.data;

        if (this.localizacao_atual?.logradouro) {
          this.texto_completo = this.localizacao_atual?.logradouro + ', ' + this.localizacao_atual?.numero
        } else {
          this.texto_completo = this.localizacao_atual?.bairro + ', ' + this.localizacao_atual?.cidade
        }

        if (this.localizacao_atual.estado == 'DF') {
          if (this.localizacao_atual.cep == undefined || this.localizacao_atual.logradouro == undefined || this.localizacao_atual.bairro == undefined) {
            await this.getOpenStreetMap(req.lat, req.lng);
          }
        }

        if (this.localizacao_atual.lat !== null) {
          const localizacao = this.changeCoordinates(this.localizacao_atual);
          this.utilsService.setStorage('localizacao-atual', localizacao);
        } else {
          this.localizacao_atual = null;
        }

      } else {
        this.utilsService.exibirToast(
          'Ops.. Tivemos um problema em pegar sua localiza��o, por favor tente novamente.', 'erro'
        );

        this.localizacao_atual = null;
      }
    });

    return this.localizacao_atual;
  }

  public async getOpenStreetMap(lat: number, lng: number) {
    const reqLatLng = {
      lat,
      lng,
    }

    const verificacaoOpenStreetMap = await this.apiService.get('openstreetmap', reqLatLng);

    if (verificacaoOpenStreetMap.status === 'success') {

      if (this.localizacao_atual.logradouro == undefined) {
        this.localizacao_atual.logradouro = verificacaoOpenStreetMap.data?.logradouro;
      }

      if (this.localizacao_atual.cep == undefined) {
        this.localizacao_atual.cep = verificacaoOpenStreetMap.data?.cep;
      }

      if (this.localizacao_atual.bairro == undefined) {
        this.localizacao_atual.bairro = verificacaoOpenStreetMap.data?.bairro;
      }

      if (this.localizacao_atual.cidade == undefined) {
        this.localizacao_atual.cidade = verificacaoOpenStreetMap.data?.cidade;
      }

      if (this.localizacao_atual.estado == undefined) {
        this.localizacao_atual.estado = verificacaoOpenStreetMap.data?.estado;
      }

    }

  }

  // private async getOpenStreetMap(lat: number, lng: number): Promise<void> {
  //   const reqLatLng = {
  //     lat,
  //     lng,
  //   };

  //   const verificacaoOpenStreetMap = await this.apiService.get(
  //     'openstreetmap',
  //     reqLatLng
  //   );

  //   if (verificacaoOpenStreetMap.status === 'success') {
  //     if (this.localizacao_atual.logradouro == undefined) {
  //       this.localizacao_atual.logradouro =
  //         verificacaoOpenStreetMap.data?.logradouro;
  //     }

  //     if (this.localizacao_atual.cep == undefined) {
  //       this.localizacao_atual.cep = verificacaoOpenStreetMap.data?.cep;
  //     }

  //     if (this.localizacao_atual.bairro == undefined) {
  //       this.localizacao_atual.bairro = verificacaoOpenStreetMap.data?.bairro;
  //     }

  //     if (this.localizacao_atual.cidade == undefined) {
  //       this.localizacao_atual.cidade = verificacaoOpenStreetMap.data?.cidade;
  //     }

  //     if (this.localizacao_atual.estado == undefined) {
  //       this.localizacao_atual.estado = verificacaoOpenStreetMap.data?.estado;
  //     }
  //   }
  // }

  public async navegar(caminho: string) {
    const modal = await this.modalsService.existeModal();
    if (modal) {
      this.modalsService.fecharModal();
    }

    if (caminho === 'mapa-confirmacao') {
      const localizacao = this.changeCoordinates(this.localizacao_atual);

      this.utilsService.setStorage('localizacao-atual', localizacao);
    }

    this.utilsService.navegarPagina(caminho);
  }

  public changeCoordinates(localizacao: any): any {
    const objCoordenadas: any = {
      ...localizacao,
      latitude: localizacao.lat,
      longitude: localizacao.lng,
    };

    delete objCoordenadas.lat;
    delete objCoordenadas.lng;

    return objCoordenadas;
  }
}
