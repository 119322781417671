import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-politicas-web',
  templateUrl: './termos-politicas-web.component.html',
  styleUrls: ['./termos-politicas-web.component.scss'],
})
export class TermosPoliticasWebComponent implements OnInit {

  public termos: Boolean = true;
  public politica: Boolean = false;
  public textoCompleto: Boolean = false;

  constructor() { }

  ngOnInit() {
  }

  trocarStatus(ev: any) {
    if (ev.detail.value === "termos") {
      this.termos = true;
      this.politica = false;
    } else {
      this.politica = true;
      this.termos = false;
    }
  }

  verTexto() {
    this.textoCompleto = !this.textoCompleto;
  }

}
