import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Endereco } from 'src/app/interfaces/endereco';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-confirma-modalidade',
  templateUrl: './confirma-modalidade.page.html',
  styleUrls: ['./confirma-modalidade.page.scss'],
})
export class ConfirmaModalidadePage implements OnInit {

  public endereco: Endereco;
  public loja: any;
  public modalidade: any
  public carrinho: any;
  public desconto: any;
  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private platform: Platform,
    private modalController: ModalController,
  ) {
    this.endereco = this.utils.getStorage('enderecos')[0];
    this.modalidade = this.utils.getStorage('modalidade');
    this.loja = this.utils.getStorage('loja');
  }

  async ngOnInit() {

    await this.platform.ready();

    if (this.platform.is('ios') || this.platform.is('iphone')) {
      this.utils.alteraStatusBar('escuro');
    }

  }

  voltar() {
    this.modalController.dismiss();
  }

  async trocarModalidade() {
    this.modalService.modalSelecionaModalidade()
  }

  async confirmar() {
    this.modalController.dismiss();

    this.utils.setStorage('confirma-modalidade', true);
    this.utils.setStorage('desconto', this.desconto);
    this.utils.setStorage('carrinho', this.carrinho);
    this.utils.setStorage('endereco', this.endereco);
    
    if (this.utils.getStorage('sugestoes').length != 0) {
      this.modalService.modalSugestoes().then().then((res) => {

        if (res.role === 'sugestao') {

          this.modalService.modalProduto().then(() => {
            this.utils.alteraStatusBar('claro');
          });

        }
      })
    } else {
      this.modalController.dismiss();
  
      if (this.utils.getStorage('edicao-restaurante') == 'sim') {
        this.utils.navegarPagina('tabs/home');
        this.utils.removeStorage('edicao-restaurante');
      }

      if (this.utils.getStorage('sucesso_cpf') == true) {
        this.utils.alteraStatusBar('claro');
          await this.utils.exibirToast(
            'Para fazer um pedido é necessário cadastrar o CPF',
            'erro'
          );
          this.utils.navegarPagina('dados-pessoais');
      }
      
      this.utils.navegarPagina('finalizar-pedido');
    }
  }

}
