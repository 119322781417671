import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { ISegmentButton } from 'src/app/componentes/segment/segment.component';

export interface IModalidade {
  codigo: string;
  modalidade?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ModalidadeService {
  private modalidades: IModalidade[] = [
    { codigo: 'TTA', modalidade: 'Retirar' },
    { codigo: 'TGO', modalidade: 'Local' },
  ];
  private modalidadeSubject = new BehaviorSubject<IModalidade | null>(null);
  public modalidade$: Observable<IModalidade | null> =
    this.modalidadeSubject.asObservable();

  public semModalidadeSubject = new BehaviorSubject<boolean | null>(false);
  public semModalidade$: Observable<boolean | null> =
    this.semModalidadeSubject.asObservable();

  private modalidadesDisponiveisSubject =
    new BehaviorSubject<ISegmentButton | null>(null);
  public modalidadesDisponiveis$: Observable<ISegmentButton | null> =
    this.modalidadesDisponiveisSubject.asObservable();

  constructor(private utilsService: UtilsService) {
    const modalidade: IModalidade = this.getModalidade();

    this.modalidadeSubject.next(modalidade);
  }

  public atualizarModalidade(codigo: string): IModalidade {
    let objModalidade: IModalidade = this.buscarModalidade(codigo);

    this.setModalidade(objModalidade);

    return objModalidade;
  }

  public modalidadesDisponiveis(loja: any): ISegmentButton {
    const modalidadeSelecionada = this.getModalidade();

    let modalidadesDisponiveis: ISegmentButton | null = null;

    if (loja) {
      modalidadesDisponiveis = {
        valor_padrao: modalidadeSelecionada.codigo,
        opcoes: [],
      };

      loja.modalidades.map((modalidade: any) => {
        if (modalidade.status) {
          const objModalidade = this.buscarModalidade(modalidade.codigo);

          return modalidadesDisponiveis.opcoes.push({
            opcao: objModalidade.modalidade,
            valor: objModalidade.codigo,
          });
        }
      });
    }

    this.modalidadesDisponiveisSubject.next(modalidadesDisponiveis);
    this.semModalidadeSubject.next(false);
    return modalidadesDisponiveis;
  }

  private buscarModalidade(codigo: string): IModalidade {
    return (
      this.modalidades.find((modalidade) => modalidade.codigo === codigo) || {
        codigo: 'DLV',
        modalidade: 'Delivery',
      }
    );
  }

  public getModalidade(): IModalidade {
    const modalidade = this.utilsService.getStorage('modalidade');
    const modalidadeSelecionada =
      modalidade !== null
        ? this.buscarModalidade(modalidade.codigo)
        : this.buscarModalidade('DLV');

    return modalidadeSelecionada;
  }

  private setModalidade(objModalidade: IModalidade): void {
    this.utilsService.setStorage('modalidade', objModalidade);
    this.modalidadeSubject.next(objModalidade);
  }
}
