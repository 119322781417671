import { IonSlides, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Redes } from 'src/app/interfaces/Redes';
import { EventsService } from 'src/app/services/events.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { CelularService } from 'src/app/services/celular/celular.service';

@Component({
  selector: 'app-programa-fidelidade',
  templateUrl: './programa-fidelidade.page.html',
  styleUrls: ['./programa-fidelidade.page.scss'],
})
export class ProgramaFidelidadePage implements OnInit {
  public loading: boolean = true;
  public redes: Redes[];
  public usuario: any = this.utilsService.getStorage('usuario');
  public meses: string = '1';
  public historico: any;
  public idMarca: number;
  public marcaSelecionada;
  public extratoDetalhado: boolean = false;
  public celular: boolean;
  public tipos_movimentos: any = {
    A: {
      texto: 'CASHBACK DE ANIVERSÁRIO',
      color: 'blue_color',
      border: 'blue_border',
    },
    B: { texto: 'BONIFICAÇÃO', color: 'blue_color', border: 'blue_border' },
    D: { texto: 'DEVOLUÇÃO', color: 'blue_color', border: 'blue_border' },
    E: { texto: 'EXTORNO', color: 'blue_color', border: 'blue_border' },
    P: {
      texto: 'REVISÃO DE CASHBACK',
      color: 'blue_color',
      border: 'blue_border',
    },
    R: {
      texto: 'RESGATE DE CASHBACK',
      color: 'red_color',
      border: 'red_border',
    },
    V: { texto: 'COMPRA', color: 'blue_color', border: 'blue_border' },
    X: {
      texto: 'EXPIRAÇÃO DE CASHBACK',
      color: 'red_color',
      border: 'red_border',
    },
    S: { texto: 'SUBIU DE NÍVEL', color: 'blue_color', border: 'blue_border' },
    C: { texto: 'CAIU DE NÍVEL', color: 'red_color', border: 'red_border' },
    M: {
      texto: 'SE TORNOU MEMBRO',
      color: 'blue_color',
      border: 'blue_border',
    },
    K: { texto: 'MANTEVE O NÍVEL', color: 'blue_color', border: 'blue_border' },
    I: {
      texto: 'IMPORTAÇÃO DE CASHBACK',
      color: 'blue_color',
      border: 'blue_border',
    },
    J: { texto: 'RECEBEU CASHBACK', color: 'blue_color', border: 'blue_border' },
  };

  @ViewChild(IonSlides) slides: IonSlides;
  slideOpts = {
    initialSlide: 0,
    slidesPerView: 9,
    centeredSlides: false,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };
  constructor(
    private utilsService: UtilsService,
    private api: ApiService,
    private events: EventsService,
    private usuarioService: UsuarioService,
    private modalController: ModalController,
    private celularService: CelularService,
  ) {
    this.celularService.celular$.subscribe(
      (celular) => (this.celular = celular)
    );
  }

  async ngOnInit() {
    this.buscarFidelidade();
  }

  async buscarFidelidade() {
    const res = await this.api.get('fidelidade', {
      jwt: this.utilsService.getStorage('usuario').jwt,
    });
    this.loading = false;
    if (res.status == 'success') {
      this.redes = res.data;
      this.redes.sort(
        (a, b) => b.valor_cashback_saldo - a.valor_cashback_saldo
      );
      this.verExtrato(this.redes[0]);
    }
  }

  fechar() {
    this.modalController.dismiss();
  }
  regulamento() {
    this.utilsService.navegarPagina('regulamento');
  }
  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }
  slideTo(index) {
    this.slides.slideTo(index);
  }
  verExtrato(marca) {
    this.utilsService.exibirLoading();
    this.idMarca = marca.id_marca;
    this.marcaSelecionada = marca;
    this.buscarExtrato();
    this.extratoDetalhado = true;

    // this.extratoDetalhado = !this.extratoDetalhado;
    // this.utils.navegarPagina('extrato/' + id);
  }

  trocarMeses(event) {
    this.meses = event.target.value;

    this.loading = true;

    this.buscarExtrato();
  }

  async buscarExtrato() {
    const res = await this.api.get('fidelidade_extrato', {
      jwt: this.utilsService.getStorage('usuario').jwt,
      id_marca: this.idMarca,
      meses: this.meses,
    });
    this.loading = false;
    if (res.status == 'success') {
      this.historico = res.data;
    } else {
      this.historico = null;
    }
  }
}
