import { Injectable, NgZone } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import {
  GoogleMap,
  Environment,
  GoogleMapOptions,
  GoogleMaps,
  MyLocation,
  GoogleMapsAnimation,
  Marker,
  LocationService,
  GoogleMapsEvent,
  LatLng,
  ILatLng,
  LatLngBounds,
} from '@ionic-native/google-maps/ngx';
import { Platform } from '@ionic/angular';
import { loader } from 'src/environments/environment';
import { Endereco, EnderecoMaps } from '../interfaces/endereco';
import { UtilsService } from './utils.service';
declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  private googleAutocomplete: any;
  private geocoder: any;

  constructor(
    private diagnostic: Diagnostic,
    private platform: Platform,
    private utils: UtilsService,
    private ngZone: NgZone
  ) {
    loader.load().then(async () => {
      this.googleAutocomplete = new google.maps.places.AutocompleteService();
      this.geocoder = new google.maps.Geocoder();
    });
  }

  public isIOS() {
    return this.platform.is('ios');
  }

  public async criarMapa(id: string): Promise<GoogleMap> {
    // Environment.setEnv({
    //   API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyD9AkDWwXaHyOriXbO1KaJgYyYHb-SrpEc',
    //   API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyD9AkDWwXaHyOriXbO1KaJgYyYHb-SrpEc',
    // });

    const mapOptions: GoogleMapOptions = {
      controls: {
        zoom: false,
      },
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d6e2e6',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#cfd4d5',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#7492a8',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [
            {
              lightness: 25,
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dde2e3',
            },
          ],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#cfd4d5',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dde2e3',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#7492a8',
            },
          ],
        },
        {
          featureType: 'landscape.natural.terrain',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dde2e3',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#588ca4',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a9de83',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#bae6a1',
            },
          ],
        },
        {
          featureType: 'poi.sports_complex',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c6e8b3',
            },
          ],
        },
        {
          featureType: 'poi.sports_complex',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#bae6a1',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              saturation: -45,
            },
            {
              lightness: 10,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#41626b',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c1d1d6',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#a6b5bb',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#9fb6bd',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#b4cbd4',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#588ca4',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#008cb5',
            },
          ],
        },
        {
          featureType: 'transit.station.airport',
          elementType: 'geometry.fill',
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: -5,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a6cbe3',
            },
          ],
        },
      ],
    };

    const mapa = GoogleMaps.create(id, mapOptions);

    return mapa;
  }

  async getLocalizacao(): Promise<MyLocation> {
    return await LocationService.getMyLocation();
  }

  public verificaLocalizacao(): Promise<any> {
    const promise = new Promise(async (resolve) => {
      if (this.isIOS()) {
        if (await this.diagnostic.isLocationAuthorized()) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        if (
          (await this.diagnostic.isLocationAuthorized()) &&
          (await this.diagnostic.isGpsLocationAvailable())
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });

    return promise;
  }

  public async addMarker(
    mapa: GoogleMap,
    localizacao: LatLng | { lat: number; lng: number },
    icone: string
  ): Promise<Marker> {
    let marker = mapa.addMarkerSync({
      animation: GoogleMapsAnimation.DROP,
      position: localizacao,
      icon: {
        url: `./assets/${icone}.png`,
        size: {
          width: 40,
          height: 40,
        },
      },
    });

    return marker;
  }

  public async autoComplete(
    busca: string,
    localizacao: MyLocation = null
  ): Promise<Array<EnderecoMaps>> {
    let req = {};

    if (!localizacao) {
      req = {
        input: busca,
        componentRestrictions: {
          country: 'BR',
        },
      };
    } else {
      const bounds: LatLngBounds = new LatLngBounds([localizacao.latLng]);
      const defaultBounds = new google.maps.LatLngBounds(
        bounds.southwest,
        bounds.northeast
      );

      req = {
        bounds: defaultBounds,
        input: busca,
        componentRestrictions: {
          country: 'BR',
        },
      };
    }

    let enderecos: Array<EnderecoMaps> = [];

    await this.googleAutocomplete.getPlacePredictions(
      req,
      (predictions: any) => {
        this.ngZone.run(() => {
          if (predictions != null) {
            predictions.forEach((element: any) => {
              enderecos.push({
                texto_principal: element.structured_formatting.main_text,
                texto_secundario: element.structured_formatting.secondary_text,
              });
            });
          }
        });
      }
    );

    return enderecos;
  }

  public async selecionarEndereco(endereco: string) {
    let infoeEndereco: Endereco = {};

    await this.geocoder.geocode({ address: endereco }, (results: any) => {
      results[0].address_components.forEach((component: any) => {
        if (component.types.includes('street_number')) {
          infoeEndereco.numero = component.long_name;
        }
        if (component.types.includes('route')) {
          infoeEndereco.logradouro = component.long_name;
        }
        if (component.types.includes('sublocality')) {
          infoeEndereco.bairro = component.long_name;
        }
        if (component.types.includes('administrative_area_level_2')) {
          infoeEndereco.cidade = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          infoeEndereco.estado = component.short_name;
        }
        if (component.types.includes('postal_code')) {
          infoeEndereco.cep = component.long_name;
        }
      });

      infoeEndereco.lat = results[0].geometry.location.lat();
      infoeEndereco.lng = results[0].geometry.location.lng();
    });

    return infoeEndereco;
  }

  public async getEndereco(latLng: LatLng | ILatLng) {
    let infoeEndereco: Endereco = {};

    try {
      await this.geocoder.geocode({ location: latLng }, (results: any) => {
        if (results != null) {
          results[0].address_components.forEach((component: any) => {
            if (component.types.includes('street_number')) {
              infoeEndereco.numero = component.long_name;
            }
            if (component.types.includes('route')) {
              infoeEndereco.logradouro = component.long_name;
            }
            if (component.types.includes('sublocality')) {
              infoeEndereco.bairro = component.long_name;
            }
            if (component.types.includes('administrative_area_level_2')) {
              infoeEndereco.cidade = component.long_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
              infoeEndereco.estado = component.short_name;
            }
            if (component.types.includes('postal_code')) {
              infoeEndereco.cep = component.long_name;
            }
          });

          infoeEndereco.lat = results[0].geometry.location.lat();
          infoeEndereco.lng = results[0].geometry.location.lng();
        }
      });
    } catch (error) {}

    return infoeEndereco;
  }

  public async carregarMapa(
    coordenadas: google.maps.LatLngLiteral,
    elemento: HTMLElement,
    marker: boolean = false,
    icone: string = '',
    titulo: string = '',
    estilos: google.maps.MapTypeStyle[] = [],
    zoom: number = 14,
    markers: {
      title: string;
      coordenadas: {
        lat: number;
        lng: number;
      };
    }[] = [],
    infoWindow: boolean = false
  ): Promise<google.maps.Map> {
    let mapa: google.maps.Map;

    loader.load().then(async () => {
      const { Map } = (await google.maps.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary;

      mapa = new Map(elemento as HTMLElement, {
        center: { lat: coordenadas.lat, lng: coordenadas.lng },
        zoom: zoom,
        panControl: false,
        disableDefaultUI: true,
        styles: estilos,
      });

      const limites = new google.maps.LatLngBounds();

      if (marker) {
        if (markers.length !== 0) {
          const coordenadas = markers;

          coordenadas.map((coordenada) => {
            const marker = this.setMarker(
              mapa,
              coordenada.coordenadas,
              icone,
              titulo
            );

            if (infoWindow) {
              let infoWindowBox = `<div id="google-popup"><span>${coordenada.title}</span></div>`;

              this.setInfoWindow(
                infoWindowBox,
                coordenada.coordenadas,
                marker,
                mapa
              );
            }

            limites.extend(coordenada.coordenadas);
          });

          mapa.setCenter(limites.getSouthWest());
          mapa.fitBounds(limites, 190);
        } else {
          this.setMarker(mapa, coordenadas, icone, titulo);

          if (infoWindow) {
            let infoWindowBox = `<div id="google-popup"><span>${titulo}</span></div>`;

            this.setInfoWindow(infoWindowBox, coordenadas, marker, mapa);
          }
        }
      }
    });

    return mapa;
  }

  public async setMarker(
    mapa: google.maps.Map,
    posicao: google.maps.LatLngLiteral,
    icone: string = '',
    titulo: string = ''
  ): Promise<google.maps.Marker> {
    const marcador = new google.maps.Marker({
      icon: icone,
      map: mapa,
      position: posicao,
      title: titulo,
    });

    return marcador;
  }

  public setInfoWindow(
    infoWindowBox: string,
    coordenadas: {
      lat: number;
      lng: number;
    },
    marker: any,
    mapa: any
  ) {
    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowBox,
      position: coordenadas,
      pixelOffset: new google.maps.Size(0, -15)
    });25

    infoWindow.open({
      anchor: marker,
      map: mapa,
    });
  }
}
