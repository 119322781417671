import { UtilsService } from './../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-browser-sugestoes',
  templateUrl: './browser-sugestoes.page.html',
  styleUrls: ['./browser-sugestoes.page.scss'],
})
export class BrowserSugestoesPage implements OnInit {
  public sugestoes = this.utils.getStorage('sugestoes-loja')
  public modalidade: any
  public sacola: boolean = false;
  public totalSacola: any = {};
  public qtdCarrinho: number = 0;
  public corBadge: string;

  constructor(
    private modalService: ModalsService,
    public modalController: ModalController,
    public utils: UtilsService,
  ) { }

  ngOnInit() {
  }

  fechar() {
    this.modalController.dismiss();
  }


  carregarCarrinho() {

    var root = document.documentElement;
    this.corBadge = this.utils.getStorage('loja_carrinho') ? this.utils.getStorage('loja_carrinho').paletaCor : '';
    root.style.setProperty('--corBadge', this.corBadge);

    this.utils.removeStorage('tipo-compra');
    this.utils.removeStorage('periodo-agendado');
    this.utils.removeStorage('horario-agendado');

    this.totalSacola = {
      valor: 0,
      texto: 'R$ 0,00'
    };

    if (this.utils.getStorage('carrinho') != null) {

      const carrinho = this.utils.getStorage('carrinho');

      carrinho.forEach((item: any) => {
        item.preco.unitario.valor = item.preco.total.valor;
        this.totalSacola.valor += item.preco.total.valor;
        this.totalSacola.texto = this.utils.floatToReal(this.totalSacola.valor);
      });

      this.sacola = true;
      this.qtdCarrinho = carrinho.reduce((total: number, item: any) => total + item.qtd, 0);

    } else {
      this.sacola = false;
    }

  }


  seguir(){
    this.modalController.dismiss();
    this.utils.navegarPagina('tabs/home');
    this.modalService.modalCarrinhoBrowser().then().then((res) => {
      this.carregarCarrinho();

    });
    this.utils.exibirToast('Produto adicionado com sucesso!');
  }
}
