import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PerfilService } from 'src/app/pages/usuario/perfil/perfil.service';

@Injectable({
  providedIn: 'root',
})
export class RedesService {
  private redeIdSource = new BehaviorSubject<number>(null);
  public redeId$ = this.redeIdSource.asObservable();

  constructor(private perfilService: PerfilService) {}

  public setRedeId(redeId: number): void {
    this.redeIdSource.next(redeId);
    this.perfilService.buscarSaldo(redeId);
  }
}
