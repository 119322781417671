import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-simples-web',
  templateUrl: './simples-web.page.html',
  styleUrls: ['./simples-web.page.scss'],
})
export class SimplesWebPage implements OnInit {

  @Input() titulo: string;
  @Input() texto: string;
  @Input() pagina: string;
  @Input() elemento: string;

  constructor(
    private utils: UtilsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  irPara() {
    this.modalController.dismiss('sucesso');
  }

  fechar() {
    this.modalController.dismiss();
  }

}
