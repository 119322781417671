import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { NovoChamadoPage } from 'src/app/modals/novo-chamado/novo-chamado.page';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-fale-conosco-web',
  templateUrl: './fale-conosco-web.component.html',
  styleUrls: ['./fale-conosco-web.component.scss'],
})
export class FaleConoscoWebComponent implements OnInit {

  public load: boolean = false;
  public faleconosco: Array<any> = [];
  public chamado: any = {};
  public abrircontato: boolean = false;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private router: Router,
    private inAppBrowser: InAppBrowser,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buscarChamados();
  }
  ionViewWillEnter() {
    this.buscarChamados();
  }

  async buscarChamados() {
    await this.utils.exibirLoading();

    const res = await this.api.get('fale_conosco');
    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      this.load = false;
      if (res.data != null) {
        this.faleconosco = res.data;
      }
    }
  }

  abrirContato(id) {
    this.abrircontato = true;
    this.buscarChamado(id);
  }

  voltarContato() {
    this.abrircontato = false;
  }

  async novoChamado() {
    const modal = await this.modalController.create({
      component: NovoChamadoPage,
      cssClass: 'modal-novo-chamdo'
    });
    await modal.present();
    return await modal.onDidDismiss().then(data => {
      this.buscarChamados();
    });
  }

  async buscarChamado(id){
    let req ={
      id
    }
    const res = await this.api.get('fale_conosco', req);

    if (res.status === "success") {
      this.chamado = res.data[0]
      console.log('teste', this.chamado);
      
    }
  }

  facebook() {
    const browser = this.inAppBrowser.create('https://pt-br.facebook.com/grupotrigooficial/', '_system');
    browser.show();
  }

  instagram() {
    const browser = this.inAppBrowser.create('https://instagram.com/fomedeqapp?igshid=MzRlODBiNWFlZA==', '_system');
    browser.show();
  }

  linkedin() {
    const browser = this.inAppBrowser.create('https://www.linkedin.com/company/grupotrigo/', '_system');
    browser.show();
  }
}