import { Component, Input, OnInit } from '@angular/core';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { IBanner } from 'src/app/interfaces/IBanner.model';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit {
  @Input() banners: Array<IBanner>;
  public bannersOpt = {
    autoplay: true,
    initialSlide: 0,
    navigation: true,
  };

  constructor(private analyticsService: AnalyticsService, private iab: InAppBrowser) {}

  ngOnInit() {}

  public clickBanner(banner: IBanner): void {
    const event = this.analyticsService.select_promotion(banner);
    this.analyticsService.registrarEvento(event);

    if (banner.link != null && banner.link != '') {
      this.iab.create(banner.link, '_system').show();
    }
  }
}
