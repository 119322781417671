import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.scss'],
})
export class CardProdutoComponent implements OnInit {
  @Input() produto: any;
  @Input() loja: any;
  @Input() isHome: boolean = false;

  public preco: string;
  public preco_apartir: string;
  constructor() { }

  ngOnInit() {
    this.verifyPrice();
  }

  ionViewWillEnter() {
    this.verifyPrice();
  }

  verifyPrice() {
    typeof this.produto.preco === 'object'
      ? (this.preco = this.produto.preco.texto)
      : (this.preco = this.produto.preco);

    typeof this.produto.preco_apartir === 'object'
      ? (this.preco_apartir = this.produto.preco_apartir.texto)
      : (this.preco_apartir = this.produto.preco_apartir);
  }
}
