import { Component, OnInit } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-editar-produto',
  templateUrl: './editar-produto.page.html',
  styleUrls: ['./editar-produto.page.scss'],
})
export class EditarProdutoPage implements OnInit {

  public index: number;
  public produto: any;
  public cor: string;
  public loja: any;
  public isNatura: boolean;

  constructor(
    private modalService: ModalsService,
    private utils: UtilsService,
  ) {

    var root = document.documentElement;
    this.cor = this.utils.getStorage('cor');
    root.style.setProperty('--cor', this.cor);

    this.loja = this.utils.getStorage('loja');

    this.index = this.utils.getStorage('carrinho-index');
    this.produto = this.utils.getStorage('carrinho')[this.index];
    this.isNatura = [2, 3, 4, 5, 9, 11, 14, 15, 16, 17, 19, 20, 23].includes(this.produto.categoriaId);

  }

  ngOnInit() {
    this.utils.alteraStatusBar('escuro');
  }

  voltar() {
    this.modalService.fecharModal();
  }

  alteraProduto(tipo: string) {

    if (tipo === 'add') {

      this.produto.qtd++;

    } else {

      if (this.produto.qtd === 1) {
        return;
      }

      this.produto.qtd--;

    }

    let totalAdicionais = 0;

    this.produto.adicionais.forEach((item: any) => {
      totalAdicionais += item.qtd * item.preco.unitario.valor;
    });

    this.produto.preco.total.valor = this.produto.qtd * this.produto.preco.unitario.valor + (this.produto.qtd * totalAdicionais);
    this.produto.preco.total.texto = this.utils.floatToReal(this.produto.preco.total.valor);

  }

  alteraAdicional(tipo: string, i: number) {

    if (tipo === 'add') {

      if (this.produto.adicionais[i].qtd === 1) {
        return;
      }

      this.produto.adicionais[i].qtd++;

    } else {

      if (this.produto.adicionais[i].qtd === 0) {
        return;
      }

      this.produto.adicionais[i].qtd--;

    }

    this.produto.adicionais[i].preco.total.valor = this.produto.adicionais[i].preco.unitario.valor * this.produto.adicionais[i].qtd;
    this.produto.adicionais[i].preco.total.texto = this.utils.floatToReal(this.produto.adicionais[i].preco.total.valor);

    let totalAdicionais = 0;

    this.produto.adicionais.forEach((item: any) => {
      totalAdicionais += item.qtd * item.preco.unitario.valor;
    });

    this.produto.preco.total.valor = this.produto.qtd * this.produto.preco.unitario.valor + (this.produto.qtd * totalAdicionais);
    this.produto.preco.total.texto = this.utils.floatToReal(this.produto.preco.total.valor);

  }

  filtroOpcionais(opcionais: Array<any>) {
    return opcionais.filter((item: any) => !item.obrigatorio);
  }

  filtroObrigatorios(opcionais: Array<any>) {
    return opcionais.filter((item: any) => item.obrigatorio);
  }

  async atualizarProduto() {

    let carrinho = this.utils.getStorage('carrinho');
    carrinho[this.index] = this.produto;

    this.utils.setStorage('carrinho', carrinho);

    await this.modalService.fecharModal();

  }
}
