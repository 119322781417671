import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
@Component({
  selector: 'app-cartoes-cadastrados',
  templateUrl: './cartoes-cadastrados.page.html',
  styleUrls: ['./cartoes-cadastrados.page.scss'],
})
export class CartoesCadastradosPage implements OnInit {

  public cartoes: Array<any> = [];
  public usuario: any;
  public loja: any;

  constructor(
    private utils: UtilsService,
    private events: EventsService,
    private modals: ModalsService
  ) {
    this.usuario = this.utils.getStorage('usuario');
    this.cartoes = this.usuario.cartoes;
  }

  ngOnInit() {
    if (this.utils.getStorage('loja')) {
      this.loja = this.utils.getStorage('loja');
    }
  }

  async abrirCadastroCartao() {
    await this.modals.fecharModal();
    await this.modals.fecharModal();
    if (this.loja.metodo_pagamento == 'braspag') {
      this.modals.modalAdicionarCartao();
    }else{
      this.modals.modalAdicionarCartaoMP();
    }
  }

  async selecionarCartao(index: number) {

    const cartao = this.cartoes[index];
    this.cartoes.splice(index, 1);

    this.cartoes.unshift(cartao);

    this.usuario.cartoes = this.cartoes;
    this.utils.setStorage('usuario', this.usuario);

    this.events.publish('atualizar-cartoes');

    await this.modals.fecharModal();

  }

}
