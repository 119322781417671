import { MobileService } from 'src/app/services/mobile/mobile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-adicionar-pagamento',
  templateUrl: './adicionar-pagamento.page.html',
  styleUrls: ['./adicionar-pagamento.page.scss'],
})
export class AdicionarPagamentoPage implements OnInit {

  public formulario;
  public controladores: Object;
  public loading: boolean = false;
  public dados_cartao;
  public isPhone: boolean = false;
  public tipo;
  public modal_title = 'Adicionar Cartão';
  public mp: any;
  public celular: boolean;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private mobileService: MobileService,
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });
   }


   ngOnInit() {
    this.formulario = this.formBuilder.group({
      numero: [this.dados_cartao.numero, Validators.required],
      titular: [this.dados_cartao.titular, Validators.required],
      cpf: [this.dados_cartao.cpf, Validators.required],
      validade: [this.dados_cartao.validade, Validators.required],
      cvv: [this.dados_cartao.cvv, Validators.required],
    });

    if (this.tipo == 'CREDIT') {
      this.modal_title = 'Adicionar cartão de crédito';
    } else if (this.tipo == 'DEBIT') {
      this.modal_title = 'Adicionar cartão de débito';
    } else if (this.tipo == 'MEAL_VOUCHER') {
      this.modal_title = 'Adicionar vale refeição';
    } else if (this.tipo == 'Editar') {
      this.modal_title = 'Editar Cartão';
    }
  }

  async cadastrar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCPF(this.formulario.get('cpf').value)) {
        this.utils.exibirToast('CPF inválido!', 'erro');
        return;
      }

      if (!this.utils.validaValidade(this.formulario.get('validade').value)) {
        this.utils.exibirToast('Data de validade expirada!', 'erro');
        return;
      }

      const string = this.formulario.get('numero').value;
      const final = string.substr(-4);

      let req = {
        numero: this.formulario.get('numero').value,
        titular: this.formulario.get('titular').value,
        cpf: this.formulario.get('cpf').value,
        validade: this.formulario.get('validade').value,
        cvv: this.formulario.get('cvv').value,
        tipo: this.tipo === 'Editar' ? this.dados_cartao.tipo : this.tipo,
        final: final ? final : '',
      };

      this.modalController.dismiss(req);
    }
  }

  fechar() {
    this.modalController.dismiss();
  }

}
