import { Component, OnInit, Input } from '@angular/core';
import { Endereco } from 'src/app/interfaces/endereco';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { UtilsService } from 'src/app/services/utils.service';

declare let dataLayer: any;
@Component({
  selector: 'app-sem-resultado',
  templateUrl: './sem-resultado.component.html',
  styleUrls: ['./sem-resultado.component.scss'],
})
export class SemResultadoComponent implements OnInit {
  @Input() cardapio;
  @Input() carregando;
  @Input() sem_modalidade;
  @Input() cardapio_filtrado;

  private endereco: Endereco;

  constructor(
    private enderecoService: EnderecoService,
    private utilsService: UtilsService
  ) {
    this.enderecoService.enderecos$.subscribe((enderecos) => {
      if (enderecos !== null && enderecos.length > 0)
        this.endereco = enderecos[0];
      else this.endereco = null;
    });
  }

  ngOnInit() {
    let carregando: boolean;
    // let sem_modalidade: boolean;

    this.carregando.subscribe((charging) => { carregando = charging });
    // this.sem_modalidade.subscribe((no_modality) => {sem_modalidade = no_modality});

    if (this.endereco != null && !this.cardapio && !this.cardapio_filtrado && !this.sem_modalidade && !carregando) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: 'address_not_answered',
        cep: this.endereco.cep,
      });
    }
  }

  public navegar(caminho: string) {
    this.utilsService.navegarPagina(caminho);
  }
}
