import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-confirmar-endereco',
  templateUrl: './modal-confirmar-endereco.page.html',
  styleUrls: ['./modal-confirmar-endereco.page.scss'],
})
export class ModalConfirmarEnderecoPage implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  confirmar() {
    this.modalController.dismiss(true);
  }

  cancelar() {
    this.modalController.dismiss(false);
  }

}
