import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Redes } from 'src/app/interfaces/Redes';
import { Usuario } from 'src/app/interfaces/Usuario';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-programa-fidelidade-web',
  templateUrl: './programa-fidelidade-web.component.html',
  styleUrls: ['./programa-fidelidade-web.component.scss'],
})
export class ProgramaFidelidadeWebComponent implements OnInit {
  public loading: boolean = true;
  public centered: number = 1.5;

  public usuario: Usuario;
  public meses: string = '1';
  public idMarca: number;
  public marcaSelecionada;
  public marcas: Array<Redes>;
  public extratoDetalhado: boolean = false;
  public historico: any = [];

  public tipos_movimentos: any = {
    A: {
      texto: 'CASHBACK DE ANIVERSÁRIO',
      color: 'blue_color',
      border: 'blue_border',
    },
    B: { texto: 'BONIFICAÇÃO', color: 'blue_color', border: 'blue_border' },
    D: { texto: 'DEVOLUÇÃO', color: 'blue_color', border: 'blue_border' },
    E: { texto: 'EXTORNO', color: 'blue_color', border: 'blue_border' },
    P: {
      texto: 'REVISÃO DE CASHBACK',
      color: 'blue_color',
      border: 'blue_border',
    },
    R: {
      texto: 'RESGATE DE CASHBACK',
      color: 'red_color',
      border: 'red_border',
    },
    V: { texto: 'COMPRA', color: 'blue_color', border: 'blue_border' },
    X: {
      texto: 'EXPIRAÇÃO DE CASHBACK',
      color: 'red_color',
      border: 'red_border',
    },
    S: { texto: 'SUBIU DE NÍVEL', color: 'blue_color', border: 'blue_border' },
    C: { texto: 'CAIU DE NÍVEL', color: 'red_color', border: 'red_border' },
    M: {
      texto: 'SE TORNOU MEMBRO',
      color: 'blue_color',
      border: 'blue_border',
    },
    K: { texto: 'MANTEVE O NÍVEL', color: 'blue_color', border: 'blue_border' },
    I: {
      texto: 'IMPORTAÇÃO DE CASHBACK',
      color: 'blue_color',
      border: 'blue_border',
    },
    J: {
      texto: 'RECEBEU CASHBACK',
      color: 'blue_color',
      border: 'blue_border',
    },
  };

  @ViewChild('detalhesFidelidade') detalhesFidelidade: IonSlides;

  slideMarcasOptions = {
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 10,
  };

  slideFidelidadeOptions = {
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    initialSlide: 0,
    slidesPerView: 1.5,
    spaceBetween: 10,
  };

  constructor(
    private api: ApiService,
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.usuario = this.utilsService.getStorage('usuario');
    this.buscarFidelidade();
  }

  async buscarFidelidade() {
    const res = await this.api.get('fidelidade', {
      jwt: this.usuario.jwt,
    });
    this.loading = false;
    if (res.status == 'success') {
      this.marcas = res.data;
      this.marcas.sort((a, b) => b.valor_cashback_saldo - a.valor_cashback_saldo);
    }

    this.slidesPerView(this.marcas);
  }

  slidesPerView(marcas: Array<Redes>) {
    this.slideFidelidadeOptions.slidesPerView = 1.5;
    if (marcas.length == 1) this.slideFidelidadeOptions.slidesPerView = 1;
  }

  async buscarExtrato() {
    this.utilsService.ocultarLoading();
    const res = await this.api.get('fidelidade_extrato', {
      jwt: this.usuario.jwt,
      id_marca: this.idMarca,
      meses: this.meses,
    });
    this.loading = false;
    if (res.status == 'success') {
      this.historico = res.data;
    } else {
      this.historico = null;
    }
  }

  abrirRegulamento() {
    this.modalsService.modalRegulamentoFidelidade();
  }

  scrollParaMarca(index: number) {
    this.detalhesFidelidade.slideTo(index);
  }

  slidePrev() {
    this.detalhesFidelidade.slidePrev();
  }

  slideNext() {
    this.detalhesFidelidade.slideNext();
  }

  extratoPage(marca: Redes) {
    this.utilsService.exibirLoading();
    this.idMarca = marca.id_marca;
    this.marcaSelecionada = marca;
    this.buscarExtrato();
    this.extratoDetalhado = true;
    // this.extratoDetalhado = !this.extratoDetalhado;
  }

  trocarMeses(event: any) {
    this.meses = event.target.value;
    this.loading = true;
    this.buscarExtrato();
  }

  voltar() {
    this.extratoDetalhado = false;
  }

  onImgError(event) {
    event.target.src = './assets/default.jpeg';
  }
}
