import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CelularService } from './../../services/celular/celular.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-informacoes-loja',
  templateUrl: './informacoes-loja.component.html',
  styleUrls: ['./informacoes-loja.component.scss'],
})
export class InformacoesLojaComponent implements OnInit {
  @Input() loja: any;
  @Input() carregando: any;
  @Output() filtro: EventEmitter<void> = new EventEmitter<void>();
  @Output() filtrar: EventEmitter<void> = new EventEmitter<void>();
  @Output() limpar: EventEmitter<void> = new EventEmitter<void>();

  private celular: boolean;

  constructor(
    private celularService: CelularService,
    private modalsService: ModalsService,
    private utilsService: UtilsService
  ) {
    this.celularService.celular$.subscribe(
      (celular) => (this.celular = celular)
    );
  }

  ngOnInit() {}

  public strFiltro(event: any): void {
    this.filtro.emit(event);
  }

  public filtrarDado(event: any): void {
    this.filtrar.emit(event);
  }

  public limparDado(): void {
    this.limpar.emit();
  }

  public abrirLoja(): void {
    if (!this.celular) {
      this.modalsService.modalDetalhesLojaBrowser(
        this.utilsService.getStorage('modalidade').codigo
      );
    } else {
      this.utilsService.navegarPagina('detalhes-loja');
    }
  }
}
