import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MudarVisibilidadeService {
  private visibilitySource = new Subject<boolean>();

  visibility$ = this.visibilitySource.asObservable();

  changeVisibility(isVisible: boolean) {
    this.visibilitySource.next(isVisible);
  }
}
