import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { Component, OnInit } from '@angular/core';
import { Endereco } from 'src/app/interfaces/endereco';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';

@Component({
  selector: 'app-meus-enderecos-web',
  templateUrl: './meus-enderecos-web.component.html',
  styleUrls: ['./meus-enderecos-web.component.scss'],
})
export class MeusEnderecosWebComponent implements OnInit {
  public enderecos: any[] = [];

  constructor(
    private enderecoService: EnderecoService,
    private modals: ModalsService,
    private utils: UtilsService
  ) {
    this.enderecoService.enderecos$.subscribe(
      (enderecos) => (this.enderecos = enderecos)
    );
  }

  ngOnInit() {}

  public excluirEndereco(endereco: Endereco): void {
    if (endereco.principal == true) {
      this.utils.exibirToast('Você não pode excluir o seu endereço atual!', 'erro');
      return;
    }

    const data = {
      titulo: 'Excluir endereço',
      paragrafos: ['Você tem certeza que quer excluir este endereço?'],
      label_acao: 'Excluir endereço',
      acao: 'excluir-endereco',
    };

    this.modals.abrirModal(
      'modal-generica',
      'modal-generica',
      ModalGenericaPage,
      { data: data },
      (params: any) => {
        if (params.data === 'executar') {
          this.enderecoService.removerEndereco(endereco);
        }
      }
    );
  }

  public navegar(caminho: string, endereco: any = null): void {
    if (endereco !== null) {
      this.utils.setStorage('editar-endereco', endereco);
      this.utils.navegarPagina('/cadastro-endereco');
      return;
    }

    this.utils.navegarPagina(caminho);
  }
}
