import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-card-produto',
  templateUrl: './skeleton-card-produto.component.html',
  styleUrls: ['./skeleton-card-produto.component.scss'],
})
export class SkeletonCardProdutoComponent implements OnInit {
  @Input() isHome: boolean;
  @Input() quantity: number;

  public skeletons: Array<Number>;
  constructor() { }

  ngOnInit() {
    this.skeletons = new Array(this.quantity);
  }

}
