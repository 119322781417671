import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { loader } from 'src/environments/environment.prod';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { LojaService } from 'src/app/services/loja/loja.service';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';
import { MapsService } from 'src/app/services/maps.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { EscolherRestauranteRetiradaPage } from '../escolher-restaurante-retirada/escolher-restaurante-retirada.page';
import { CINZA } from 'src/app/constantes/mapa.style';

declare let google: any;
@Component({
  selector: 'app-confirmar-restaurante-retirada',
  templateUrl: './confirmar-restaurante-retirada.page.html',
  styleUrls: ['./confirmar-restaurante-retirada.page.scss'],
})
export class ConfirmarRestauranteRetiradaPage implements OnInit {
  @ViewChild('mapa') mapaElement: ElementRef;
  private mapa: google.maps.Map;

  private data: {
    data: {
      data: any;
      localizacao: any;
      redeId: number;
    };
  };
  public loja: any;
  private localizacao_usuario: any;
  private modalidade: any;

  constructor(
    private enderecoService: EnderecoService,
    private lojaService: LojaService,
    private mapsService: MapsService,
    private modalidadeService: ModalidadeService,
    private modalsService: ModalsService,
    private utilsService: UtilsService
  ) {
    this.modalidadeService.modalidade$.subscribe(
      (modalidade) => (this.modalidade = modalidade)
    );
  }

  ngOnInit() {
    this.loja = this.data.data.data;
    this.localizacao_usuario = this.data.data.localizacao;

    this.carregarMapa();
  }

  ngOnDestroy() {
    if (this.mapa) this.mapa = null;
  }

  private async carregarMapa() {
    const coordenadas_loja: { lat: number; lng: number } | google.maps.LatLng =
      {
        lat: parseFloat(this.loja.latitude),
        lng: parseFloat(this.loja.longitude),
      };
    const coordenadas_usuario:
      | { lat: number; lng: number }
      | google.maps.LatLng = {
      lat: this.localizacao_usuario.lat,
      lng: this.localizacao_usuario.lng,
    };

    const coordenadas = [
      {
        title: this.loja.nome,
        coordenadas: coordenadas_loja,
      },
      {
        title: 'Você está aqui.',
        coordenadas: coordenadas_usuario,
      },
    ];

    this.mapa = await this.mapsService.carregarMapa(
      coordenadas_loja,
      document.getElementById('mapa'),
      true,
      '/assets/topo/localizacao.svg',
      '',
      CINZA,
      18,
      coordenadas,
      true
    );
  }

  public async navegar(caminho: string): Promise<void> {
    const modal = await this.modalsService.existeModal();
    if (modal) {
      this.modalsService.fecharModal('cancelar');

      this.modalsService
        .abrirModal(
          'modal-retirada',
          'modal-retirada',
          EscolherRestauranteRetiradaPage
        )
        .then((response) => {
          if (response)
            if (response.data === 'cancelar' || response.role == 'backdrop') {
              this.modalidadeService.atualizarModalidade('DLV');
            }
        });

      return;
    }

    this.utilsService.navegarPagina(caminho);
  }

  public confirmar(): void {
    this.enderecoService
      .verificarCarrinho(null, false, this.modalidade.codigo)
      .then((response) => {
        if (response) {
          this.modalidadeService.atualizarModalidade('TTA');

          const coordenadas = {
            lat: parseFloat(this.loja.latitude),
            lng: parseFloat(this.loja.longitude),
          };

          this.lojaService.buscarLoja(coordenadas, 'TTA', this.data.data.redeId);

          this.modalsService.fecharModal();
        }
      });
  }
}
