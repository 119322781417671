import { CelularService } from './../celular/celular.service';
import { SelecionarFormaPagamentoWebPage } from './../../modals/selecionar-forma-pagamento-web/selecionar-forma-pagamento-web.page';
import { ConfirmaCartaoPage } from './../../modals/confirma-cartao/confirma-cartao.page';
import { PoliticaPrivacidadePage } from './../../pages/politica-privacidade/politica-privacidade.page';
import { PoliticaTermosPage } from './../../pages/politica-termos/politica-termos.page';
import { SelecionarFormaPagamentoPage } from './../../modals/selecionar-forma-pagamento/selecionar-forma-pagamento.page';
import { ModalCarrinhoBrowserPage } from './../../modals/modal-carrinho-browser/modal-carrinho-browser.page';
import { BrowserSugestoesPage } from './../../modals/browser-sugestoes/browser-sugestoes.page';
import { BrowserConfirmaModalidadePage } from './../../modals/browser-confirma-modalidade/browser-confirma-modalidade.page';
import { SearchProdutoBrowserPage } from './../../modals/search-produto-browser/search-produto-browser.page';
import { ModalProdutoPage } from './../../modals/modal-produto/modal-produto.page';
import { SelecionarModalidadePage } from './../../modals/selecionar-modalidade/selecionar-modalidade.page';
import { SugestoesPage } from './../../modals/sugestoes/sugestoes.page';
import { MudarLojasPage } from './../../modals/mudar-lojas/mudar-lojas.page';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlterarSenhaPage } from 'src/app/modals/alterar-senha/alterar-senha.page';
import { ClienteImportadoPage } from 'src/app/modals/cliente-importado/cliente-importado.page';
import { ConfirmaModalidadePage } from 'src/app/modals/confirma-modalidade/confirma-modalidade.page';
import { CpfNotaPage } from 'src/app/modals/cpf-nota/cpf-nota.page';
import { DetalhesPedidoPage } from 'src/app/modals/detalhes-pedido/detalhes-pedido.page';
import { EditarProdutoPage } from 'src/app/modals/editar-produto/editar-produto.page';
import { InativarContaPage } from 'src/app/modals/inativar-conta/inativar-conta.page';
import { LimparSacolaModalidadePage } from 'src/app/modals/limpar-sacola-modalidade/limpar-sacola-modalidade.page';
import { LimparSacolaPage } from 'src/app/modals/limpar-sacola/limpar-sacola.page';
import { ProdutoPage } from 'src/app/modals/produto/produto.page';
import { SimplesPage } from 'src/app/modals/simples/simples.page';
import { ExcluirEnderecoPage } from 'src/app/modals/excluir-endereco/excluir-endereco.page';
import { ConfirmarEdicaoRestaurantePage } from 'src/app/modals/confirmar-edicao-restaurante/confirmar-edicao-restaurante.page';
import { TrocarRestaurantePage } from 'src/app/modals/trocar-restaurante/trocar-restaurante.page';
import { ExcluirContaPage } from 'src/app/modals/excluir-conta/excluir-conta.page';
import { FinalizarPedidoPage } from 'src/app/pages/finalizar-pedido/finalizar-pedido.page';
import { AvaliacaoPage } from 'src/app/modals/avaliacao/avaliacao.page';
import { PagamentoPixPage } from 'src/app/modals/pagamento-pix/pagamento-pix.page';
import { AdicionarCartaoPage } from 'src/app/modals/adicionar-cartao/adicionar-cartao.page';
import { CartoesCadastradosPage } from 'src/app/modals/cartoes-cadastrados/cartoes-cadastrados.page';
import { ExcluirCartaoPage } from 'src/app/modals/excluir-cartao/excluir-cartao.page';
import { BuscaRestaurantesPage } from 'src/app/modals/busca-restaurantes/busca-restaurantes.page';
import { NovoChamadoPage } from 'src/app/modals/novo-chamado/novo-chamado.page';
import { InativarContaWebPage } from 'src/app/modals/inativar-conta-web/inativar-conta-web.page';
import { ExcluirContaWebPage } from 'src/app/modals/excluir-conta-web/excluir-conta-web.page';
import { AlterarSenhaWebPage } from 'src/app/modals/alterar-senha-web/alterar-senha-web.page';
import { SimplesWebPage } from 'src/app/modals/simples-web/simples-web.page';
import { ExcluirCartaoWebPage } from 'src/app/modals/excluir-cartao-web/excluir-cartao-web.page';
import { ExcluirEnderecoWebPage } from 'src/app/modals/excluir-endereco-web/excluir-endereco-web.page';
import { AdicionarCartaoBrowserPage } from 'src/app/modals/adicionar-cartao-browser/adicionar-cartao-browser.page';
import { AdicionarCpfBrowserPage } from 'src/app/modals/adicionar-cpf-browser/adicionar-cpf-browser.page';
import { SelecionarModalidadeBrowserPage } from 'src/app/modals/selecionar-modalidade-browser/selecionar-modalidade-browser.page';
import { DetalhesLojaBrowserPage } from 'src/app/modals/detalhes-loja-browser/detalhes-loja-browser.page';
import { CartoesCadastradosBrowserPage } from 'src/app/modals/cartoes-cadastrados-browser/cartoes-cadastrados-browser.page';
import { ModalConfirmacaoWebPage } from 'src/app/modals/modal-confirmacao-web/modal-confirmacao-web.page';
import { FidelidadeRegulamentoPage } from 'src/app/pages/fidelidade-regulamento/fidelidade-regulamento.page';
import { ModalMudarLojasWebPage } from 'src/app/modals/modal-mudar-lojas-web/modal-mudar-lojas-web.page';
import { ConfirmaEdicaoRestauranteWebPage } from 'src/app/modals/confirma-edicao-restaurante-web/confirma-edicao-restaurante-web.page';
import { AdicionarCartaoBrowserMpPage } from 'src/app/modals/adicionar-cartao-browser-mp/adicionar-cartao-browser-mp.page';
import { AdicionarCartaoMpPage } from 'src/app/modals/adicionar-cartao-mp/adicionar-cartao-mp.page';
import { AdicionarPagamentoPage } from 'src/app/modals/adicionar-pagamento/adicionar-pagamento.page';
import { ProgramaFidelidadePage } from 'src/app/modals/fidelidade/programa-fidelidade/programa-fidelidade.page';
import { ModalConfirmarEnderecoPage } from 'src/app/modals/modal-confirmar-endereco/modal-confirmar-endereco.page';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private initialBreakpoint: number | undefined;

  constructor(
    private celularService: CelularService,
    private modalController: ModalController
  ) {
    this.celularService.celular$.subscribe((celular) => {
      this.initialBreakpoint = celular ? 1 : undefined;
    });
  }

  async modalDetalhesPedido() {
    const modal = await this.modalController.create({
      component: DetalhesPedidoPage,
      cssClass: 'modal-detalhes-pedido',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      // showBackdrop: false,
      // presentingElement: await this.modalController.getTop()
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCPFNota() {
    const modal = await this.modalController.create({
      component: CpfNotaPage,
      cssClass: 'modal-cpf-nota',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCPFNotaBrowser() {
    const modal = await this.modalController.create({
      component: AdicionarCpfBrowserPage,
      cssClass: 'modal-cpf-nota-browser',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalLimparSacola() {
    const modal = await this.modalController.create({
      component: LimparSacolaPage,
      cssClass: 'modal-limpar-sacola',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSimples({
    titulo = '',
    texto = '',
    pagina = '',
  }: {
    titulo?: string;
    texto?: string;
    pagina?: string;
  }) {
    const modal = await this.modalController.create({
      component: SimplesPage,
      cssClass: 'modal-simples',
      componentProps: {
        titulo: titulo,
        texto: texto,
        pagina: pagina,
      },
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalLimparSacolaModalidade() {
    const modal = await this.modalController.create({
      component: LimparSacolaModalidadePage,
      cssClass: 'modal-limpar-sacola-modalidade',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalClienteImportado() {
    const modal = await this.modalController.create({
      component: ClienteImportadoPage,
      cssClass: 'modal-cliente-importado',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarProduto() {
    const modal = await this.modalController.create({
      component: EditarProdutoPage,
      cssClass: 'modal-editar-produto',
      swipeToClose: true,
      presentingElement: await this.modalController.getTop(),
      id: 'modal-editar-produto',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAlterarSenha() {
    const modal = await this.modalController.create({
      component: AlterarSenhaPage,
      cssClass: 'modal-alterar-senha',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalInativarConta(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: InativarContaPage,
      cssClass: 'modal-inativar-conta',
      id: 'inativar-conta',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalMudarLoja(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: MudarLojasPage,
      cssClass: 'modal-inativar-conta',
      id: 'mudar-loja',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalMudarLojaWeb() {
    const modal = await this.modalController.create({
      component: ModalMudarLojasWebPage,
      cssClass: 'modal-seleciona-modalidade-browser',
      id: 'mudar-loja-web',
      mode: 'ios',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirConta(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: ExcluirContaPage,
      cssClass: 'modal-excluir-conta',
      id: 'excluir-conta',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalProduto(elemento: HTMLElement = null) {
    const modal = await this.modalController.create({
      component: ProdutoPage,
      cssClass: 'modal-produto',
      swipeToClose: true,
      presentingElement: elemento,
      id: 'modal-produto',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirEndereco(elemento: HTMLElement, endereco) {
    const modal = await this.modalController.create({
      component: ExcluirEnderecoPage,
      cssClass: 'modal-excluir-endereco',
      componentProps: {
        endereco: endereco,
      },
      id: 'excluir-endereco',
      swipeToClose: true,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalRegulamentoFidelidade() {
    const modal = await this.modalController.create({
      component: FidelidadeRegulamentoPage,
      canDismiss: true,
      cssClass: 'modal-regulamento-fidelidade',
      id: 'regulamento-fidelidade',
    });

    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartao() {
    const modal = await this.modalController.create({
      component: AdicionarCartaoPage,
      cssClass: 'modal-add-cartao',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartaoBrowser(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoBrowserPage,
      cssClass: 'modal-add-cartao-browser',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartaoMP(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoMpPage,
      cssClass: 'modal-add-cartao',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarCartaoBrowserMP(dados_cartao = '') {
    const modal = await this.modalController.create({
      component: AdicionarCartaoBrowserMpPage,
      cssClass: 'modal-add-cartao-browser',
      canDismiss: true,
      componentProps: {
        dados_cartao,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAdicionarPagamento(dados_cartao = '', tipo: string, css, isPhone: boolean = false) {

    let modal;

    if (isPhone) {
      modal = await this.modalController.create({
        component: AdicionarPagamentoPage,
        cssClass: css,
        canDismiss: true,
        componentProps: {
          dados_cartao,
          tipo,
          isPhone
        },
        breakpoints: [0, 1],
        initialBreakpoint: 1,
      });
    } else {
      modal = await this.modalController.create({
        component: AdicionarPagamentoPage,
        cssClass: css,
        canDismiss: true,
        componentProps: {
          dados_cartao,
          tipo,
          isPhone
        }
      });
    }

    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarRestaurante() {
    const modal = await this.modalController.create({
      component: ConfirmarEdicaoRestaurantePage,
      cssClass: 'modal-edicao-restaurante',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalEditarRestauranteWeb() {
    const modal = await this.modalController.create({
      component: ConfirmaEdicaoRestauranteWebPage,
      cssClass: 'modal-seleciona-modalidade-browser',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCartoesCadastrados() {
    const modal = await this.modalController.create({
      component: CartoesCadastradosPage,
      cssClass: 'modal-cartoes-cadastrados',
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCartoesCadastradosBrowser() {
    const modal = await this.modalController.create({
      component: CartoesCadastradosBrowserPage,
      cssClass: 'modal-cartoes-cadastrados-browser',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalTrocarRestaurante() {
    const modal = await this.modalController.create({
      component: TrocarRestaurantePage,
      cssClass: 'modal-trocar-restaurante',
      swipeToClose: true,
      mode: 'ios',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFinalizarPedido(
    isModal: boolean = true,
    elemento: HTMLElement = null
  ) {
    const modal = await this.modalController.create({
      component: FinalizarPedidoPage,
      cssClass: 'modal-finalizar-pedido',
      id: 'finalizar-pedido',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalPagamentoPix() {
    const modal = await this.modalController.create({
      component: PagamentoPixPage,
      cssClass: 'modal-pagamento-pix',
      swipeToClose: true,
    });
    await modal.present();
  }

  async modalAvaliacao() {
    const modal = await this.modalController.create({
      component: AvaliacaoPage,
      cssClass: 'modal-avaliacao',
      swipeToClose: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirCartao(elemento: HTMLElement) {
    const modal = await this.modalController.create({
      component: ExcluirCartaoPage,
      cssClass: 'modal-excluir-conta',
      id: 'excluir-cartao',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      presentingElement: elemento,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalBuscaRestaurantes() {
    const modal = await this.modalController.create({
      component: BuscaRestaurantesPage,
      cssClass: 'modal-busca-restaurantes',
      id: 'busca-restaurantes',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  avaliarApp() {
    this.modalAvaliacao();
  }

  async modalConfirmaModalidade(carrinho, desconto) {
    const modal = await this.modalController.create({
      component: ConfirmaModalidadePage,
      cssClass: 'modal-confirma-modalidade',
      id: 'modal-confirma-modalidade',
      componentProps: { carrinho, desconto },
      swipeToClose: false,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSelecionaModalidade() {
    const modal = await this.modalController.create({
      component: SelecionarModalidadePage,
      cssClass: 'modal-seleciona-modalidade',
      id: 'modal-seleciona-modalidade',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSelecionaModalidadeBrowser() {
    const modal = await this.modalController.create({
      component: SelecionarModalidadeBrowserPage,
      cssClass: 'modal-seleciona-modalidade-browser',
      id: 'modal-seleciona-modalidade-browser',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSugestoes() {
    const modal = await this.modalController.create({
      component: SugestoesPage,
      cssClass: 'modal-sugestoes',
      id: 'modal-sugestoes',
      mode: 'ios',
      canDismiss: true,
      swipeToClose: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      backdropDismiss: false,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalNovoChamado() {
    const modal = await this.modalController.create({
      component: NovoChamadoPage,
      cssClass: 'modal-novo-chamdo',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalInativarContaWeb() {
    const modal = await this.modalController.create({
      component: InativarContaWebPage,
      cssClass: 'modal-inativar-conta-web',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirContaWeb() {
    const modal = await this.modalController.create({
      component: ExcluirContaWebPage,
      cssClass: 'modal-excluir-conta-web',
      id: 'excluir-conta',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalAlterarSenhaWeb() {
    const modal = await this.modalController.create({
      component: AlterarSenhaWebPage,
      cssClass: 'modal-pequena-web',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSimplesWeb({
    titulo = '',
    texto = '',
    pagina = '',
  }: {
    titulo?: string;
    texto?: string;
    pagina?: string;
  }) {
    const modal = await this.modalController.create({
      component: SimplesWebPage,
      cssClass: 'modal-simples-web',
      componentProps: {
        titulo: titulo,
        texto: texto,
        pagina: pagina,
      },
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      // presentingElement: await this.modalController.getTop()
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalConfirmacaoWeb({
    titulo = '',
    texto = '',
  }: {
    titulo?: string;
    texto?: string;
    pagina?: string;
  }) {
    const modal = await this.modalController.create({
      component: ModalConfirmacaoWebPage,
      cssClass: 'modal-confirmacao-web',
      componentProps: {
        titulo: titulo,
        texto: texto,
      },
      mode: 'ios',
      canDismiss: true,
      swipeToClose: true,
      breakpoints: [0, 1],
      initialBreakpoint: this.initialBreakpoint,
      presentingElement: await this.modalController.getTop(),
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirCartaoWeb() {
    const modal = await this.modalController.create({
      component: ExcluirCartaoWebPage,
      cssClass: 'modal-simples-web',
      id: 'excluir-cartao',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalExcluirEnderecoWeb(endereco) {
    const modal = await this.modalController.create({
      component: ExcluirEnderecoWebPage,
      cssClass: 'modal-simples-web',
      componentProps: {
        endereco: endereco,
      },
      id: 'excluir-endereco',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalSearchProdutos() {
    const modal = await this.modalController.create({
      component: SearchProdutoBrowserPage,
      cssClass: 'modal-search-produtos',
      id: 'search-produtos',
      canDismiss: true,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalProdutoBrowser(isBrowser: any = '', callback: any = '', redeId: number = 0) {
    const modal = await this.modalController.create({
      component: ModalProdutoPage,
      componentProps: {
        isBrowser,
        redeId
      },
      cssClass: 'modal-produto',
      id: 'modal-produto',
    });
    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }

  async modalBrowserConfirmaModalidade(callback: any = '') {
    const modal = await this.modalController.create({
      component: BrowserConfirmaModalidadePage,
      cssClass: 'browser-confirma-modalidade',
      id: 'browser-confirma-modalidade',
    });
    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }

  async modalBrowserSugestoes() {
    const modal = await this.modalController.create({
      component: BrowserSugestoesPage,
      cssClass: 'browser-sugestoes',
      id: 'browser-sugestoes',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalCarrinhoBrowser(callback: any = '') {
    const modal = await this.modalController.create({
      component: ModalCarrinhoBrowserPage,
      cssClass: 'modal-carrinho-browser',
      id: 'modal-carrinho-browser',
    });
    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }

  async modalDetalhesLojaBrowser(modalidade) {
    const modal = await this.modalController.create({
      component: DetalhesLojaBrowserPage,
      cssClass: 'detalhes-loja-browser',
      id: 'detalhes-loja-browser',
      canDismiss: true,
      componentProps: {
        modalidadeAtiva: modalidade,
      },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalFormasPagamento(formas, loja) {
    const modal = await this.modalController.create({
      component: SelecionarFormaPagamentoPage,
      cssClass: 'modal-forma',
      id: 'formas-pagamento',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: { formas: formas, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalTermos() {
    const modal = await this.modalController.create({
      component: PoliticaTermosPage,
      cssClass: 'modal-produto',
      id: 'detalhes-loja',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {},
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalPolitica() {
    const modal = await this.modalController.create({
      component: PoliticaPrivacidadePage,
      cssClass: 'modal-produto',
      id: 'detalhes-loja',
      swipeToClose: true,
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {},
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalConfirmaCartao(cartao) {
    const modal = await this.modalController.create({
      component: ConfirmaCartaoPage,
      cssClass: 'modal-confirma-cartao',
      id: 'confirma-cartao',
      componentProps: { cartao },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    await modal.present();
    return await modal.onDidDismiss();
  }
  async modalFormasPagamentoBrowser(formas, loja) {
    const modal = await this.modalController.create({
      component: SelecionarFormaPagamentoWebPage,
      cssClass: 'modal-add-cartao-browser',
      id: 'formas-pagamento',
      swipeToClose: true,
      canDismiss: true,
      componentProps: { formas: formas, loja: loja },
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async modalConfirmarEndereco(): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ModalConfirmarEnderecoPage,
      cssClass: 'modal modal-confirmar-endereco',
      id: 'modal-generica',
      canDismiss: true,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {},
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }

  ////////////////////////////////////////////////////////////////
  public async abrirModal(
    id: string,
    cssClass: string,
    component: Function,
    data: any = {},
    callback: any = '',
    canDismiss: boolean = true,
    breakpoints: boolean = true,
  ): Promise<any> {
    const modal = await this.modalController.create({
      id: id,
      cssClass: `modal ${cssClass}`,
      component: component,
      canDismiss: canDismiss,
      initialBreakpoint: this.initialBreakpoint,
      componentProps: {
        data: data,
      },
    });

    breakpoints ? (modal['breakpoints'] = [0, 1]) : '';

    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }

  public async fecharModal(acao: any = null): Promise<void> {
    await this.modalController.dismiss(acao);
  }

  public async existeModal(): Promise<HTMLIonModalElement> {
    return await this.modalController.getTop();
  }
}
