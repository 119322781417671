import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-confirmar-edicao-restaurante',
  templateUrl: './confirmar-edicao-restaurante.page.html',
  styleUrls: ['./confirmar-edicao-restaurante.page.scss'],
})
export class ConfirmarEdicaoRestaurantePage implements OnInit {

  constructor(
    private utils: UtilsService,
    private events: EventsService,
    private modalController: ModalController,
    private modals: ModalsService,
    
  ) { }

  ngOnInit() {
  }
 
  sim() {

    this.utils.setStorage('edicao-restaurante', 'sim');
    this.utils.removeStorage('tipo-compra-carrinho');
    this.utils.removeStorage('periodo-agendado-carrinho');
    this.utils.removeStorage('horario-agendado-carrinho');
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('modalidade_carrinho');
    this.utils.removeStorage('loja-fora-area');

    this.events.publish('fechar-modal');
    this.events.publish('atualizar-tabs');
    this.modalController.dismiss('sim');

  }

  nao() {
    this.modals.fecharModal();
  }

}
