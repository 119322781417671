import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { Endereco } from 'src/app/interfaces/endereco';
import { ModalsService } from 'src/app/services/modals/modals.service';
@Component({
  selector: 'app-excluir-endereco',
  templateUrl: './excluir-endereco.page.html',
  styleUrls: ['./excluir-endereco.page.scss'],
})
export class ExcluirEnderecoPage implements OnInit {

  public loading: boolean = false;
  public endereco: any;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private modalController: ModalController,
    private modals: ModalsService
  ) { }

  ngOnInit() { }

  async continuar() {

    const req = {
      enderecoId: this.endereco.id
    };

    this.loading = true;
    console.log(req);

    const res = await this.api.delete('endereco', req);

    this.loading = false;

    if (res.status === 'success') {
      await this.modalController.dismiss('', '', 'excluir-endereco');
      this.modals.modalSimples({ texto: 'O endereço foi excluído com sucesso!' });
    }

  }

  cancelar() {
    this.modalController.dismiss()
  }

}
