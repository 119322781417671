import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-limpar-sacola-modalidade',
  templateUrl: './limpar-sacola-modalidade.page.html',
  styleUrls: ['./limpar-sacola-modalidade.page.scss'],
})
export class LimparSacolaModalidadePage implements OnInit {

  public modalidade: any;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private modalController: ModalController
  ) {
    let modalidade = this.utils.getStorage('modalidade_carrinho').codigo;

    if (modalidade == 'DLV') {
      this.modalidade = 'delivery';
    } else if (modalidade == 'TGO') {
      this.modalidade = 'comer no restaurante';
    } else if (modalidade == 'TTA') {
      this.modalidade = 'buscar no restaurante';
    }
  }

  ngOnInit() {
  }

  sim() {
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('modalidade_carrinho');
    this.modalController.dismiss('sim');
  }

  nao() {
    this.modalService.fecharModal();
  }

}
