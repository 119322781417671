import { ModalsService } from 'src/app/services/modals/modals.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selecionar-forma-pagamento-web',
  templateUrl: './selecionar-forma-pagamento-web.page.html',
  styleUrls: ['./selecionar-forma-pagamento-web.page.scss'],
})
export class SelecionarFormaPagamentoWebPage implements OnInit {
  public formas: any;
  public loja: any;
  public bandeiras: any = {
    "HIPER": { image: 'assets/bandeiras/hiper.png', nome: 'Hiper' },
    "AMEX": { image: 'assets/bandeiras/amex.jpeg', nome: 'Amex' },
    "DINERS": { image: 'assets/bandeiras/diners.png', nome: 'Diners' },
    "ELO": { image: 'assets/bandeiras/elo.jpeg', nome: 'Elo' },
    "HIPERCARD": { image: 'assets/bandeiras/hipercard.png', nome: 'Hipercard' },
    "MASTERCARD": { image: 'assets/bandeiras/mastercard.png', nome: 'Mastercard' },
    "ALELO": { image: 'assets/bandeiras/alelo.jpeg', nome: 'Alelo' },
    "SODEXO": { image: 'assets/bandeiras/sodexo.jpeg', nome: 'Sodexo' },
    "TICKET_RESTAURANTE": { image: 'assets/bandeiras/ticket.png', nome: 'Ticket Restaurante' },
    "VISA_REFEICAO": { image: 'assets/bandeiras/vr.jpeg', nome: 'Visa Refei��o' },
    "VR": { image: 'assets/bandeiras/vr.jpeg', nome: 'VR' },
    "VISA": { image: 'assets/bandeiras/visa.png', nome: 'Visa' }
  }
  constructor(
    private modalController: ModalController,
    private modalService: ModalsService,
  ) { }

  ngOnInit() {
    this.formas.DEBIT?.forEach(element => {
      element.img = this.bandeiras[element.bandeira].image;
      element.nome_apresenta = this.bandeiras[element.bandeira].nome;
    });

    this.formas.CREDIT?.forEach(element => {
      element.img = this.bandeiras[element.bandeira].image;
      element.nome_apresenta = this.bandeiras[element.bandeira].nome
    });

    this.formas.MEAL_VOUCHER?.forEach(element => {
      element.img = this.bandeiras[element.bandeira].image;
      element.nome_apresenta = this.bandeiras[element.bandeira].nome;
    });
  }

  selecionarForma(forma, tipo) {

    this.modalController.dismiss({
      bandeira: forma.bandeira,
      nome: forma.nome,
      img: forma.img,
      tipo
    });
  }

  fechar() {
    this.modalController.dismiss(null);
  }

}
