import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-caixa-logo',
  templateUrl: './caixa-logo.component.html',
  styleUrls: ['./caixa-logo.component.scss'],
})
export class CaixaLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
