import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UsuarioService } from '../usuario/usuario.service';
import { UtilsService } from '../utils.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.model';
import { LojaService } from '../loja/loja.service';
import { ModalidadeService } from '../modalidade/modalidade.service';
import { ModalsService } from '../modals/modals.service';
import { ModalGenericaPage } from 'src/app/modals/modal-generica/modal-generica.page';
import { CarrinhoService } from '../carrinho/carrinho.service';

@Injectable({
  providedIn: 'root',
})
export class EnderecoService {
  private enderecosSubject: BehaviorSubject<any[] | null> = new BehaviorSubject(
    null
  );
  public enderecos$: Observable<any[] | null> =
    this.enderecosSubject.asObservable();

  private enderecoSelecionadoSubject: BehaviorSubject<any[] | null> =
    new BehaviorSubject(null);
  public enderecoSelecionado$: Observable<any[] | null> =
    this.enderecoSelecionadoSubject.asObservable();

  private usuario: IUsuario;

  constructor(
    private apiService: ApiService,
    private carrinhoService: CarrinhoService,
    private lojaService: LojaService,
    private modalidadeService: ModalidadeService,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    const enderecos = this.utilsService.getStorage('enderecos');

    if (enderecos) {
      this.enderecosSubject.next(enderecos);
    }

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });
  }

  public atualizarListaEnderecos(enderecos: any[]): void {
    if (enderecos !== null && enderecos !== undefined && enderecos.length > 0) {
      this.utilsService.setStorage('enderecos', enderecos);
      this.utilsService.setStorage('endereco', enderecos[0]);
      this.enderecosSubject.next(enderecos);
    } else {
      this.limparDadosArmazenados();
    }
  }

  public selecionarEndereco(
    endereco: any,
    atualizarPrincipal: boolean = false,
    redeId: number = null,
    atualizarLoja: boolean = true
  ): void {
    let enderecos = this.utilsService.getStorage('enderecos');

    enderecos.forEach((endereco) => {
      endereco.principal = false;
    });

    let search = enderecos.findIndex(function (element: any) {
      if (element.id && element.id !== 0) {
        return element.id == endereco.id;
      } else {
        return (
          element.apelido == endereco.apelido &&
          element.bairro == endereco.bairro &&
          element.cep == endereco.cep &&
          element.cidade == endereco.cidade &&
          element.estado == endereco.estado &&
          element.lat == endereco.lat &&
          element.lng == endereco.lng &&
          element.logradouro == endereco.logradouro &&
          element.numero == endereco.numero
        );
      }
    });

    enderecos.splice(search, 1);

    enderecos.unshift(endereco);

    this.setPrincipal(endereco);

    if (endereco && endereco.id && endereco.id !== 0 && atualizarPrincipal) {
      this.atualizarPrincipal(endereco);
    }

    this.utilsService.setStorage('enderecos', enderecos);
    this.enderecosSubject.next(enderecos);
    this.enderecoSelecionadoSubject.next(endereco);

    if (atualizarLoja) this.lojaService.buscarLoja(endereco, 'DLV', redeId);
  }

  private setPrincipal(endereco: any): any {
    endereco['principal'] = true;
  }

  public async removerEndereco(endereco: any): Promise<void> {
    if (this.usuario !== null) {
      this.deleteEndereco(endereco);
    } else {
      let enderecos: any[];

      this.enderecos$.subscribe((lista_enderecos) => {
        enderecos = lista_enderecos;
      });

      let paraRemover = enderecos.findIndex(function (element: any) {
        return (
          element.bairro == endereco.bairro &&
          element.cep == endereco.cep &&
          element.cidade == endereco.cidade &&
          element.estado == endereco.estado &&
          element.lat == endereco.lat &&
          element.lng == endereco.lng &&
          element.logradouro == endereco.logradouro &&
          element.numero == endereco.numero
        );
      });

      enderecos.splice(paraRemover, 1);

      this.utilsService.setStorage('enderecos', enderecos);
    }
  }

  private async deleteEndereco(endereco: any): Promise<any[]> | null {
    let enderecos: any[];

    enderecos = this.enderecosSubject.value;

    const nova_lista: any[] = enderecos.filter(
      (endereco_filtrado) => endereco_filtrado.id !== endereco.id
    );

    const req = {
      enderecoId: endereco.id,
    };

    const res = await this.apiService.delete('endereco_pedido', req);

    if (res.status == 'success') {
      this.utilsService.exibirToast(res.message, 'sucesso');
      this.atualizarListaEnderecos(nova_lista);

      return nova_lista;
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
      return null;
    }
  }

  public limparDadosArmazenados(): void {
    this.enderecosSubject.next(null);
    this.utilsService.removeStorage('enderecos');
    this.utilsService.removeStorage('endereco');
  }

  public async verificarCarrinho(
    endereco: any,
    atualizarPrincipal: boolean = false,
    codigo_modalidade: string | null = null,
    redeId: number = null
  ) {
    const carrinho = this.utilsService.getStorage('carrinho');

    if (carrinho !== null) {
      let data;

      if (codigo_modalidade == null) {
        data = {
          titulo: 'Trocar de endere�o',
          paragrafos: [
            'Voc� tem certeza que quer trocar de endere�o?',
            'Voc� possui um carrinho com outra loja, se trocar de endere�o perder� seu carrinho atual.',
          ],
          label_acao: 'Trocar de endere�o',
          acao: 'alterar-endereco',
        };
      } else {
        data = {
          titulo: 'Trocar modalidade',
          paragrafos: [
            'Voc� tem certeza que quer trocar a modalidade?',
            'Voc� possui um carrinho com outra modalidade, se trocar perder� seu carrinho atual.',
          ],
          label_acao: 'Trocar modalidade',
          acao: 'alterar-modalidade',
        };
      }

      const resposta = await this.modalsService.abrirModal(
        'modal-generica',
        'modal-generica center',
        ModalGenericaPage,
        { data: data }
      );

      return new Promise<boolean>((resolve) => {
        let response: boolean;

        if (resposta?.data === 'executar') {
          this.carrinhoService.limparCarrinho();
          response = true;
        } else {
          response = false;
        }

        resolve(response);
      });
    } else {
      if (endereco !== null) {
        this.selecionarEndereco(endereco, true, redeId);

        return true;
      } else {
        this.modalidadeService.atualizarModalidade(codigo_modalidade);

        return true;
      }
    }
  }

  private async atualizarPrincipal(endereco: any): Promise<void> {
    let req = {
      ...endereco,
      selecionar: true,
    };

    await this.apiService.put('endereco_pedido', req);
  }
}
