import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-cliente-importado',
  templateUrl: './cliente-importado.page.html',
  styleUrls: ['./cliente-importado.page.scss'],
})
export class ClienteImportadoPage implements OnInit {

  constructor(
    private modalService: ModalsService,
  ) { }

  ngOnInit() {
  }

  async fechar() {
    await this.modalService.fecharModal();
  }

}
