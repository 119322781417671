import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-excluir-conta',
  templateUrl: './excluir-conta.page.html',
  styleUrls: ['./excluir-conta.page.scss'],
})
export class ExcluirContaPage implements OnInit {

  public loading: boolean = false;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private modalController: ModalController,
    private modalService: ModalsService
    // private firebase: FirebaseAnalyticsService
  ) { }

  ngOnInit() {
  }

  fechar() {
    this.modalController.dismiss();
  }

  async excluirConta() {

    const req = {
      jwt: this.utils.getStorage('usuario')['jwt']
    };

    this.loading = true;

    const res = await this.api.delete('cliente', req);

    this.loading = false;

    if (res.status === 'success') {

      // this.firebase.criarEvento('APP_DELETE');
      await this.modalService.fecharModal();
      // this.firebase.criarEvento('APP_INACTIVATE');
      localStorage.clear();
      this.utils.navegarPaginaRoot('login');
      this.modalService.modalSimples({
        texto: 'A sua conta foi excluída permanentemente, mas estaremos te esperando caso queira se cadastrar novamente.',
      });
    }
  }
}