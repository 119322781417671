import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-privacidade-web',
  templateUrl: './politicas-privacidade-web.component.html',
  styleUrls: ['./politicas-privacidade-web.component.scss'],
})
export class PoliticasPrivacidadeWebComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
