import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.model';
import { EventsService } from '../events.service';
import { ApiService } from '../api.service';
@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private usuarioSubject = new BehaviorSubject<IUsuario | null>(null);
  public usuario$: Observable<IUsuario | null> =
    this.usuarioSubject.asObservable();

  constructor(
    private utilsService: UtilsService,
    private events: EventsService,
    private apiService: ApiService
  ) {
    const usuario = this.utilsService.getStorage('usuario');

    if (usuario) {
      this.usuarioSubject.next(usuario);
    }
  }

  public async atualizarUsuario(usuario: IUsuario): Promise<IUsuario> {
    this.usuarioSubject.next(usuario);

    if (usuario !== null) {
      this.utilsService.setStorage('usuario', usuario);

      const loja = this.utilsService.getStorage('loja');

      if (usuario.jwt != null && loja != null) {
        const carteira = await this.apiService.get('carteira', { 'metodo': (loja.metodo_pagamento != null) ? loja.metodo_pagamento : 'mercadopago' });

        if (carteira.data) {
          usuario.cartoes = carteira.data
        } else {
          usuario.cartoes = [];
        }
      }
    }

    return this.usuarioSubject.value;
  }

  public logOff(message: string = 'Voc� foi deslogado com sucesso.'): void {
    localStorage.clear();
    this.usuarioSubject.next(null);
    this.utilsService.navegarPaginaRoot('/login');
    this.utilsService.exibirToast(message, 'sucesso');
  }
}
