import { Injectable } from '@angular/core';

declare let dataLayer: any;
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  public address_not_answered(endereco: any): any {
    const event = {
      event: 'address_not_answered',
      cep: endereco.cep,
    };

    return event;
  }

  public view_item_list(lista: any, loja: any): void {
    const event: any = {
      event: 'view_item_list',
      ecommerce: {
        items: this.itemsEvent(lista, loja),
      },
    };

    return event;
  }

  public select_item(item: any, categoria: any, loja: any): any {
    const event: any = {
      event: 'select_item',
      ecommerce: {
        affiliation: loja.nome,
        items: [
          {
            item_list_id: categoria.id.toString(),
            item_list_name: categoria.categoria,
            item_id: item.cod,
            item_name: item.produto,
            item_brand: loja.nome_rede,
            item_category: categoria.categoria,
            item_category2: false,
            price: item.preco.valor,
            currency: 'BRL',
            quantity: 1,
          },
        ],
      },
    };

    return event;
  }

  public select_promotion(banner: any): any {
    const event  = {
      event: 'select_promotion',
      items: [{
        creative_name: banner.imagem,
        creative_slot: banner.ordem,
        promotion_id: banner.id,
        promotion_name: banner.nome,
      }],
    };

    return event;
  }

  public view_promotion(banners: any[]): any {
    const event = {
      event: 'view_promotion',
      ecommerce: {
        items: this.itemsBanner(banners),
      },
    };

    return event;
  }

  private itemsBanner(banners: any[]): any[] {
    let items = [];

    banners.forEach((item, index) => {
      let ordem = index + 1;

      items.push({
        creative_name: item.imagem,
        creative_slot: ordem.toString(),
        promotion_id: '',
        promotion_name: item.nome,
      });
    });

    return items;
  }

  private itemsEvent(objeto: any, loja: any): any[] {
    let items = [];

    objeto.cardapio.forEach((elemento: any) => {
      items.push({
        item_list_id: objeto.id.toString(),
        item_list_name: objeto.categoria,
        item_id: elemento.cod,
        item_category: objeto.categoria,
        item_name: elemento.produto,
        item_brand: loja !== null ? loja.nome_rede : '',
        price: elemento.preco.valor,
        currency: 'BRL',
        quantity: 1,
      });
    });

    return items;
  }

  public registrarEvento(objeto: any) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(objeto);
  }
}
