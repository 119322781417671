import { EventsService } from './../../services/events.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-trocar-restaurante',
  templateUrl: './trocar-restaurante.page.html',
  styleUrls: ['./trocar-restaurante.page.scss'],
})
export class TrocarRestaurantePage implements OnInit {

  public loja: string = '';

  constructor(
    private utils: UtilsService,
    private modalController: ModalController,
    private events: EventsService,
    private modals: ModalsService
  ) {
    this.loja = this.utils.getStorage('loja').nome;
  }

  ngOnInit() {
  }

  sim() {

    this.utils.setStorage('edicao-restaurante', 'sim');
    this.utils.removeStorage('tipo-compra-carrinho');
    this.utils.removeStorage('periodo-agendado-carrinho');
    this.utils.removeStorage('horario-agendado-carrinho');
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('modalidade_carrinho');
    this.utils.removeStorage('loja-fora-area');

    let enderecos = this.utils.getStorage('enderecos');
    const i = this.utils.getStorage('endereco-carrinho-index');

    let endereco = enderecos[i];

    endereco.principal = true;

    enderecos.splice(i, 1);
    enderecos.forEach(item => {
      item.principal = false;
    });

    enderecos.unshift(endereco);

    this.utils.setStorage('enderecos', enderecos);

    this.events.publish('atualizar-endereco');
    this.events.publish('fechar-modal');
    this.modalController.dismiss('sim');
  }

  nao() {
    this.modals.fecharModal();
  }

}
