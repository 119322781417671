import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-dados-debito',
  templateUrl: './dados-debito.component.html',
  styleUrls: ['./dados-debito.component.scss'],
})
export class DadosDebitoComponent implements OnInit {
  public access_token: string;
  public ip: string;
  @Input() dados: any;

  constructor(
    private api: ApiService
  ) {
    fetch('https://ipinfo.io/json')
    .then(response => response.json())
    .then(data => {
      this.ip = data.ip
    })
    .catch(error => {
    });
  }

  async ngOnInit() {
    let res = await this.api.get('auth_3ds');
    this.access_token = res.data.access_token;
  }

}
