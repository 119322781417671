import { UtilsService } from 'src/app/services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-limpar-sacola',
  templateUrl: './limpar-sacola.page.html',
  styleUrls: ['./limpar-sacola.page.scss'],
})
export class LimparSacolaPage implements OnInit {

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  sim() {
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('modalidade_carrinho');
    this.events.publish("carrinho-numero");
    this.modalController.dismiss('sim');
  }

  nao() {
    this.modalService.fecharModal();
  }

}
