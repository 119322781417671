import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetatagService {
  private originalMetaData: Array<HTMLMetaElement>;
  private originalMetaTitle: string;
  private originalMetaDescription: string;

  constructor(private meta: Meta, private title: Title) {
    this.originalMetaData = this.meta.getTags('name="description"');
    this.originalMetaTitle = this.title.getTitle();
    this.originalMetaDescription = this.meta.getTags('content')[0].content;
  }

  /** Função que alterará o metatítulo e metadescrição da tela.
   * @param metatitle texto que deverá aparecer na aba do navegador.
   * @param description texto que descreverá a tela.
   */
  setMetaData(metatitle: string, description: string) {
    const allMetaTags = this.originalMetaData;

    if (allMetaTags.length > 0) {
      for (let i = 1; i < allMetaTags.length; i++) {
        this.meta.removeTagElement(allMetaTags[i]);
      }

      this.setDescription(description);
    }

    this.setTitle(metatitle);
  }

  /** Função interna para definir o metatítulo da página.
   * @param metatitle texto que deverá aparecer na aba do navegador.
   */
  setTitle(metatitle: string): void {
    if (metatitle !== undefined && metatitle !== null) {
      this.title.setTitle(metatitle);
      this.meta.updateTag({ property: 'og:title', content: metatitle });
    }
  }

  /** Função interna para definir a metadescrição da página.
   * @param metadescription texto que descreverá a tela.
   */
  setDescription(metadescription: string): void {
    const allMetaTags = this.originalMetaData;

    if (metadescription !== null && metadescription !== undefined) {
      allMetaTags[0].setAttribute('content', metadescription);
      this.meta.updateTag({
        property: 'og:description',
        content: metadescription,
      });
    }
  }

  /** Função que redefinirá o metatítulo e a metadescrição para o padrão do site,
   * além de readicionar outras meta tags cujo nome seja igual a description caso existam.
   */
  removeMetaTag(): void {
    const allMetaTags = this.originalMetaData.slice();
    this.setTitle(this.originalMetaTitle);
    this.setDescription(this.originalMetaDescription);

    if (allMetaTags.length > 0) {
      for (let i = 1; i < allMetaTags.length; i++) {
        const objMetaData = {
          name: 'description',
          content: allMetaTags[i].content,
        };

        this.meta.addTag(objMetaData);
      }
    }
  }
}
