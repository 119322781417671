import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-mudar-lojas-web',
  templateUrl: './modal-mudar-lojas-web.page.html',
  styleUrls: ['./modal-mudar-lojas-web.page.scss'],
})
export class ModalMudarLojasWebPage implements OnInit {
  public loading: boolean = false;
  public loja: any = [];
  public lojas: any;
  public  mudarRestaurantes;
  public restaurante: any = [];
  public novoloja;
  public selectedValue: string;
  public isPhone;


  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private api: ApiService,
    private modalController: ModalController,
    public events: EventsService,
    private responsive: BreakpointObserver
  ) { }

  ngOnInit() {
    this.loja = this.utils.getStorage('loja');
    this.carregarLojas();  
  }

  fechar() {
    this.modalController.dismiss();
  }

  async carregarLojas(){

    this.loading = true;
    let req = {
      lat: this.utils.getStorage('endereco')['lat'],
      lng: this.utils.getStorage('endereco')['lng'],
      marcaId: this.loja.marcaId,
    }
    const res = await this.api.get('trocar_loja', req);
    if (res.status === 'success') {
      this.loading = false;
      if (res.data != null) {
        this.lojas = res.data; 
      }
    }
  }

  async mudarLoja(event, loja){
    event.preventDefault()
    
    if (loja.id == this.loja.id) {
      this.events.publish('loja', {update: true});

      this.modalController.dismiss();
    }
    else if(this.utils.getStorage('carrinho') == null){
      this.utils.setStorage('loja', loja);

      this.events.publish('loja', {update: true});
      this.modalController.dismiss({close: true});
  
      
    }else{
      this.modalService.modalEditarRestauranteWeb().then(async (res) => {

        if (res.data === 'sim') {
          this.utils.setStorage('loja', loja);

          this.events.publish('loja', {update: true});
          this.modalController.dismiss({close: true});
          
        }
        else{
          this.modalController.dismiss();
        }
       
      });
     }   
  }

}
