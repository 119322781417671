import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-inativar-conta',
  templateUrl: './inativar-conta.page.html',
  styleUrls: ['./inativar-conta.page.scss'],
})
export class InativarContaPage implements OnInit {
  public loading: boolean = false;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private api: ApiService,
    private modalController: ModalController
  ) // private firebase: FirebaseAnalyticsService
  {}

  ngOnInit() {}

  fechar() {
    this.modalController.dismiss();
  }

  async inativarConta() {
    const req = {
      jwt: this.utils.getStorage('usuario')['jwt'],
    };

    this.loading = true;

    const res = await this.api.post('inativar_conta', req);

    this.loading = false;

    if (res.status === 'success') {
      await this.modalService.fecharModal();
      // this.firebase.criarEvento('APP_INACTIVATE');
      localStorage.clear();
      this.utils.navegarPaginaRoot('login');
      this.modalService.modalSimples({
        texto:
          'A sua conta está inativa, mas se quiser reativa-la é simples! Basta entrar em contato com nossa equipe que já vamos te ativar de novo. Esperamos por você.',
      });
    }
  }
}
