import { API_URL, PASSPHRASE_AUTH, ENCRYPT } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api';
import { UtilsService } from './utils.service';
import { AlertController } from '@ionic/angular';
import AES256 from 'aes-everywhere';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private header: HttpHeaders;
  private key: string;

  constructor(
    private _http: HttpClient,
    private utils: UtilsService,
    private alertController: AlertController,
    private auth: AuthService
  ) { }

  public async get(endpoint: string, data: any = {}): Promise<ApiResponse> {
    let key;

    if (ENCRYPT) {
      if (this.key === undefined || this.key === null) {
        this.key = await this.getToken();
      }

      key = AES256.decrypt(this.key, PASSPHRASE_AUTH);
    }

    this.atualizarHeader();

    const promise: any = new Promise(async (resolve: any) => {

      try {

        if ((data != {} || data != '' || data != null) && ENCRYPT) {

          data = {
            data: AES256.encrypt(JSON.stringify(data), key)
          }

        }

        this._http.get(API_URL + endpoint + '/', {
          params: data,
          headers: this.header
        }).subscribe(async (res: ApiResponse) => {

          await this.utils.ocultarLoading();

          if (res.responseCode === 401) {
            await this.logout();
          }

          try {

            if (res.data !== undefined) {
              if (ENCRYPT) {
                res.data = JSON.parse(AES256.decrypt(res.data, key));
              }
            }

            resolve(res);

          } catch (error) {
            console.log(error);
            await this.logout();
            resolve({ status: 'error' });
          }

        }, async (res: HttpErrorResponse) => {

          await this.utils.ocultarLoading();

          if (res.status === 401) {
            await this.logout();
          }

          if (res.error.data !== undefined) {
            if (ENCRYPT) {
              res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
            }
          }

          resolve(res.error);

        });

      } catch (error) {
        console.log(error);
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
        resolve({ status: 'error' });
      }

    });

    return promise;

  }

  public async post(endpoint: string, data: any = {}): Promise<ApiResponse> {

    let key;

    if (ENCRYPT) {
      if (this.key === undefined || this.key === null) {
        this.key = await this.getToken();
      }

      key = AES256.decrypt(this.key, PASSPHRASE_AUTH);
    }

    this.atualizarHeader();

    const promise: any = new Promise(async (resolve: any) => {

      try {

        if ((data != {} || data != '' || data != null) && ENCRYPT) {

          data = {
            data: AES256.encrypt(JSON.stringify(data), key)
          }

        }

        this._http.post(API_URL + endpoint, data, {
          headers: this.header
        })
          .subscribe(async (res: ApiResponse) => {

            await this.utils.ocultarLoading();

            if (res.responseCode === 401) {
              await this.logout();
            }

            try {

              if (res.data !== undefined) {
                if (ENCRYPT) {
                  res.data = JSON.parse(AES256.decrypt(res.data, key));
                }
              }

              resolve(res);

            } catch (error) {
              console.log(error);
              await this.logout();
              resolve({ status: 'error' });
            }

          }, async (res: HttpErrorResponse) => {

            await this.utils.ocultarLoading();

            if (res.status === 401) {
              await this.logout();
            }

            if (res.error.data !== undefined) {
              if (ENCRYPT) {
                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
              }
            }

            resolve(res.error);

          });

      } catch (error) {
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
        resolve({ status: 'error' });
      }

    });

    return promise;

  }

  public async postExterno(endpoint: string, data: any = {}): Promise<ApiResponse> {

    let key;

    if (ENCRYPT) {
      if (this.key === undefined || this.key === null) {
        this.key = await this.getToken();
      }

      key = AES256.decrypt(this.key, PASSPHRASE_AUTH);
    }

    this.atualizarHeader();

    const promise: any = new Promise(async (resolve: any) => {

      try {

        if ((data != {} || data != '' || data != null) && ENCRYPT) {

          data = {
            data: AES256.encrypt(JSON.stringify(data), key)
          }

        }

        this._http.post(endpoint, data, {
          headers: this.header
        })
          .subscribe(async (res: ApiResponse) => {

            await this.utils.ocultarLoading();

            if (res.responseCode === 401) {
              await this.logout();
            }

            try {

              if (res.data !== undefined) {
                if (ENCRYPT) {
                  res.data = JSON.parse(AES256.decrypt(res.data, key));
                }
              }

              resolve(res);

            } catch (error) {
              console.log(error);
              await this.logout();
              resolve({ status: 'error' });
            }

          }, async (res: HttpErrorResponse) => {

            await this.utils.ocultarLoading();

            if (res.status === 401) {
              await this.logout();
            }

            if (res.error.data !== undefined) {
              if (ENCRYPT) {
                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
              }
            }

            resolve(res.error);

          });

      } catch (error) {
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
        resolve({ status: 'error' });
      }

    });

    return promise;

  }

  public async put(endpoint: string, data: any = {}): Promise<ApiResponse> {

    let key;

    if (ENCRYPT) {
      if (this.key === undefined || this.key === null) {
        this.key = await this.getToken();
      }

      key = AES256.decrypt(this.key, PASSPHRASE_AUTH);
    }

    this.atualizarHeader();

    const promise: any = new Promise(async (resolve: any) => {


      try {

        if ((data != {} || data != '' || data != null) && ENCRYPT) {

          data = {
            data: AES256.encrypt(JSON.stringify(data), key)
          }

        }

        this._http.put(API_URL + endpoint, data, {
          headers: this.header
        })
          .subscribe(async (res: ApiResponse) => {

            await this.utils.ocultarLoading();

            if (res.responseCode === 401) {
              await this.logout();
            }

            try {

              if (res.data !== undefined) {
                if (ENCRYPT) {
                  res.data = JSON.parse(AES256.decrypt(res.data, key));
                }
              }

              resolve(res);

            } catch (error) {
              console.log(error);
              await this.logout();
              resolve({ status: 'error' });
            }

          }, async (res: HttpErrorResponse) => {

            await this.utils.ocultarLoading();

            if (res.status === 401) {
              await this.logout();
            }

            if (res.error.data !== undefined) {
              if (ENCRYPT) {
                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
              }
            }

            resolve(res.error);

          });

      } catch (error) {
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
        resolve({ status: 'error' });
      }

    });

    return promise;

  }

  public async delete(endpoint: string, data: any = {}): Promise<ApiResponse> {

    let key;

    if (ENCRYPT) {
      if (this.key === undefined || this.key === null) {
        this.key = await this.getToken();
      }

      key = AES256.decrypt(this.key, PASSPHRASE_AUTH);
    }

    this.atualizarHeader();

    const promise: any = new Promise(async (resolve: any) => {

      try {

        if ((data != {} || data != '' || data != null) && ENCRYPT) {

          data = {
            data: AES256.encrypt(JSON.stringify(data), key)
          }

        }

        this._http.delete(API_URL + endpoint + '/', {
          params: data,
          headers: this.header
        }).subscribe(async (res: ApiResponse) => {

          await this.utils.ocultarLoading();

          if (res.responseCode === 401) {
            await this.logout();
          }

          try {

            if (res.data !== undefined) {
              if (ENCRYPT) {
                res.data = JSON.parse(AES256.decrypt(res.data, key));
              }
            }

            resolve(res);

          } catch (error) {
            console.log(error);
            await this.logout();
            resolve({ status: 'error' });
          }

        }, async (res: HttpErrorResponse) => {

          await this.utils.ocultarLoading();

          if (res.status === 401) {
            await this.logout();
          }

          if (res.error.data !== undefined) {
            if (ENCRYPT) {
              res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
            }
          }

          resolve(res.error);

        });

      } catch (error) {
        console.log(error);
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
        resolve({ status: 'error' });
      }

    });

    return promise;

  }

  private atualizarHeader() {

    const usuario = this.utils.getStorage('usuario');

    if (usuario != null) {

      this.header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + usuario.jwt,
        'Encrypt': (ENCRYPT) ? 'true' : 'false'
      });

    } else {

      this.header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Encrypt': (ENCRYPT) ? 'true' : 'false'
      });

    }

  }

  private async logout() {
    localStorage.clear();
    this.utils.navegarPaginaRoot('login');
    const alerta = await this.alertController.create({
      header: "Sess�o expirada",
      message: "Fa�a login novamente para renovar sua sess�o.",
      buttons: [
        {
          text: "OK",
          role: "cancel"
        },
      ]
    });
    await alerta.present();
  }

  public async getToken() {

    const promise: any = new Promise(async (resolve: any) => {

      try {

        this._http.get(API_URL + this.auth.gerarEndpoint() + '/', {
          headers: new HttpHeaders({
            'Encrypt': (ENCRYPT) ? 'true' : 'false'
          })
        })
          .subscribe(async (res: ApiResponse) => {

            await this.utils.ocultarLoading();

            if (res.responseCode === 401) {
              await this.logout();
            }

            resolve(res.data);

          }, async (res: HttpErrorResponse) => {
            await this.utils.ocultarLoading();
            if (res.status === 401) {
              await this.logout();
            }
          });
      } catch (error) {
        console.log(error);
        await this.utils.ocultarLoading();
        this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'erro');
      }

    });

    return promise;

  }

}
