import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IonContent, IonSlides } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LojaService } from 'src/app/services/loja/loja.service';

@Component({
  selector: 'app-slider-categorias',
  templateUrl: './slider-categorias.component.html',
  styleUrls: ['./slider-categorias.component.scss'],
})
export class SliderCategoriasComponent implements OnInit {

  public carregando$: Observable<boolean> = this.lojaService.carregando$;

  @ViewChild('content', { static: true }) content: IonContent;
  @ViewChild('slideCategoria') slideCategoria: IonSlides;

  @Output() indexChange: EventEmitter<number> = new EventEmitter<number>();

  public loja: any;
  public cardapio: any[];
  public cardapio_filtrado: any[];
  public categoria_selecionada: number;
  public categoriasOpt = {
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 10,
    roundLengths: true,
    navigation: true,
  };

  constructor(private lojaService: LojaService, private analyticsService: AnalyticsService,) { }

  ngOnInit() {
    this.getCardapio();
  }

  private getCardapio(): void {
    this.lojaService.cardapio$.subscribe((cardapio) => {
      this.cardapio = cardapio;
      this.cardapio_filtrado = cardapio;

      if (this.cardapio_filtrado) {
        this.cardapio_filtrado = this.redoObject(this.cardapio_filtrado);

        this.cardapio_filtrado.map((cardapio) => {
          const event = this.analyticsService.view_item_list(
            cardapio,
            this.loja
          );

          this.analyticsService.registrarEvento(event);
        });
      }
    });
  }

  private redoObject(object: any): any[] {
    const array = object.map((element: any) => {
      const object = {
        id: element.id,
        categoria: element.categoria,
        cardapio: element.cardapio.map((item: any) => ({
          cardapioId: item.cardapioId,
          categoriaId: item.categoriaId,
          categoria_nome: item.categoria_nome,
          cod: item.cod,
          combo: item.combo,
          descricao: item.descricao,
          id: item.id,
          alt_produto: item.alt_produto,
          foto: item.imagem,
          imagem: item.imagem,
          marcaId: item.marcaId,
          observacoes: item.observacoes,
          preco: item.preco,
          preco_obs: item.preco_obs,
          preco_apartir: item.preco_partir,
          titulo: item.produto,
          produto: item.produto,
        })),
      };

      return object;
    });

    return array;
  }

  public clickScroll(index: number): void {
    const offsetTop = this.getViewportWidthInPixels(10);

    const categoria = document.getElementById(`${index}`).offsetTop;
    this.indexChange.emit(index);
    this.content.scrollToPoint(0, categoria - offsetTop, 500);
  }

  public slide(elemento: IonSlides | IonContent, metodo: string): void {
    switch (metodo) {
      case 'next':
        (elemento as IonSlides).slideNext();
        break;
      case 'previous':
        (elemento as IonSlides).slidePrev();
        break;
      case 'top':
        this.content.scrollToTop(500);
        break;
      default:
        break;
    }
  }

  public scrollEnd(): void {
    this.content.getScrollElement().then((scrollElement: HTMLElement) => {
      const scrollTop = scrollElement.scrollTop;
      const offsetTop = this.getViewportWidthInPixels(15);

      const categorias = document.querySelectorAll('.produtos-categoria');

      categorias.forEach(async (categoria: HTMLElement, index: number) => {
        if (scrollTop >= categoria.offsetTop - offsetTop) {
          this.slideCategoria.slideTo(index);
          this.categoria_selecionada = index;
        }
      });
    });
  }

  private getViewportWidthInPixels(vw: number): number {
    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    return (viewportWidth * vw) / 100;
  }


}
