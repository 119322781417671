import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { IEndereco } from 'src/app/interfaces/IEndereco.model';
import { IUsuario } from 'src/app/interfaces/IUsuario.model';

import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-meus-enderecos',
  templateUrl: './meus-enderecos.component.html',
  styleUrls: ['./meus-enderecos.component.scss'],
})
export class MeusEnderecosComponent implements OnInit {
  public enderecos!: IEndereco[];
  public usuario$: Observable<IUsuario> = this.usuarioService.usuario$;

  public data;

  constructor(
    private enderecoService: EnderecoService,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.enderecoService.enderecos$.subscribe(
      (enderecos) => (this.enderecos = enderecos)
    );
  }

  ngOnInit() { }

  public async selecionarEndereco(endereco: IEndereco): Promise<void> {
    const url = this.router.url;
    const paginasEspecificas = ['/tabs/gokei', '/tabs/gendai', '/tabs/koni', '/tabs/owan'];

    if (paginasEspecificas.includes(url)) {
      const confirmado = await this.modalsService.modalConfirmarEndereco();
      if (confirmado) {
        if (await this.enderecoService.verificarCarrinho(endereco, true))
          this.enderecoService.selecionarEndereco(endereco);

        this.modalsService.fecharModal();
        this.utilsService.navegarPagina('/home');
      } else {
        this.modalsService.fecharModal();
      }
    } else {
      if (await this.enderecoService.verificarCarrinho(endereco, true))
        this.enderecoService.selecionarEndereco(endereco);

      this.modalsService.fecharModal();
    }
  }

  public deletarEndereco(endereco: IEndereco): void {
    this.enderecoService.removerEndereco(endereco);
  }

  public async navegar(caminho: string) {
    const modal = await this.modalsService.existeModal();

    if (modal) {
      this.modalsService.fecharModal();
    }

    this.utilsService.navegarPagina(caminho);
  }

}
