import { CarrinhoService } from 'src/app/services/carrinho/carrinho.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { SlideOptions } from 'src/app/interfaces/slide';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

declare let dataLayer: any;

@Component({
  selector: 'app-modal-carrinho-browser',
  templateUrl: './modal-carrinho-browser.page.html',
  styleUrls: ['./modal-carrinho-browser.page.scss'],
})
export class ModalCarrinhoBrowserPage implements OnInit {
  public rede_logo;
  public optionProdutos: SlideOptions;
  public total: number;
  public total_sem_cashback: number;
  public subtotal: number;
  public carrinho: Array<any>;
  public sugestoes: Array<any>;
  public marca: number;
  public cor: string;
  public loja: any;
  public usuario: any;
  public cupom: string;
  public loading: boolean;
  public modalidade: any;
  public frete: number;
  public usar_cashback: boolean = false;
  public opcaoRemover: boolean = false;
  public cashback: number = 0;
  public brand: any = {};
  public adicionais_selecionado: any;
  public adicionais_selecionados: any;
  public dados_produto: any;
  public descontoFrete: number = 0;

  public desconto: {
    cupom?: string;
    cupomId?: number;
    valor?: number;
    tipo?: string;
    porcentagem?: number;
    frete?: number;
    categoriaId?: number;
    codProduto?: string;
  };
  isValid: boolean;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private modalController: ModalController,
    private api: ApiService,
    private events: EventsService,
    private platform: Platform,
    private carrinhoService: CarrinhoService,
    private usuarioService: UsuarioService
  ) {
    this.events.subscribe('carrinho', () => {
      this.inicializarPagina();
    });

    this.inicializarPagina();
    this.buscarSaldo();
  }

  inicializarPagina() {
    this.rede_logo = this.utils.getStorage('loja').cabecalho;

    // Inicializando variáveis
    this.optionProdutos = {
      slidesPerView: 3,
      spaceBetween: 10,
      navigation: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
    };

    this.cupom = '';

    this.sugestoes = [];

    this.loading = false;

    this.desconto = {
      cupom: '',
      cupomId: 0,
      valor: 0,
      tipo: '',
      porcentagem: 0,
      frete: 0,
    };

    // Removendo e buscando valores no localStorage
    this.utils.removeStorage('desconto');
    this.loja = this.utils.getStorage('loja');
    this.modalidade = this.utils.getStorage('modalidade');
    this.carrinho = this.utils.getStorage('carrinho');
    this.usuario = this.utils.getStorage('usuario');
    if (this.utils.getStorage('cupomDesconto') != null) {
      this.cupom = this.utils.getStorage('cupomDesconto');
      this.buscarDesconto();
    }

    // Setar paleta de cores marca
    const root = document.documentElement;
    root.style.setProperty('--cor', this.loja.paletaCor);

    // Calculando valor do carrinho
    this.calcularTotal();
    this.buscarSaldo();
  }

  itemsEvent() {
    let items: any = [];
    let opcionais = '';
    let obrigatorios = '';
    let preco = '';

    this.carrinho.forEach((item) => {
      this.dados_produto = item;
      this.adicionais_selecionado = this.filtroAdicionais(item.adicionais);

      item.opcionais.forEach((opc) => {
        if (opc.selecionado) {
          opcionais = opc.opcional;
        }
      });

      item.obrigatorios.forEach((obr) => {
        if (obr.selecionado) {
          obrigatorios = obr.opcional;
          preco = obr.preco.unitario.valor;
        }
      });

      items.push({
        item_list_id: item.categoriaId.toString(),
        item_list_name: item.categoria_nome,
        item_name: item.nome,
        item_id: item.cod,
        item_category: item.categoria_nome,
        item_category2: false,
        item_brand: this.loja.nome_rede,
        item_variant: obrigatorios,
        price: item.preco.unitario.valor,
        currency: 'BRL',
        quantity: item.qtd,
      });

      this.adicionais_selecionado.forEach((adicional) => {
        items.push({
          item_list_id: item.categoriaId.toString(),
          item_list_name: item.categoria_nome,
          item_name: adicional.nome,
          item_id: adicional.cod,
          item_category: item.categoria_nome,
          item_category2: adicional.itemCategory2,
          item_category3: item.cod,
          item_brand: this.loja.nome_rede,
          item_variant: '',
          price: adicional.preco.unitario.valor,
          quantity: adicional.qtd,
        });
      });
    });

    return items;
  }

  itemsRemove(produto: any) {
    let items = [];
    let opcionais = '';
    let obrigatorios = '';
    let preco = '';

    this.dados_produto = produto;
    this.adicionais_selecionado = this.filtroAdicionais(produto.adicionais);

    produto.opcionais.forEach((opc) => {
      if (opc.selecionado) {
        opcionais = opc.opcional;
      }
    });

    produto.obrigatorios.forEach((obr) => {
      if (obr.selecionado) {
        obrigatorios = obr.opcional;
        preco = obr.preco.unitario.valor;
      }
    });

    items.push({
      item_list_id: produto.categoriaId.toString(),
      item_list_name: produto.categoria_nome,
      item_name: produto.nome,
      item_id: produto.cod,
      item_category: produto.categoria_nome,
      item_category2: false,
      item_brand: this.loja.nome_rede,
      item_variant: obrigatorios,
      price: produto.preco.unitario.valor,
      currency: 'BRL',
      quantity: produto.qtd,
    });

    this.adicionais_selecionado.forEach((adicional) => {
      items.push({
        item_list_id: produto.categoriaId.toString(),
        item_list_name: produto.categoria_nome,
        item_id: adicional.cod,
        item_name: adicional.nome,
        item_category: produto.categoria_nome,
        item_category2: adicional.itemCategory2,
        item_category3: produto.cod,
        item_brand: this.loja.nome_rede,
        item_variant: '',
        price: adicional.preco.unitario.valor,
        quantity: adicional.qtd,
      });
    });

    return items;
  }

  async ngOnInit() {
    await this.platform.ready();

    if (this.platform.is('ios') || this.platform.is('iphone')) {
      this.utils.alteraStatusBar('escuro');
    }

    this.events.subscribe('pedido-finalizado', async () => {
      this.utils.alteraStatusBar('claro');
      await this.modalController.getTop();
      await this.modalController.dismiss(undefined, undefined, 'carrinho');

      this.utils.navegarPagina('detalhes-pedido');
    });
  }

  ionViewWillEnter() {
    this.sugestoes = this.utils.getStorage('sugestoes');

    let event = {
      event: 'view_cart',
      ecommerce: {
        affiliation: this.loja.nome,
        currency: 'BRL',
        value: parseFloat(this.total.toFixed(2)),
        shipping: this.loja.frete.valor,
        items: this.itemsEvent(),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    if (this.usuario.cashback) {
      this.usar_cashback = true;
      if (this.usuario.cashback >= this.total - this.frete) {
        this.cashback = this.total - this.frete;
        this.total = this.frete;
      } else {
        this.cashback = this.usuario.cashback;
        this.total -= this.cashback;
      }
    } else {
      this.usar_cashback = false;
    }

    this.inicializarPagina();
  }

  voltar() {
    this.utils.alteraStatusBar('claro');
    this.events.publish('carrinho-numero', Date.now());
    this.modalService.fecharModal();
  }

  calcularTotal() {
    this.subtotal = this.carrinho.reduce(
      (total: number, item: any) => total + item.preco.total.valor,
      0
    );

    if (this.desconto.cupomId > 0 && this.desconto.tipo === 'porcentagem') {
      this.aplicarPorcentagem(
        this.desconto.porcentagem,
        this.desconto.codProduto,
        this.desconto.categoriaId
      );
    } else {
      this.carrinho.forEach((item) => {
        item.desconto.unitario.valor = item.preco.unitario.valor;
        item.desconto.total.valor = item.preco.total.valor;

        if (item.adicionais.length > 0) {
          item.adicionais.forEach((adicional: any) => {
            if (adicional.qtd > 0) {
              adicional.desconto.unitario.valor =
                adicional.preco.unitario.valor;
              adicional.desconto.total.valor = adicional.preco.total.valor;
            }
          });
        }
      });
    }

    this.descontoFrete = (this.loja.frete.valor * this.desconto.frete) / 100;

    let totalFrete = (this.frete =
      this.modalidade.codigo === 'DLV'
        ? this.loja.frete.valor -
        (this.loja.frete.valor * this.desconto.frete) / 100
        : 0);
    let subtotalCarrinho = this.subtotal - this.desconto.valor;

    if (subtotalCarrinho < 0) {
      subtotalCarrinho = 0;
    }

    let cashback = 0;
    if (this.cashback > 0) {
      if (
        this.usuario.cashback == this.cashback &&
        this.cashback < subtotalCarrinho
      ) {
        cashback = this.cashback;
      } else {
        if (this.usuario.cashback >= subtotalCarrinho) {
          cashback = this.cashback = subtotalCarrinho;
        } else {
          cashback = this.cashback = this.usuario.cashback;
        }
      }
    }

    this.total = subtotalCarrinho - cashback + totalFrete;
    this.total_sem_cashback = subtotalCarrinho + totalFrete;
  }

  filtroAdicionais(adicionais: Array<any>) {
    return adicionais.filter((item: any) => item.qtd > 0);
  }

  filtroOpcionais(opcionais: Array<any>) {
    return opcionais.filter((item: any) => item.selecionado);
  }

  filtroSugestoes(sugestoes: Array<any>) {
    return sugestoes.filter(
      (sugestao: any) =>
        this.carrinho.findIndex((item) => sugestao.cod === item.cod) === -1
    );
  }

  erroImagem(img: any, imagemURL: string) {
    img.src = imagemURL;
  }

  async selecionarProduto(produto: any) {
    this.utils.alteraStatusBar('claro');
    // await this.modalController.getTop();
    this.utils.setStorage('produto', produto);
    this.modalService.modalProdutoBrowser('', () => {
      this.carrinho = this.utils.getStorage('carrinho');
      this.events.publish('atualizar-carrinho');
    });
    this.modalController.dismiss('', 'sugestao', 'carrinho');
  }

  calcularCashback(event) {
    if (event) {
      if (this.usuario.cashback >= this.total - this.frete) {
        this.cashback = this.total - this.frete;
        this.total = this.frete;
      } else {
        this.cashback = this.usuario.cashback;
        this.total -= this.cashback;
      }
    } else {
      this.total += this.cashback;
      this.cashback = 0;
    }
  }

  editarProduto(index: number) {
    if (this.carrinho[index].tipo === 'Individual') {
      this.utils.setStorage('carrinho-index', index);
      this.modalService.modalEditarProduto().then(() => {
        this.carrinho = this.utils.getStorage('carrinho');
        this.calcularTotal();
      });
    }
  }

  async addItens() {
    this.utils.setStorage('modalidade', this.modalidade);
    this.utils.setStorage('loja', this.loja);

    switch (this.loja.marcaId) {
      case 24:
        this.utils.navegarPagina('/tabs/pokes-do-china-in-box');
        break;
      case 21:
        this.utils.navegarPagina('/tabs/gendai');
        break;
      case 17:
        this.utils.navegarPagina('/tabs/gokei');
        break;
      case 16:
        this.utils.navegarPagina('/tabs/owan');
        break;
      case 3:
        this.utils.navegarPagina('/tabs/koni');
        break;
      default:
        this.utils.navegarPagina('/tabs/home');
        break;
    }
    await this.modalService.fecharModal();
  }

  async limparCarrinho(evento: boolean) {
    if (evento) {
      const event = {
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'BRL',
          affiliation: this.loja.nome,
          items: this.itemsEvent(),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    }

    this.carrinho = [];

    if (this.carrinho.length === 0) {
      this.carrinhoService.limparCarrinho();
      this.utils.alteraStatusBar('claro');
      this.utils.removeStorage('qtdCarrinho');
      await this.modalService.fecharModal();
      this.events.publish('carrinho-numero', Date.now());
    }
  }

  async alterarProduto(tipo: string, i: number) {
    let produto = this.carrinho[i];

    if (tipo === 'add') {
      produto.qtd++;

      let event = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'BRL',
          affiliation: this.loja.nome,
          value: produto.preco.total.valor,
          items: this.itemsRemove(produto),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    } else {
      const event = {
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'BRL',
          affiliation: this.loja.nome,
          items: this.itemsRemove(produto),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);

      if (produto.qtd === 1) {
        this.carrinho.splice(i, 1);
        if (this.carrinho.length === 0) {
          this.limparCarrinho(false);
          return;
        } else {
          this.utils.exibirToast('Produto removido com sucesso da sacola!');
        }
      }

      produto.qtd--;
    }

    let totalAdicionais = 0;

    const adicionais: Array<any> = produto.adicionais;

    if (adicionais.length > 0) {
      totalAdicionais = adicionais.reduce(
        (total, item) => total + item.preco.total.valor,
        0
      );
    }

    produto.preco.total.valor =
      produto.qtd * produto.preco.unitario.valor +
      produto.qtd * produto.preco.opcional.valor;

    if(this.cupom && this.utils.getStorage('cupomDesconto') != null){
      this.buscarDesconto();
    }

    this.calcularTotal();

    this.utils.setStorage('carrinho', this.carrinho);

    this.carrinhoService.calcular(this.carrinho);
  }

  async buscarDesconto() {
    if (this.cupom === '') {
      this.utils.exibirToast('O cupom é obrigatório!', 'erro');
      return;
    }

    const req = {
      cupom: this.cupom,
      lojaId: this.loja.id,
      carrinho: this.carrinho,
      modalidade: this.modalidade.codigo,
    };

    this.loading = true;
    const res = await this.api.post('cupom', req);

    this.loading = false;

    if (res.status === 'success') {
      this.desconto = {
        cupom: this.cupom,
        cupomId: res.data.id,
        tipo: res.data.tipo,
        frete: res.data.frete,
        categoriaId: res.data.categoriaId,
        codProduto: res.data.codProduto,
      };

      this.frete =
        this.desconto.frete > 0
          ? (this.loja.frete.valor * this.desconto.frete) / 100
          : 0;

      if (this.desconto.tipo === 'fixo') {
        this.desconto.valor = res.data.desconto.valor;
        this.desconto.porcentagem = 0;
      } else {
        this.desconto.porcentagem = res.data.desconto.valor;
      }

      this.utils.setStorage('cupomDesconto', this.cupom);
      this.opcaoRemover = true;
      this.calcularTotal();
      this.isValid = true;
    } else {
      this.utils.exibirToast(res.message, 'erro');
      this.isValid = false;

      this.desconto = {
        cupom: '',
        cupomId: 0,
        valor: 0,
        tipo: '',
        porcentagem: 0,
        frete: 0,
      };
      this.utils.removeStorage('cupomDesconto');
      this.utils.removeStorage('desconto');

      this.calcularTotal();
    }

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'addCoupon',
      coupon: this.cupom,
      itsValid: this.isValid,
      affiliation: this.loja.nome,
    });
  }

  removerCupom() {
    this.cupom = '';

    this.desconto = {
      cupom: '',
      cupomId: 0,
      valor: 0,
      tipo: '',
      porcentagem: 0,
      frete: 0,
    };
    this.utils.removeStorage('cupomDesconto');
    this.utils.removeStorage('desconto');

    this.calcularTotal();
  }

  aplicarPorcentagem(
    porcentagem: number,
    codProduto: string = null,
    categoriaId: number = 0
  ) {
    let totalDesconto: number = 0;

    this.carrinho.forEach((item) => {
      if (codProduto !== null && codProduto != '0') {
        if (item.cod !== codProduto) {
          return;
        }
      }

      if (categoriaId > 0) {
        if (item.categoriaId !== categoriaId) {
          return;
        }
      }

      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * porcentagem) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * porcentagem) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * porcentagem) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * porcentagem) / 100;
          }
        });
      }

      if (item.tipo === 'Combo') {
        this.aplicarPorcentagemCombo(
          item.produtos,
          porcentagem,
          codProduto,
          categoriaId
        );
      }

      totalDesconto +=
        item.preco.total.valor - Number(item.desconto.total.valor.toFixed(2));
    });

    this.desconto.valor = totalDesconto;
  }

  aplicarPorcentagemCombo(
    produtos: Array<any>,
    porcentagem: number,
    codProduto: string = null,
    categoriaId: number = 0
  ) {
    produtos.forEach((item) => {
      if (codProduto !== null && codProduto != '0') {
        if (item.cod !== codProduto) {
          return;
        }
      }

      if (categoriaId > 0) {
        if (item.categoriaId !== categoriaId) {
          return;
        }
      }

      item.desconto.unitario.valor =
        item.preco.unitario.valor -
        (item.preco.unitario.valor * porcentagem) / 100;
      item.desconto.total.valor =
        item.preco.total.valor - (item.preco.total.valor * porcentagem) / 100;

      if (item.adicionais.length > 0) {
        item.adicionais.forEach((adicional: any) => {
          if (adicional.qtd > 0) {
            adicional.desconto.unitario.valor =
              adicional.preco.unitario.valor -
              (adicional.preco.unitario.valor * porcentagem) / 100;
            adicional.desconto.total.valor =
              adicional.preco.total.valor -
              (adicional.preco.total.valor * porcentagem) / 100;
          }
        });
      }
    });
  }

  async buscarSaldo() {
    if (this.utils.getStorage('usuario') != null) {
      const res = await this.api.get('fidelidade_saldo', {
        jwt: this.utils.getStorage('usuario').jwt,
        marcaId: this.utils.getStorage('loja').marcaId
      });

      if (res.status == 'success') {
        this.usuario.cashback = res.data.valor;
        this.usuario.percentual_valor = res.data.percentual_valor;

        this.utils.setStorage('usuario', this.usuario);
        this.events.publish('atualizar-cashback');
      }
    } else {
      this.utils.exibirToast('Faça login para continuar!', 'erro');
      this.utils.navegarPagina('login');
    }
  }

  finalizar() {
    if (this.loja.valor_minimo?.valor > this.subtotal && this.modalidade.codigo === 'DLV') {
      this.utils.exibirToast('O pedido mínimo para essa loja é de ' + this.loja.valor_minimo.texto, 'erro');
      return
    }

    this.utils.setStorage('cashback', this.cashback);
    this.utils.setStorage('carrinho', this.carrinho);
    this.utils.setStorage('desconto', this.desconto);
    this.utils.navegarPagina('finalizar-pedido-browser');
    this.modalController.dismiss();

    let event = {
      event: 'begin_checkout',
      ecommerce: {
        affiliation: this.loja.nome,
        currency: 'BRL',
        value: parseFloat(this.total.toFixed(2)),
        ...(this.modalidade.codigo === 'DLV'
          ? { shipping: this.loja.frete.valor }
          : ''),
        coupon: this.cupom,
        items: this.itemsEvent(),
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  fechar() {
    this.usuarioService.atualizarUsuario(this.usuario);
    this.events.publish('carrinho-numero', this.carrinho);
    this.modalController.dismiss();
  }
}
