import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private semana: object;
  private dias: object;
  private meses: object;
  private path = environment.apiUrl

  constructor(
    private _http: HttpClient
  ) {

    this.semana = {
      0 : '8uRdZ',
      1 : 'lsCZk',
      2 : 'XJKTk',
      3 : 'qchG5',
      4 : 'CQpzD',
      5 : '89dm1',
      6 : 'X7QiP'
    };

    this.dias = {
      1 : 'heLu1',
      2 : '6wPja',
      3 : 'SLQOb',
      4 : 'uCEdx',
      5 : 'uJz5O',
      6 : 'jo6kk',
      7 : 'JKrBd',
      8 : 'TcCBj',
      9 : 'xyzBh',
      10 : 'iMXnB',
      11 : 'lSL7Z',
      12 : 'qWmdR',
      13 : 'bWqOE',
      14 : '73aqS',
      15 : 'ztCjR',
      16 : 'gljRu',
      17 : 'M51li',
      18 : 'Db1IU',
      19 : '3UhKm',
      20 : 'Dmhlk',
      21 : 'KjFjC',
      22 : 'CKEZ8',
      23 : 'ylKnY',
      24 : 'KTw3D',
      25 : 'u9N7e',
      26 : 'f8irj',
      27 : 'sDCmK',
      28 : 'UX6kF',
      29 : '3GWqk',
      30 : 'zexfu',
      31 : 'E8rbh'
    };

    this.meses = {
      1 : 'B84Pa',
      2 : 'bABa9',
      3 : '1M3Uh',
      4 : 'iM9x2',
      5 : 'tcOq1',
      6 : 'QRiOI',
      7 : 'f9a21',
      8 : 'XoIWo',
      9 : 'NFXDf',
      10 : 'ADcab',
      11 : 'Sn4oq',
      12 : 'J5mJC'
    };

  }

  public gerarEndpoint() {
    const hoje = new Date();
    return `${this.semana[hoje.getDay()]}.${this.dias[hoje.getDate()]}.${this.meses[hoje.getMonth() + 1]}`;
  }

  public signOutExternal = () => {
    localStorage.removeItem("token");
    console.log("token deleted")
  }

  LoginWithGoogle(credentials: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    return this._http.post(this.path + "LoginWithGoogle", JSON.stringify(credentials), { headers: header });
  }
}
