import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-pagamento-pix',
  templateUrl: './pagamento-pix.page.html',
  styleUrls: ['./pagamento-pix.page.scss'],
})
export class PagamentoPixPage implements OnInit {

  public cor: string;
  public pedido: any;

  constructor(
    private utils: UtilsService,
    private clipboard: Clipboard,
    private modals: ModalsService
  ) {
    this.pedido = this.utils.getStorage('pedido');
    var root = document.documentElement;
    this.cor = this.pedido.loja.paletaCor;
    root.style.setProperty('--cor', this.cor);
  }

  ngOnInit() {
  }

  async copiarCodigo() {
    await this.clipboard.copy(this.pedido.codigoPix)
      .then(() => this.utils.exibirToast('Código copiado com sucesso!'))
      .catch(() => this.utils.exibirToast('Houve um problema ao copiar o texto!', 'erro'));
  }

  fechar() {
    this.modals.fecharModal();
  }

}
