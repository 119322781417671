import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  constructor(
    private firebaseAnalytics: FirebaseAnalytics
  ) { }

  public async criarEvento(topico: string, parametros: any = '') {

    try {
      await this.firebaseAnalytics.logEvent(topico, parametros)
        .then(() => console.log('Registrou evento!!!'))
        .catch((erro) => console.log(erro, topico));
    } catch (error) {
      console.log(error);
    }

  }

}
