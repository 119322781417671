import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-confirma-edicao-restaurante-web',
  templateUrl: './confirma-edicao-restaurante-web.page.html',
  styleUrls: ['./confirma-edicao-restaurante-web.page.scss'],
})
export class ConfirmaEdicaoRestauranteWebPage implements OnInit {

  constructor(
    private utils: UtilsService,
    private events: EventsService,
    private modalController: ModalController,
    private modals: ModalsService,
  ) { }

  ngOnInit() {
  }

  sim() {

    this.utils.setStorage('edicao-restaurante', 'sim');
    this.utils.removeStorage('tipo-compra-carrinho');
    this.utils.removeStorage('periodo-agendado-carrinho');
    this.utils.removeStorage('horario-agendado-carrinho');
    this.utils.removeStorage('carrinho');
    this.utils.removeStorage('modalidade_carrinho');
    this.utils.removeStorage('loja-fora-area');

    this.events.publish('fechar-modal');
    this.events.publish('atualizar-tabs');
    this.events.publish('carrinho-numero', Date.now());

    this.modalController.dismiss('sim');

  }

  nao() {
    this.modals.fecharModal();
  }


}
