import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '../analytics/analytics.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  public bannersSubject = new BehaviorSubject<any>(null);
  banners$: Observable<any> = this.bannersSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService
  ) {}

  public async buscarBanners(marcaId: number | string | null, local: string): Promise<any[]> | null {
    const req = {
      marcaId: marcaId,
      local: local,
    };

    const res = await this.apiService.get('banners', req);

    if (res.status === 'success') {
      if (res.data.length > 0) {
        const banners = res.data;
        const event = this.analyticsService.view_promotion(banners);
        this.analyticsService.registrarEvento(event);
        this.bannersSubject.next(banners);
        return banners;
      } else {
        this.bannersSubject.next(null);
        return null;
      }
    } else {
      this.bannersSubject.next(null);
      return null;
    }
  }
}
