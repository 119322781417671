import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from './../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Endereco } from 'src/app/interfaces/endereco';


@Component({
  selector: 'app-browser-confirma-modalidade',
  templateUrl: './browser-confirma-modalidade.page.html',
  styleUrls: ['./browser-confirma-modalidade.page.scss'],
})
export class BrowserConfirmaModalidadePage implements OnInit {
  public endereco: Endereco;
  public loja: any;
  public modalidade: any
  public sacola: boolean = false;
  public totalSacola: any = {};
  public qtdCarrinho: number = 0;
  public corBadge: string;

  constructor(
    public modalController: ModalController,
    public utils: UtilsService,
    private modalService: ModalsService,
  ) { }

  ngOnInit() {
    this.endereco = this.utils.getStorage('enderecos')[0];
    this.modalidade = this.utils.getStorage('modalidade');
    this.loja = this.utils.getStorage('loja');

  }

  fechar() {
    this.modalController.dismiss();
  }

  carregarCarrinho() {

    var root = document.documentElement;
    this.corBadge = this.utils.getStorage('loja_carrinho') ? this.utils.getStorage('loja_carrinho').paletaCor : '';
    root.style.setProperty('--corBadge', this.corBadge);

    this.utils.removeStorage('tipo-compra');
    this.utils.removeStorage('periodo-agendado');
    this.utils.removeStorage('horario-agendado');

    this.totalSacola = {
      valor: 0,
      texto: 'R$ 0,00'
    };

    if (this.utils.getStorage('carrinho') != null) {

      const carrinho = this.utils.getStorage('carrinho');

      carrinho.forEach((item: any) => {
        item.preco.unitario.valor = item.preco.total.valor;
        this.totalSacola.valor += item.preco.total.valor;
        this.totalSacola.texto = this.utils.floatToReal(this.totalSacola.valor);
      });

      this.sacola = true;
      this.qtdCarrinho = carrinho.reduce((total: number, item: any) => total + item.qtd, 0);

    } else {
      this.sacola = false;
    }

  }

  trocarModalidade() {
    this.modalService.modalSelecionaModalidadeBrowser()
  }

  confirmar() {
    this.utils.setStorage('endereco', this.endereco);
    this.modalController.dismiss();
    this.utils.navegarPagina('tabs/home');
    this.modalService.modalCarrinhoBrowser().then().then((res) => {
      this.carregarCarrinho();
    });
    this.utils.exibirToast('Produto adicionado com sucesso!');
  }

  // async trocarModalidade() {
  //   this.modalService.modalSelecionaModalidade()
  // }

}
