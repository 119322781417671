import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILojaRetirada } from 'src/app/modals/fluxo-retirada/escolher-restaurante-retirada/escolher-restaurante-retirada.page';

@Component({
  selector: 'app-loja-retirada',
  templateUrl: './loja-retirada.component.html',
  styleUrls: ['./loja-retirada.component.scss'],
})
export class LojaRetiradaComponent implements OnInit {
  @Input() loja: ILojaRetirada;
  @Input() classe: string;
  @Output() selecionar: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
