import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BannersService } from 'src/app/services/banners/banners.service';
import { CarrinhoService } from 'src/app/services/carrinho/carrinho.service';
import { CelularService } from 'src/app/services/celular/celular.service';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { LojaService } from 'src/app/services/loja/loja.service';
import { MetatagService } from 'src/app/services/seo/metatag.service';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ISegmentButton } from 'src/app/componentes/segment/segment.component';
import { IonContent, IonSlides } from '@ionic/angular';
import { Location } from '@angular/common';
import { RedesService } from 'src/app/services/redes/redes.service';
import { MudarVisibilidadeService } from 'src/app/services/mudarVisibilidade/mudar-visibilidade.service';

@Component({
  selector: 'app-cardapio',
  templateUrl: './cardapio.component.html',
  styleUrls: ['./cardapio.component.scss'],
})
export class CardapioComponent implements OnInit {
  public carregando$: Observable<boolean> = this.lojaService.carregando$;
  public segment: ISegmentButton;

  @ViewChild('content', { static: true }) content: IonContent;
  @ViewChild('slideCategoria') slideCategoria: IonSlides;
  @ViewChild('sentinela') sentinela: ElementRef;

  @Input() redeId: number;

  public categoria_selecionada: number;
  public categoriasOpt = {
    initialSlide: 0,
    slidesPerView: 'auto',
    spaceBetween: 10,
    roundLengths: true,
    navigation: true,
  };

  public loja: any;
  public banners: any[];
  public cardapio: any[];
  public cardapio_filtrado: any[];
  public dados_carrinho: any;
  public filtro: string;
  public celular: boolean;
  private endereco: any[] | null = null;
  private modalidade: any;
  public sem_modalidade;
  private usuario: any;
  private jwt: string = '';
  public modalidadeAtiva: string;
  public meta: string;
  public data: string;
  private redeIdSub: Subscription;
  private observerService: boolean = false;

  constructor(
    private apiService: ApiService,
    private analyticsService: AnalyticsService,
    private bannersService: BannersService,
    private carrinhoService: CarrinhoService,
    private celularService: CelularService,
    private enderecoService: EnderecoService,
    private lojaService: LojaService,
    private location: Location,
    private metatagService: MetatagService,
    private modalidadeService: ModalidadeService,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private redesService: RedesService,
    private mudarVisibilidadeService: MudarVisibilidadeService
  ) {
    this.modalidadeService.semModalidade$.subscribe((sem_modalidade) => {
      this.sem_modalidade = sem_modalidade;
    });

    this.enderecoService.enderecos$.subscribe((enderecos) => {
      if (enderecos !== null && enderecos.length > 0)
        this.endereco = enderecos[0];
      else this.endereco = null;

      if (this.jwt)
        this.lojaService.buscarLoja(
          this.endereco,
          this.modalidade.codigo,
          this.redeId
        );


    });

    this.route.params.subscribe((params) => {
      this.jwt = params['jwt'];

      if (this.jwt)
        this.login(this.jwt).finally(() => {
          const url_atual = this.location.path();
          const remover_jwt = this.removeJwtFromUrl(url_atual);
          this.location.replaceState(remover_jwt);
        });
    });

    this.carrinhoService.dadosCarrinho$.subscribe(
      (dadosCarrinho) => (this.dados_carrinho = dadosCarrinho)
    );

    this.celularService.celular$.subscribe(
      (celular) => (this.celular = celular)
    );

    this.lojaService.loja$.subscribe((loja) => (this.loja = loja));

    this.bannersService.banners$.subscribe((banners) => {
      this.banners = banners;

      if (banners !== null) {
        if (this.banners.length == 0) this.banners = null;
      }
    });

    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );

    this.modalidadeService.modalidadesDisponiveis$.subscribe(
      (modalidadesDisponiveis) => {
        if (modalidadesDisponiveis !== null) {
          this.segment = modalidadesDisponiveis;
        } else {
          this.segment = null;
        }
      }
    );

    this.getCardapio();
  }

  public async ngOnInit() {
    this.redeIdSub = this.redesService.redeId$.subscribe(async (redeId) => {
      this.redeId = redeId;
      if (
        this.jwt !== '' &&
        this.redeId != 0 &&
        this.endereco != null &&
        this.modalidade != null
      ) {
        await this.lojaService.buscarLoja(
          this.endereco,
          this.modalidade.codigo,
          this.redeId
        );
        await this.lojaService.verificarFidelidadeNestaMarca(this.redeId);
      }
    });

    this.setModalidade();

    switch (this.redeId) {
      case 24:
        this.meta = 'Pokes do china | Fome de Q';
        this.data = '';
      case 21:
        this.meta = 'Fome de Q | Gendai o Melhor Delivery de Japa!';
        this.data = 'Desfrute do melhor da culin�ria japonesa perto de voc� com o Gendai. Pe�a agora pelo Fome de Q e delicie-se com uma variedade de Pokes, Temakis, Sushis e mais.';
      case 17:
        this.meta = 'Fome de Q | Descubra Gokei, comida japonesa de qualidade!';
        this.data = 'Procurando um delivery de comida japonesa? Pe�a j� no Gokei! Delicie-se com Shimeji, Sunomono, Rolinho Primavera, Temaki Empanado, Temaki Salm�o Grelhado e mais.';
      case 3:
        this.meta = 'Fome de Q | Koni o Melhor Restaurante Japon�s';
        this.data = 'Compre no melhor restaurante de comida japonesa! Pe�a agora no Fome de Q e aproveite Uramaki, Sashimi, Harumaki, Pokes e mais.';
    }

    this.metatagService.setMetaData(this.meta, this.data);
  }

  ngOnDestroy() {
    this.redeIdSub.unsubscribe();
  }

  public ionViewWillEnter() {
    switch (this.redeId) {
      case 24:
        this.meta = 'Pokes do china | Fome de Q';
        this.data = '';
      case 21:
        this.meta = 'Fome de Q | Gendai o Melhor Delivery de Japa!';
        this.data = 'Desfrute do melhor da culin�ria japonesa perto de voc� com o Gendai. Pe�a agora pelo Fome de Q e delicie-se com uma variedade de Pokes, Temakis, Sushis e mais.';
      case 17:
        this.meta = 'Fome de Q | Descubra Gokei, comida japonesa de qualidade!';
        this.data = 'Procurando um delivery de comida japonesa? Pe�a j� no Gokei! Delicie-se com Shimeji, Sunomono, Rolinho Primavera, Temaki Empanado, Temaki Salm�o Grelhado e mais.';
      case 3:
        this.meta = 'Fome de Q | Koni o Melhor Restaurante Japon�s';
        this.data = 'Compre no melhor restaurante de comida japonesa! Pe�a agora no Fome de Q e aproveite Uramaki, Sashimi, Harumaki, Pokes e mais.';
    }

    this.metatagService.setMetaData(this.meta, this.data);
  }

  public ionViewDidLeave() {
    this.metatagService.removeMetaTag();
  }

  private async login(jwt: string) {
    const req = {
      jwt: jwt,
    };

    const res = await this.apiService.post('login_institucional', req);

    if (res.status === 'success') {
      const usuario = res.data;

      this.loginInstitucional(usuario);
    } else {
      this.loginInstitucional(null);
    }
  }

  private loginInstitucional(usuario: any): void {
    if (usuario !== null) {
      this.usuarioService.atualizarUsuario(usuario);
      this.carrinhoService.limparCarrinho();

      this.enderecoService.limparDadosArmazenados();
      this.enderecoService.atualizarListaEnderecos(usuario.enderecos);

      this.lojaService.buscarLoja(
        this.endereco,
        this.modalidade.codigo,
        this.redeId
      );
    }
  }

  private setModalidade(): void {
    if (this.utilsService.getStorage('modalidade') == null) {
      this.modalidade = this.modalidadeService.atualizarModalidade('DLV');
    } else {
      this.modalidade = this.utilsService.getStorage('modalidade');
    }
  }

  private getCardapio(): void {
    this.lojaService.cardapio$.subscribe((cardapio) => {
      this.cardapio = cardapio;
      this.cardapio_filtrado = cardapio;

      if (this.cardapio_filtrado) {
        this.cardapio_filtrado = this.redoObject(this.cardapio_filtrado);

        this.cardapio_filtrado.map((cardapio) => {
          const event = this.analyticsService.view_item_list(
            cardapio,
            this.loja
          );

          this.analyticsService.registrarEvento(event);
        });
      }
    });
  }

  public onChange(event: any): void {
    this.filtro = this.utilsService.validUri(event.target.value);
    this.filtrar(this.filtro);
  }

  public filtrar(event: any): void {
    const filtro = this.filtro.toLowerCase();

    this.cardapio_filtrado = this.cardapio
      .map((item: any) => {
        const produtos_filtrados = item.cardapio.filter((produto: any) => {
          const nome_produto = this.utilsService.validUri(produto.produto);
          const descricao_produto = this.utilsService.validUri(
            produto.descricao
          );
          const categoria_produto = this.utilsService.validUri(item.categoria);
          return (
            nome_produto.includes(filtro) ||
            descricao_produto.includes(filtro) ||
            categoria_produto.includes(filtro)
          );
        });

        return { ...item, cardapio: produtos_filtrados };
      })
      .filter((categoria: any) => categoria.cardapio.length > 0);

    this.cardapio_filtrado = this.redoObject(this.cardapio_filtrado);
  }

  public limpar(): void {
    this.filtro = '';
    this.cardapio_filtrado = this.redoObject(this.cardapio);
    this.utilsService.scroll('', 0);
  }

  public scrollEnd(): void {
    this.content.getScrollElement().then((scrollElement: HTMLElement) => {
      const scrollTop = scrollElement.scrollTop;
      const offsetTop = this.getViewportWidthInPixels(15);

      const categorias = document.querySelectorAll('.produtos-categoria');

      categorias.forEach(async (categoria: HTMLElement, index: number) => {
        if (scrollTop >= categoria.offsetTop - offsetTop) {
          this.slideCategoria.slideTo(index);
          this.categoria_selecionada = index;
        }
      });

      if (scrollTop >= 140) {
        this.mudarVisibilidadeService.changeVisibility(true);
      } else if (scrollTop < 80) {
        this.mudarVisibilidadeService.changeVisibility(false);
      }
    });
  }

  public clickScroll(index: number): void {
    const offsetTop = this.getViewportWidthInPixels(10);

    const categoria = document.getElementById(`${index}`).offsetTop;
    this.content.scrollToPoint(0, categoria - offsetTop, 500);
  }

  public slide(elemento: IonSlides | IonContent, metodo: string): void {
    switch (metodo) {
      case 'next':
        (elemento as IonSlides).slideNext();
        break;
      case 'previous':
        (elemento as IonSlides).slidePrev();
        break;
      case 'top':
        this.content.scrollToTop(500);
        break;
      default:
        break;
    }
  }

  public irPara(
    pagina: string,
    produto: any = null,
    categoria: any = null,
    carrinho: any = null
  ): void {
    if (produto !== null) {
      this.selecionarProduto(produto, categoria);
    }

    if (carrinho !== null && this.carrinhoVazio()) {
      this.utilsService.exibirToast('Sua sacola est� vazia :(', 'erro');
      return;
    }

    if (!this.celular) {
      if (produto !== null) {
        this.modalsService.modalProdutoBrowser(!this.celular, '', this.redeId);
      }

      if (carrinho !== null) {
        this.modalsService.modalCarrinhoBrowser();
      }

      return;
    }

    this.utilsService.navegarPagina(pagina);
  }

  public navegar(caminho: string) {
    this.utilsService.navegarPagina(caminho);
  }

  private carrinhoVazio(): boolean {
    return this.dados_carrinho.qtd === 0 || this.dados_carrinho.qtd === null;
  }

  private selecionarProduto(produto: any, categoria: any): void {
    // this.utils.setStorage('sugestoes-loja', this.sugestoes);
    this.utilsService.setStorage('produto', produto);

    const event = this.analyticsService.select_item(
      produto,
      categoria,
      this.loja
    );

    this.analyticsService.registrarEvento(event);
  }

  private getViewportWidthInPixels(vw: number): number {
    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    return (viewportWidth * vw) / 100;
  }

  private removeJwtFromUrl(url: string): string {
    const regex = /\/(gendai|koni|gokei)\/([^\/]+)/;
    return url.replace(regex, '/$1');
  }

  private redoObject(object: any): any[] {
    const array = object.map((element: any) => {
      const object = {
        id: element.id,
        categoria: element.categoria,
        cardapio: element.cardapio.map((item: any) => ({
          cardapioId: item.cardapioId,
          categoriaId: item.categoriaId,
          categoria_nome: item.categoria_nome,
          cod: item.cod,
          combo: item.combo,
          descricao: item.descricao,
          id: item.id,
          alt_produto: item.alt_produto,
          foto: item.imagem,
          imagem: item.imagem,
          marcaId: item.marcaId,
          observacoes: item.observacoes,
          preco: item.preco,
          preco_obs: item.preco_obs,
          preco_apartir: item.preco_partir,
          titulo: item.produto,
          produto: item.produto,
        })),
      };

      return object;
    });

    return array;
  }

  public async alterarStatus(event: any): Promise<void> {
    this.modalidadeService.atualizarModalidade(event.target.value);
    if (event.target.value != 'TTA') {
      this.modalidadeAtiva = event.target.value;
      console.log('REDE', this.redeId);

      this.lojaService
        .buscarLoja(this.endereco, event.target.value, this.redeId)
        .then((resp) => {
          if (resp == null) {
            this.modalidadeService.semModalidadeSubject.next(true);
          }
        });
    } else {
      this.modalidadeAtiva = 'TTA';
    }
  }
}
