import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';

export interface ISegmentButton {
  valor_padrao: string;
  opcoes: Array<IOpcoes>;
}

interface IOpcoes {
  valor: string;
  opcao: string;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
  public valor_padrao: string = 'DLV';

  @Input() segment_buttons: ISegmentButton;
  @Output() alterarOpcao: EventEmitter<void> = new EventEmitter<void>();

  constructor(private modalidadeService: ModalidadeService) {
    this.modalidadeService.modalidade$.subscribe(
      (modalidade) => (this.valor_padrao = modalidade.codigo)
    );
  }

  ngOnInit() {}

  public switch(ev: any): void {
    this.alterarOpcao.emit(ev);
  }
}
