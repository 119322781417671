import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MERCADOPAGO_TOKEN } from 'src/environments/environment';
declare var MercadoPago;

@Component({
  selector: 'app-meus-cartoes-mp-web',
  templateUrl: './meus-cartoes-mp-web.component.html',
  styleUrls: ['./meus-cartoes-mp-web.component.scss'],
})
export class MeusCartoesMpWebComponent implements OnInit {

  public cartoes: Array<any> = [];
  public usuario: any;
  public addcartao: boolean = false;

  public formulario: FormGroup;
  public controladores: Object;
  public botao: boolean = true;
  public loading: boolean = false;
  public loja: any = this.utils.getStorage('loja');

  public mp: any;

  constructor(
    private alertController: AlertController,
    private utils: UtilsService,
    private routerOutlet: IonRouterOutlet,
    private events: EventsService,
    private modalService: ModalsService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform
  ) {

    this.usuario = this.utils.getStorage('usuario');
    if (this.usuario != null) {
      this.cartoes = this.usuario.cartoes;
    }

    this.formulario = this.formBuilder.group({
      apelido: ['', Validators.required],
      // numero: ['', Validators.required],
      titular: ['', Validators.required],
      cpf: ['', Validators.required],
      // validade: ['', Validators.required],
      // cvv: ['', Validators.required]
    });

    this.controladores = {
      apelido: 'O apelido',
      // numero: 'O n�mero',
      titular: 'O titular',
      cpf: 'O CPF',
      // validade: 'A validade',
      // cvv: 'O CVV'
    };

  }

  ngOnInit() {
    this.events.subscribe('atualizar-cartoes', () => {
      this.usuario = this.utils.getStorage('usuario');
      this.cartoes = this.usuario.cartoes;
    });
  }

  ionViewWillEnter(){
    this.loja = this.utils.getStorage('loja');
   }

  async alertExcluirCartao(index: number) {
    this.utils.setStorage('index-cartao', index);

    const alert = await this.alertController.create({
      header: 'Excluir cart�o',
      message: 'Tem certeza que deseja excluir esse cart�o?',
      buttons: [
        {
          text: 'Mudei de ideia',
          role: 'cancel',
        },
        {
          text: 'Excluir cart�o',
          handler: async () => {
            this.excluirCartao();
          },
        },
      ],
    });

    return await alert.present();
    // this.modalService.modalExcluirCartaoWeb();
  }

  async excluirCartao() {
    let usuario = this.utils.getStorage('usuario');
    let cartoes: Array<any> = usuario.cartoes;

    const index = this.utils.getStorage('index-cartao');

    let cartao = cartoes[index];

    const req = {
      cartao_id: cartao.id,
    };

    this.loading = true;

    let res = null;

    if (this.loja.metodo_pagamento == 'braspag') {
      res = await this.api.delete('cartao_cielo', req);
    }else{
      res = await this.api.delete('cartao_mercadopago', req);
    }
    this.loading = false;

    if (res.status === 'success') {
      cartoes.splice(index, 1);

      usuario.cartoes = cartoes;
      this.utils.setStorage('usuario', usuario);

      this.utils.exibirToast(res.message);
      this.events.publish('atualizar-cartoes');
    } else {
      this.utils.exibirToast(res.message, 'erro');
    }
  }


  addCartao() {
    this.addcartao = true;

    setTimeout(() => {
      this.mp = new MercadoPago(MERCADOPAGO_TOKEN);

      const optMp = {
        height: '3vw',
        marginTop: '2vw',
        color: '#626264',
        border: '1px solid black',
        'border-radius': '10px',
        'placeholder-color': '#626264',
      };
      const optMpDuplo = {
        height: '8vw',
        marginTop: '5vw',
        color: '#626264',
        border: '1px solid black',
        'border-radius': '10px',
        'placeholder-color': '#626264',
      };

      const cardNumberElement = this.mp.fields
        .create('cardNumber', {
          placeholder: 'N�mero*',
          style: optMp,
        })
        .mount('cardNumber');

      const expirationDateElement = this.mp.fields
        .create('expirationDate', {
          placeholder: 'Validade*',
          style: optMpDuplo,
        })
        .mount('expirationDate');

      const securityCodeElement = this.mp.fields
        .create('securityCode', {
          placeholder: 'C�d. Seguran�a*',
          style: optMpDuplo,
        })
        .mount('securityCode');
    }, 1000);
  }

  voltarCartao() {
    this.addcartao = false;
  }

  async validarCartao(req) {
    let token;

    try {
      token = await this.mp.fields.createCardToken(req);
      return token;
    } catch (error) {
      this.utils.exibirToast(
        'Cart�o n�o autorizado pela operadora! Revise os dados e tente novamente.',
        'erro'
      );
    }
  }

  async cadastrar() {
    if (this.utils.validaForm(this.formulario, this.controladores)) {
      if (!this.utils.validaCPF(this.formulario.get('cpf').value)) {
        this.utils.exibirToast('CPF inv�lido!', 'erro');
        return;
      }

      // if (!this.utils.validaValidade(this.formulario.get('validade').value)) {
      //   this.utils.exibirToast('Data de validade expirada!', 'erro');
      //   return;
      // }

      let usuario = this.utils.getStorage('usuario');
      let cartoes: Array<any> = usuario.cartoes;

      if (cartoes.length === 5) {
        this.utils.exibirToast('Voc� s� pode cadastrar at� 5 cart�es!', 'erro');
        return;
      }

      const tokenReq = {
        cardholderName: this.formulario.get('titular').value,
        identificationType: 'CPF',
        identificationNumber: this.utils.removeChar(
          this.formulario.get('cpf').value
        ),
      };

      let req = {
        apelido: this.formulario.get('apelido').value,
        // numero: this.formulario.get('numero').value,
        titular: this.formulario.get('titular').value,
        cpf: this.formulario.get('cpf').value,
        // validade: this.formulario.get('validade').value,
        // cvv: this.formulario.get('cvv').value,
      };

      const token = await this.validarCartao(tokenReq);

      if (token != undefined) {
        this.loading = true;

        if (token.id != null || token.status == 'active') {
          req['token'] = token.id;
          req['final'] = token.last_four_digits;

          this.api.post('cartao', req).then((res: any) => {
            this.loading = false;

            if (res.status === 'success') {
              // this.formulario.reset();

              cartoes.unshift(res.data);

              usuario.cartoes = cartoes;
              this.utils.setStorage('usuario', usuario);

              this.utils.exibirToast(res.message);
              this.events.publish('atualizar-cartoes');
              setTimeout(() => {
                this.addcartao = false;
              }, 500);
            } else {
              this.utils.exibirToast(res.message, 'erro');
            }
          });
        } else {
          this.loading = false;
          this.utils.exibirToast(
            'Houve um problema ao cadastrar o cart�o!',
            'erro'
          );
        }
      }
    }
  }

}
