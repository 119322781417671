import { UtilsService } from 'src/app/services/utils.service';
import { Endereco } from 'src/app/interfaces/endereco';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { MeusEnderecosComponent } from 'src/app/componentes/meus-enderecos/meus-enderecos.component';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { ProgramaFidelidadePage } from 'src/app/modals/fidelidade/programa-fidelidade/programa-fidelidade.page';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.scss'],
})
export class TopoComponent implements OnInit {
  @Input() voltar: boolean = false;
  @Input() carrinho: boolean = false;
  @Input() busca: boolean = true;
  @Input() localizacao: boolean = true;
  @Input() mostrarEndereco: boolean = false;
  @Input() apresenta_logo: boolean = true;
  @Input() logo_marca: string = null;
  @Input() logo: boolean = false;
  @Input() marca: any;
  @Output() volta = new EventEmitter<any>();
  @Output() openCarrinho = new EventEmitter<any>();
  @Input() qtdCarrinho: number;
  @Input() home: boolean = false;

  public endereco: Endereco;
  public enderecos: any;
  public usuario: any;

  constructor(
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService,
    private enderecoService: EnderecoService
  ) {
    this.usuario = this.utils.getStorage('usuario');
    this.endereco = this.utils.getStorage('endereco');

    this.enderecoService.enderecos$.subscribe((enderecos) => {
      if (enderecos !== null && enderecos.length > 0)
        this.endereco = enderecos[0];
      else this.endereco = null;
    });
  }

  ngOnInit() {
    this.verificaCarrinho();

    this.events.subscribe('carrinho-numero', async () => {
      this.verificaCarrinho();
    });
  }

  ionViewWillEnter() {
    this.endereco = this.utils.getStorage('endereco');
  }

  irPara(pagina: string) {
    this.utils.navegarPaginaRoot(pagina);
  }

  verificaCarrinho() {
    this.qtdCarrinho = 0;
    if (this.utils.getStorage('carrinho') != null) {
      const carrinho: any = this.utils.getStorage('carrinho');
      const loja_carrinho = this.utils.getStorage('loja_carrinho');
      if (this.marca == loja_carrinho.marcaId) {
        this.qtdCarrinho = carrinho.length;
      }
    }
  }

  chamarFuncao() {
    this.volta.emit();
  }

  irHome() {
    this.utils.navegarPagina('/home');
  }

  irPerfil() {
    this.usuario = this.utils.getStorage('usuario');

    this.events.publish('atualizar-perfil', { update: true });

    this.utils.navegarPagina('/tabs/perfil');
  }

  public navegar(caminho: string) {
    this.utils.navegarPagina(caminho);
  }

  buscar() {
    this.modalService.modalBuscaRestaurantes();
  }

  openCart() {
    this.openCarrinho.emit();
  }

  public abrirModal(id: string, cssClass: string): void {
    let elemento!: Function;

    if (id === 'modal-enderecos') elemento = MeusEnderecosComponent;

    this.modalService.abrirModal(id, cssClass, elemento);
  }

  public programaFidelidade(): void {
    this.modalService.abrirModal(
      'modal-programa-fidelidade',
      'modal-programa-fidelidade',
      ProgramaFidelidadePage,
      {},
      true,
      true
    );
  }
}
