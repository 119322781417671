import { EventsService } from './../../services/events.service';
import { ModalsService } from './../../services/modals/modals.service';
import { UtilsService } from './../../services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemAnalytics } from 'src/app/interfaces/ItemAnalytics';
import { CarrinhoService } from 'src/app/services/carrinho/carrinho.service';
import { EnderecoService } from 'src/app/services/endereco/endereco.service';

declare let dataLayer: any;
@Component({
  selector: 'app-modal-produto',
  templateUrl: './modal-produto.page.html',
  styleUrls: ['./modal-produto.page.scss'],
})
export class ModalProdutoPage implements OnInit {
  public produto: any;
  public loja: any;
  public isBrowser: Boolean;
  public observacoes: string;
  public adicionais_selecionados: any;
  public usuario: any;
  public rede_logo;
  public marca: any = {};
  public modalidade: any;
  public enderecos: any;
  public redeId: number;

  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private modalService: ModalsService,
    private events: EventsService,
    private enderecoService: EnderecoService,
    private carrinhoService: CarrinhoService
  ) { }

  ngOnInit() {
    this.enderecoService.enderecos$.subscribe((enderecos) => {
      if (enderecos !== null && enderecos !== undefined && enderecos.length !== 0) {
        this.enderecos = enderecos;
      } else {
        this.enderecos = null;
      }
    });

    this.produto = this.utils.getStorage('produto');
    this.loja = this.utils.getStorage('loja');
    this.usuario = this.utils.getStorage('usuario');

    if (this.usuario) {

      this.produto.qtd = 1;
      this.produto.preco.unitario =
        this.produto.preco_obs === 1 ? 0 : this.produto.preco.valor;
      this.produto.preco.valor_obrigatorio = 0;

      let event = {
        event: 'view_item',
        ecommerce: {
          affiliation: this.loja.nome !== null ? this.loja.nome : '',
          currency: 'BRL',
          value: this.produto.preco.valor,
          items: [
            {
              item_list_id: this.produto.categoriaId.toString(),
              item_list_name: this.produto.categoria_nome,
              item_id: this.produto.cod,
              item_name: this.produto.produto,
              item_brand: this.loja.nome_rede,
              item_category: this.produto.categoria_nome,
              item_category2: false,
              price: this.produto.preco.valor,
              currency: 'BRL',
              quantity: 1,
            },
          ],
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);

      this.rede_logo = this.utils.getStorage('loja');

      this.modalidade = this.utils.getStorage('modalidade').codigo;
    }
  }

  fechar() {
    this.modalController.dismiss();
  }

  irPara() {
    this.modalController.dismiss();
    this.utils.navegarPaginaRoot('login');
  }

  alteraProduto(tipo: string) {
    if (tipo === 'add') {
      this.produto.preco.valor_obrigatorio =
        this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd++;
    } else {
      if (this.produto.qtd === 1) {
        return;
      }
      this.produto.preco.valor_obrigatorio =
        this.produto.preco.valor_obrigatorio / this.produto.qtd;
      this.produto.qtd--;
    }

    let totalAdicionais = 0;
    this.produto.observacoes?.forEach((observacoes: any) => {
      if (observacoes.tipo_opcional == 'Adicional') {
        observacoes.adicionais.forEach((item) => {
          totalAdicionais += item.qtd * item.preco.valor;
        });
      }
    });

    this.produto.preco.valor_obrigatorio =
      this.produto.qtd * this.produto.preco.valor_obrigatorio;

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.preco.valor_obrigatorio +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  alteraAdicional(tipo: string, i: number, index: number) {
    let observacoes = this.produto.observacoes[index];
    let qtdSelecionada = 0;
    if (tipo === 'add') {
      observacoes.adicionais.forEach((adicional) => {
        qtdSelecionada += adicional.qtd;
      });

      if (observacoes.max < qtdSelecionada + 1) {
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida.', 'erro'
        );
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd++;
    } else {
      if (this.produto.observacoes[index].adicionais[i].qtd === 0) {
        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd--;
    }

    let totalAdicionais = 0;

    this.produto.observacoes.forEach((element) => {
      element.adicionais.forEach((item: any) => {
        totalAdicionais += item.qtd * item.preco.valor;
      });
    });

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.preco.valor_obrigatorio +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  async addSacola() {
    if (this.utils.getStorage('usuario') == null) {
      this.modalController.dismiss();
      this.utils.exibirToast(
        'Faça o login para adicionar um produto na sacola!',
        'erro'
      );
      this.utils.setStorage('pagina_anterior', 'cardapio');
      this.utils.navegarPaginaRoot('login');
      return;
    }

    const loja = this.utils.getStorage('loja');

    if (!loja.statusLoja) {
      this.utils.exibirToast('Restaurante fechado no momento!', 'erro');
      return;
    }

    let carrinho: Array<any> = [];

    if (this.utils.getStorage('carrinho') !== null) {
      carrinho = this.utils.getStorage('carrinho');
      this.events.publish('atualizar-carrinho', { limpar: false });
      this.events.publish('carrinho-numero');
    }

    let adicionais = [];
    let opcionais = [];
    let obrigatorios = [];
    let opcionais_gerais = [];
    let erros = 0;

    if (this.produto.observacoes != undefined) {
      this.produto.observacoes.forEach((observacoes: any, index) => {
        switch (observacoes.tipo_opcional) {
          case 'Adicional':
            let adicionalSelecionado = 0;

            observacoes.adicionais.forEach((adicional: any) => {
              adicionalSelecionado += adicional.qtd;
              adicionais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                itemCategory2:
                  observacoes.tipo_opcional == 'Obrigatório' ||
                    (observacoes.tipo_opcional == 'Adicional' &&
                      observacoes.min > 0) ||
                    observacoes.tipo_opcional == 'Obrigatório' ||
                    observacoes.tipo_opcional == 'Opcional'
                    ? false
                    : true,
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
              });

              opcionais_gerais.push({
                cod: adicional.cod,
                nome: adicional.opcional,
                tipo: 'adicional',
                categoriaId: observacoes.id_categoria,
                preco: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                itemCategory2:
                  observacoes.tipo_opcional == 'Obrigatório' ||
                    (observacoes.tipo_opcional == 'Adicional' &&
                      observacoes.min > 0) ||
                    observacoes.tipo_opcional == 'Obrigatório' ||
                    observacoes.tipo_opcional == 'Opcional'
                    ? false
                    : true,
                desconto: {
                  unitario: {
                    valor: adicional.preco.unitario,
                    texto: this.utils.floatToReal(adicional.preco.unitario),
                  },
                  total: {
                    valor: adicional.preco.unitario * adicional.qtd,
                    texto: this.utils.floatToReal(
                      adicional.preco.unitario * adicional.qtd
                    ),
                  },
                },
                qtd: adicional.qtd,
              });
            });
            if (adicionalSelecionado < observacoes.min) {
              this.produto.observacoes[index].sem_escolha = 'ativo';
              erros++;
            } else {
              this.produto.observacoes[index].sem_escolha = '';
            }
            break;

          case 'Opcional':
            let opcionalSelecionado = 0;

            if (observacoes.opcionais.length > 0) {
              observacoes.opcionais.forEach((item) => {
                if (item.selecionado) {
                  opcionalSelecionado++;
                }

                opcionais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'opcional',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                });
              });

              if (opcionalSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          case 'Obrigatório':
            let obrigatorioSelecionado = 0;

            if (observacoes.obrigatorios.length > 0) {
              observacoes.obrigatorios.forEach((item) => {
                if (item.selecionado) {
                  obrigatorioSelecionado++;
                }

                obrigatorios.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                });

                opcionais_gerais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  tipo: 'obrigatorio',
                  categoriaId: observacoes.id_categoria,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                  preco: {
                    unitario: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                    total: {
                      valor: item.preco.unitario,
                      texto: this.utils.floatToReal(item.preco.unitario),
                    },
                  },
                });
              });

              if (obrigatorioSelecionado == 0) {
                this.produto.observacoes[index].sem_escolha = 'ativo';
                erros++;
              } else {
                this.produto.observacoes[index].sem_escolha = '';
              }
            }
            break;

          default:
            break;
        }
      });
    }

    if (erros != 0) {
      this.utils.exibirToast('Por favor preencha todos os campos.');

      setTimeout(() => {
        const alert = document.querySelectorAll('.alert');
        const primeiroAlert = alert[0];

        primeiroAlert.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      return;
    }

    const produto = {
      tipo: 'Individual',
      categoriaId: this.produto.categoriaId,
      categoria_nome: this.produto.categoria_nome,
      cod: this.produto.cod,
      imagem: this.produto.imagem,
      nome: this.produto.produto,
      descricao: this.produto.descricao,
      observacao: this.observacoes,
      preco: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        opcional: {
          valor:
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
            this.produto.qtd,
          texto: this.utils.floatToReal(
            (this.produto.preco.valor -
              this.produto.preco.unitario * this.produto.qtd) /
            this.produto.qtd
          ),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      desconto: {
        unitario: {
          valor: this.produto.preco.unitario,
          texto: this.utils.floatToReal(this.produto.preco.unitario),
        },
        total: {
          valor: this.produto.preco.valor,
          texto: this.utils.floatToReal(this.produto.preco.valor),
        },
      },
      qtd: this.produto.qtd,
      adicionais: adicionais,
      obrigatorios: obrigatorios,
      opcionais: opcionais,
      marcaId: loja.marcaId,
      opcionais_gerais: opcionais_gerais,
      pesquisa: false,
    };

    if (this.utils.getStorage('carrinho') != null) {
      let carrinhoDiff = this.utils
        .getStorage('carrinho')
        .filter((itemCarrinho: any) => itemCarrinho.marcaId != loja.marcaId);

      if (carrinhoDiff.length > 0) {
        (await this.modalController.getTop()).dismiss();

        return this.modalService
          .modalConfirmacaoWeb({
            titulo: 'Ops, parece que temos restaurantes diferentes',
            texto:
              'Para continuar o seu pedido, vamos precisar limpar o carrinho anterior',
          })
          .then((res) => {
            if (res.data !== true)
              return this.modalService.modalProdutoBrowser();
            this.utils.limparCarrinho();
            this.atualizarCarrinho([], produto, loja);
            this.utils.navegarPagina('tabs/home');
          });
      }
    }

    this.atualizarCarrinho(carrinho, produto, loja);

    let item_variant;

    obrigatorios.filter((obrigatorio) => {
      if (obrigatorio.selecionado == true) {
        item_variant = obrigatorio.opcional;
      }
    });

    this.adicionais_selecionados = this.filtroAdicionais(adicionais);

    let event = {
      event: 'add_to_cart',
      ecommerce: {
        affiliation: loja.nome,
        currency: 'BRL',
        value: produto.preco.total.valor,
        items: [
          {
            item_list_id: produto.categoriaId.toString(),
            item_list_name: produto.categoria_nome,
            item_id: produto.cod,
            item_name: produto.nome,
            item_brand: loja.nome_rede,
            item_category: produto.categoria_nome,
            item_category2: false,
            item_variant: item_variant !== undefined ? item_variant : '',
            price: produto.preco.unitario.valor !== 0 ? produto.preco.unitario.valor : produto.preco.total.valor,
            currency: 'BRL',
            quantity: produto.qtd,
          },
        ],
      },
    };

    this.adicionais_selecionados.forEach((adicional) => {
      const items: ItemAnalytics = {
        item_list_id: produto.categoriaId.toString(),
        item_list_name: produto.categoria_nome,
        item_id: adicional.cod,
        item_name: adicional.nome,
        item_brand: loja.nome_rede,
        item_category: produto.categoria_nome,
        item_category2: adicional.itemCategory2,
        item_category3: produto.cod,
        item_variant: '',
        price: adicional.preco.unitario.valor,
        currency: 'BRL',
        quantity: adicional.qtd,
      };

      event.ecommerce.items.push(items);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    this.modalController.dismiss();

    switch (this.redeId) {
      case 24:
        this.utils.navegarPagina('/tabs/pokes-do-china-in-box');
        break;
      case 21:
        this.utils.navegarPagina('/tabs/gendai');
        break;
      case 17:
        this.utils.navegarPagina('/tabs/gokei');
        break;
      case 16:
        this.utils.navegarPagina('/tabs/owan');
        break;
      case 3:
        this.utils.navegarPagina('/tabs/koni');
        break;
      default:
        this.utils.navegarPagina('/tabs/home');
        break;
    }

    this.carrinhoService.calcular(carrinho);
  }

  atualizarCarrinho(carrinho: Array<any>, produto: any, loja: any) {
    carrinho.push(produto);

    this.utils.setStorage('sugestoes', this.utils.getStorage('sugestoes-loja'));
    this.utils.setStorage('lojaId', loja.id);
    this.utils.setStorage('loja_carrinho', loja);
    this.utils.setStorage('marca_carrinho', loja.marcaId);
    this.utils.setStorage(
      'modalidade_carrinho',
      this.utils.getStorage('modalidade')
    );
    this.utils.setStorage('carrinho', carrinho);
    this.events.publish('atualizar-tabs');
    this.events.publish('atualizar-carrinho', { limpar: false });
    this.events.publish('carrinho-numero', Date.now());
  }

  async selecionarObservacao(item, indexOpc, indexObs, tipo) {
    let regra = this.produto.observacoes[indexObs].regra;
    let itensObservacoes;

    switch (tipo) {
      case 'obrigatorio':
        itensObservacoes = this.produto.observacoes[indexObs].obrigatorios;
        break;

      case 'opcional':
        itensObservacoes = this.produto.observacoes[indexObs].opcionais;
        break;
      default:
        break;
    }

    let qtdSelecionado = 0;
    itensObservacoes.forEach((element) => {
      if (element.selecionado) {
        qtdSelecionado++;
      }
    });

    setTimeout(() => {
      if (qtdSelecionado > regra && regra > 1) {
        item.selecionado = false;
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida', 'erro'
        );
      } else {
        if (this.produto.observacoes[indexObs].tipo_preco == 'preco') {
          let totalAdicionais = 0;

          this.produto.observacoes.forEach((element) => {
            element.adicionais.forEach((item: any) => {
              totalAdicionais += item.qtd * item.preco.valor;
            });
          });

          if (tipo == 'obrigatorio' && regra == 1 && qtdSelecionado >= regra) {

            this.produto.preco.valor -= this.produto.observacoes[indexObs].preco_escolha * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );

            itensObservacoes.forEach((element, i) => {
              if (i != indexOpc) {
                if (element.selecionado) {
                  element.selecionado = false;
                }
              }

              if (i == indexOpc) {
                if (!element.selecionado) {
                  element.selecionado = false;
                } else {

                  this.produto.preco.valor += item.preco.valor * this.produto.qtd;
                  this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
                  this.produto.preco.texto = this.utils.floatToReal(
                    this.produto.preco.valor
                  );

                  this.produto.observacoes[indexObs].preco_escolha = item.preco.valor;

                  element.selecionado = true;
                }
              }
            });
          } else if (item.selecionado) {
            this.produto.preco.valor += item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
            this.produto.observacoes[indexObs].preco_escolha = item.preco.valor;

          } else {
            this.produto.preco.valor -= item.preco.valor * this.produto.qtd;
            this.produto.preco.valor_obrigatorio = this.produto.preco.valor - (this.produto.qtd * totalAdicionais);

            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
            this.produto.observacoes[indexObs].preco_escolha - item.preco.valor;

          }
        }
      }
    }, 10);
  }

  public async navegar(caminho: string): Promise<void> {
    const modal = await this.modalController.getTop();

    if (modal) {
      this.modalController.dismiss(true);
    }

    this.utils.navegarPagina(caminho);
  }

  filtroAdicionais(adicionais: Array<any>) {
    return adicionais.filter((item: any) => item.qtd > 0);
  }

  tabelaNutricional(tabela: string) {
    this.utils.openLink(tabela);
  }
}
