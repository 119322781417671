import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre-web',
  templateUrl: './sobre-web.component.html',
  styleUrls: ['./sobre-web.component.scss'],
})
export class SobreWebComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
