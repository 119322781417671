import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-notificacoes-web',
  templateUrl: './notificacoes-web.component.html',
  styleUrls: ['./notificacoes-web.component.scss'],
})
export class NotificacoesWebComponent implements OnInit {
  public notificacoes: Array<any> = [];
  // Todo define type
  public permissaoNotificacoes: any = {
    notificacoesGerais: {
      email: true,
      sms: true,
    },
    notificacoesMarketing: {
      email: true,
      sms: true,
    }
  };
  public notifica: any;
  public loading: boolean = false;
  public importado: boolean = false;

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private events: EventsService
  ) {

    if (this.utils.getStorage('cliente-importado') != null) {
      this.importado = true;
    }
  }

  ngOnInit() {
    this.buscarNotificacoes();
    this.definirPreferencias();
  }

  definirPreferencias() {
    const usuario = this.utils.getStorage('usuario');
    this.permissaoNotificacoes.notificacoesGerais.email = usuario.notificacoesGeraisEmail;
    this.permissaoNotificacoes.notificacoesGerais.sms = usuario.notificacoesGeraisSms;
    this.permissaoNotificacoes.notificacoesMarketing.email = usuario.notificacoesMarketingEmail;
    this.permissaoNotificacoes.notificacoesMarketing.push = usuario.notificacoesMarketingPush;
    this.permissaoNotificacoes.notificacoesMarketing.sms = usuario.notificacoesMarketingSms;
  }

  voltar() {
    this.utils.voltarPagina('tabs/perfil');
  }

  async buscarNotificacoes() {
    await this.utils.exibirLoading();

    const res = await this.api.get('notificacoes');

    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      this.notificacoes = res.data;
    }
  }

  async configurarNotificacoes() {
    this.utils.navegarPagina('tabs/configurar-notificacoes');
  }

  async salvarAlteracoes() {
    const req = {
      jwt: this.utils.getStorage('usuario').jwt,
      notificacoes: {
        gerais: this.permissaoNotificacoes.notificacoesGerais,
        marketing: this.permissaoNotificacoes.notificacoesMarketing,
      },
    };

    this.loading = true;

    const res = await this.api.put('cliente', req);

    this.loading = false;

    if (res.status !== 'success') {
      this.utils.exibirToast(res.message, 'erro');

      return;
    }
      let enderecos = this.utils.getStorage('enderecos');

      if (enderecos != null) {
        res.data.enderecos.unshift(enderecos[0]);
      }

      this.utils.exibirToast(res.message);
      this.utils.removeStorage('cliente-importado');
      this.utils.setStorage('usuario', res.data);
      this.utils.setStorage('endereco', res.data.enderecos[0]);
      this.utils.setStorage('enderecos', res.data.enderecos);
      this.utils.setStorage('endereco_confirmado', true);
      this.events.publish('atualizar-tags');

  }
}
