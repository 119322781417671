import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-cartoes-cadastrados-browser',
  templateUrl: './cartoes-cadastrados-browser.page.html',
  styleUrls: ['./cartoes-cadastrados-browser.page.scss'],
})
export class CartoesCadastradosBrowserPage implements OnInit {
  public cartoes: Array<any> = [];
  public cartao: any;
  public usuario: any;
  public rede_logo;
  public loja: any;


  constructor(
    private modalController: ModalController,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private keyboard: Keyboard,
    private platform: Platform,
    private events: EventsService,
    private modals: ModalsService
  ) {
    this.usuario = this.utils.getStorage('usuario');
    this.cartoes = this.usuario.cartoes;
   }

  ngOnInit() {
    this.events.subscribe('atualizar-cartoes', () => {
      this.usuario = this.utils.getStorage('usuario');
      this.cartao =
        this.usuario.cartoes.length > 0 ? this.usuario.cartoes[0] : null;
    });

    if (this.utils.getStorage('loja_carrinho')) {
      this.loja = this.utils.getStorage('loja_carrinho');
    }
  }

  fechar(){
    this.modalController.dismiss()
  }

  async abrirCadastroCartao() {
    await this.modals.fecharModal();

    if (this.loja.metodo_pagamento == 'braspag') {
      this.modals.modalAdicionarCartaoBrowser().then((res) =>{
        this.events.publish('atualizar-cartoes');
      });
    }else{
      this.modals.modalAdicionarCartaoBrowserMP().then((res) =>{
        this.events.publish('atualizar-cartoes');
      });
    }
  }

  async selecionarCartao(index: number) {

    const cartao = this.cartoes[index];
    this.cartoes.splice(index, 1);
    this.cartoes.unshift(cartao);
    this.usuario.cartoes = this.cartoes;
    this.utils.setStorage('usuario', this.usuario);
    this.events.publish('atualizar-cartoes');

    await this.modalController.dismiss(this.cartoes);
    // await this.modals.fecharModal();

  }

}
