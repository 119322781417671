import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from './../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sugestoes',
  templateUrl: './sugestoes.page.html',
  styleUrls: ['./sugestoes.page.scss'],
})
export class SugestoesPage implements OnInit {
  public produto: any;
  public sugestoes = this.utils.getStorage('sugestoes-loja');
  constructor(
    private modal: ModalController,
    private utils: UtilsService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.sugestoes.forEach((element) => {
      if (!element.preco_partir) {
        element.preco_partir = '';
      } else {
        element.preco_partir = element.preco_partir.texto;
      }
    });
    this.produto = this.utils.getStorage('produto');
  }

  fechar() {
    this.modal.dismiss();
    this.utils.voltarPagina('/tabs/home');
  }

  async seguir() {
    this.modal.dismiss();

    if (this.utils.getStorage('edicao-restaurante') == 'sim') {
      this.utils.alteraStatusBar('claro');
      this.utils.navegarPagina('/tabs/home');
      this.utils.removeStorage('edicao-restaurante');
    }

    if (this.utils.getStorage('sucesso_cpf') == true) {
      this.utils.alteraStatusBar('claro');

      await this.utils.exibirToast(
        'Para fazer um pedido é necessário cadastrar o CPF',
        'erro'
      );
      this.utils.navegarPagina('dados-pessoais');
    }

    this.utils.navegarPagina('finalizar-pedido');
  }

  async goToSugestao(produto) {
    this.utils.alteraStatusBar('claro');
    await this.modalController.getTop();
    this.utils.setStorage('produto', produto);
    this.modalController.dismiss('', 'sugestao', 'modal-sugestoes');
  }
}
