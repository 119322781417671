import { UtilsService } from './../../services/utils.service';
import { ApiService } from './../../services/api.service';
import { ModalController } from '@ionic/angular';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/services/modals/modals.service';

declare let dataLayer: any;
@Component({
  selector: 'app-search-produto-browser',
  templateUrl: './search-produto-browser.page.html',
  styleUrls: ['./search-produto-browser.page.scss'],
})
export class SearchProdutoBrowserPage implements OnInit {
  public localizacao = this.utils.getStorage('endereco');
  public cardapios = [];
  public no_results = false;
  public modalidadeAtiva: string = 'DLV';
  public error_msg: string;
  public isBrowser: Boolean = false;
  public idLoja;
  public cardapioFiltrado = [];

  constructor(
    private modalController: ModalController,
    private http: ApiService,
    private utils: UtilsService,
    private modalService: ModalsService,
    private el: ElementRef
  ) {
    if (this.utils.getStorage('modalidade') != null) {
      this.modalidadeAtiva = this.utils.getStorage('modalidade').codigo;
    }
  }

  itemsEvent(object) {
    let items = [];

    object.produtos.forEach((element) => {
      let preco_apartir;
      if (element.preco_apartir)
        preco_apartir = element.preco_apartir.split(' ')[1];

      items.push({
        item_list_id: object.marca_id.toString(),
        item_list_name: object.nome,
        item_id: element.codigo,
        item_name: element.nome,
        item_category: element.categoria,
        item_brand: object.marca_nome,
        price:
          element.preco_unitario !== '0.00'
            ? parseFloat(element.preco_unitario)
            : parseFloat(preco_apartir),
        currency: 'BRL',
        quantity: 1,
      });
    });

    return items;
  }

  async ngOnInit() {
  }

  ionViewWillEnter() {
    if (this.el.nativeElement.offsetWidth >= 768) {
      this.isBrowser = true;
    }
  }

  async getProdutos() {
    let resData: any = {
      lat: this.localizacao.lat,
      long: this.localizacao.lng,
      ...(this.utils.getStorage('loja') !== null) && { marca_id: this.utils.getStorage('loja').marcaId }
    };

    const res = await this.http.get('buscar_produtos', resData);

    if (res.status != 'success') {
      this.cardapios = [];
      return this.utils.exibirToast(res.message, 'erro');
    }

    this.idLoja = res.data.id;
    res.data.cardapios.forEach(cardapio => {
      this.cardapios.push(cardapio)
    });

    this.cardapios.forEach((element) => {
      let event = {
        event: 'view_item_list',
        ecommerce: {
          items: this.itemsEvent(element),
        },
      };

      dataLayer.push({ ecommerce: null });
      dataLayer.push(event);
    });
  }

  async buscarProdutos(keyword) {
    this.cardapios = [];

    let resData: any = {
      lat: this.localizacao ? this.localizacao.lat : '',
      long: this.localizacao ? this.localizacao.lng : '',
      modalidadeId: this.modalidadeAtiva
    };

    if (keyword != '') {
      resData.keyword = keyword;
    };

    if (keyword == '') {
      return
    };

    const res = await this.http.get('buscar_produtos', resData);

    if (res.status != 'success') {
      this.cardapios = [];
      this.no_results = true;
      this.error_msg = res.message;
      return this.utils.exibirToast(res.message, 'erro');
    }

    this.idLoja = res.data.id;
    res.data.forEach(cardapio => {
      this.cardapios.push(cardapio)
    });

    this.cardapios = this.cardapios.filter(cardapio =>
      cardapio.produtos.some(produto => produto.result !== "empty")
    );

    this.no_results = false;

  }

  async abrirProduto(produto: any) {

    this.utils.setStorage('produto', produto);

    if(this.localizacao === null) {
      this.modalController.getTop();
      this.fechar()
      return this.modalService.modalProdutoBrowser(this.isBrowser);
    }

    const req = {
      lojaId: produto.loja ? produto.loja.id : produto.lojaId,
      marcaId: produto.loja ? produto.loja.marcaId : produto.marcaId,
      produtoCode: produto.codigo ? produto.codigo : '',
      produtoId: produto.id,
      modalidadeId: this.modalidadeAtiva
    };

    await this.utils.exibirLoading();
    const res = await this.http.get('cardapio', req);

    this.utils.setStorage('loja', produto.loja);
    this.utils.setStorage('modalidade', { codigo: this.modalidadeAtiva });
    await this.utils.ocultarLoading();

    if (res.status === 'success') {
      if (res.data != null && res.data.produto != null) {
        this.utils.setStorage('produto', res.data.produto);
        this.utils.setStorage('sugestoes-loja', []);
        this.modalController.getTop();
        this.fechar()
        this.modalService.modalProdutoBrowser(this.isBrowser);
      } else {
        this.utils.exibirToast('Ops.. Infelizmente esse produto não está disponível para sua região!', 'erro');
      }
    }
  }


  fechar() {
    this.modalController.dismiss()
  }

}
