import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { EventsService } from './services/events.service';
import { LojaService } from 'src/app/services/loja/loja.service';
import { UtilsService } from './services/utils.service';
import { v4 as uuidv4 } from 'uuid';

declare let dataLayer: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private redes: Array<any> = [
    { marca: 'spoleto', id: '1' },
    { marca: 'chinainbox', id: '2' },
    { marca: 'koni', id: '3' },
    { marca: 'gurume', id: '4' },
    { marca: 'kisso', id: '6' },
    { marca: 'lebonton', id: '7' },
    { marca: 'risie', id: '8' },
    { marca: 'ctcburguer', id: '9' },
    { marca: 'strogonosso', id: '10' },
    { marca: 'giusto', id: '11' },
    { marca: 'kanePoke', id: '12' },
    { marca: 'hoaPoke', id: '13' },
    { marca: 'kapuapoke', id: '14' },
    { marca: 'banketto', id: '15' },
    { marca: 'owan', id: '16' },
    { marca: 'gokei', id: '17' },
    { marca: 'kohalaacai', id: '18' },
    { marca: 'kapuaacai', id: '19' },
    { marca: 'lefrango', id: '20' },
    { marca: 'gendai', id: '21' },
    { marca: 'kohalapoke', id: '22' },
    { marca: 'pokeschinainbox', id: '24' },
  ];

  constructor(
    private api: ApiService,
    private events: EventsService,
    private location: Location,
    private router: Router,
    private utilsService: UtilsService,
    private lojaService: LojaService,
    private readonly renderer: Renderer2
  ) {
    // const url = this.location.path();
    // const marca = url.split('/')[1];

    // const rede = this.redes.filter((rede) => {
    //   if (rede.marca === marca) {
    //     return rede;
    //   }
    // });

    // if (rede !== undefined && rede.length > 0) {
    //   this.verificaParametro(rede[0]);
    // }

    // this.events.subscribe('goToMenyByUrl', (res) => {
    //   this.verificaParametro(res);
    // });

    this.verificaParametro({ marca: 'gendai', id: '21' });

    this.startApp();
  }

  ngOnInit(): void {
    this.viewPage();
  }

  startApp() {
    let uuid = uuidv4();

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` + uuid;
    script.id = 'metrix_head';
    script.async = true;
    this.renderer.appendChild(document.head, script);

    const script2 = this.renderer.createElement('noscript') as HTMLScriptElement;
    const scriptBody = this.renderer.createElement('iframe') as HTMLScriptElement;
    scriptBody.src = `https://h.online-metrix.net/fp/tags.js?org_id=k8vif92e&session_id=braspag_grupotrigo_split` + uuid;
    scriptBody.id = 'metrix_body';
    script2.id = 'metrix_noscript';
    scriptBody.style.width = '100px'
    scriptBody.style.height = '100px'
    scriptBody.style.border = '0'
    scriptBody.style.position = 'absolute';
    scriptBody.style.top = '-5000px';

    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(document.body, script2);

    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(document.body, script2);

    this.verificaParametro({ marca: 'gendai', id: '21' });
  }

  private viewPage(): void {
    this.router.events.subscribe((event) => {
      let tipo_evento: string = 'virtal_page_view';

      if (this.utilsService.getStorage('canal-compra') == 'whatsapp') {
        tipo_evento = 'page_view_whats_app';
      }

      if (event instanceof NavigationEnd) {
        dataLayer.push({
          event: tipo_evento,
          path: event.url,
        });

        if (this.utilsService.getStorage('usuario')) {
          dataLayer.push({
            event: 'user_id',
            path: event.url,
            user_id: this.utilsService.getStorage('usuario').id_externo,
          });
        }
      }
    });
  }

  async verificaParametro(marca: any) {
    const endereco: any = this.utilsService.getStorage('endereco');
    const modalidade =
      this.utilsService.getStorage('modalidade') !== null
        ? this.utilsService.getStorage('modalidade').codigo
        : 'DLV';

    this.lojaService.buscarLoja(endereco, modalidade, marca.id).then((resp) => {
      this.utilsService.setStorage('redirect_marca', true);
      this.utilsService.setStorage('dadosMarca', { marca: marca.marca, id: marca.id });
    });
  }
}
