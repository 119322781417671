import { EventsService } from 'src/app/services/events.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-excluir-cartao-web',
  templateUrl: './excluir-cartao-web.page.html',
  styleUrls: ['./excluir-cartao-web.page.scss'],
})
export class ExcluirCartaoWebPage implements OnInit {

  public loading: boolean = false;
  public loja: any = this.utils.getStorage('loja');

  constructor(
    private utils: UtilsService,
    private api: ApiService,
    private events: EventsService,
    private modals: ModalsService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter(){
   this.loja = this.utils.getStorage('loja');
  }

  async continuar() {

    let usuario = this.utils.getStorage('usuario');
    let cartoes: Array<any> = usuario.cartoes;

    const index = this.utils.getStorage('index-cartao');

    let cartao = cartoes[index];

    const req = {
      cartao_id: cartao.id
    };

    this.loading = true;

    let res = null;

    if (this.loja.metodo_pagamento == 'braspag') {
      res = await this.api.delete('cartao_cielo', req);
    }else{
      res = await this.api.delete('cartao_mercadopago', req);
    }

    this.loading = false;

    if (res.status === 'success') {

      cartoes.splice(index, 1);

      usuario.cartoes = cartoes;
      this.utils.setStorage('usuario', usuario);

      this.utils.exibirToast(res.message);
      this.events.publish('atualizar-cartoes');
      this.modals.fecharModal();

    } else {
      this.utils.exibirToast(res.message, 'erro');
    }

  }

  cancelar() {
    this.modals.fecharModal();
  }

}
