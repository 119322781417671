import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '../analytics/analytics.service';
import { ApiService } from '../api.service';
import { BannersService } from '../banners/banners.service';
import { ModalidadeService } from 'src/app/services/modalidade/modalidade.service';
import { UtilsService } from '../utils.service';
import { EventsService } from '../events.service';

let dataLayer: any;
@Injectable({
  providedIn: 'root',
})
export class LojaService {
  public lojaSubject = new BehaviorSubject<any>(null);
  loja$: Observable<any> = this.lojaSubject.asObservable();

  private cardapioSubject = new BehaviorSubject<any[]>(null);
  public cardapio$: Observable<any[]> = this.cardapioSubject.asObservable();

  private carregandoSubject = new BehaviorSubject<boolean>(false);
  carregando$: Observable<boolean> = this.carregandoSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private bannersService: BannersService,
    private modalidadeService: ModalidadeService,
    private utilsService: UtilsService,
    private events: EventsService
  ) {
    const loja = this.utilsService.getStorage('loja');

    if (loja) this.lojaSubject.next(loja);
  }

  private atualizarLoja(loja: any): void {
    this.lojaSubject.next(loja);
    this.modalidadeService.modalidadesDisponiveis(loja);
    this.utilsService.setStorage('loja', loja);
  }

  public async buscarLoja(
    endereco: any,
    modalidade: string = 'DLV',
    redeId: number
  ): Promise<any> {
    if (redeId == null) {
      return;
    }
    this.carregandoSubject.next(true);
    let req: any = {
      marcaId: redeId,
      modalidadeId: modalidade,
    };
    if (endereco !== null) {
      req = {
        ...req,
        lat: endereco.lat || endereco.latitude,
        lng: endereco.lng || endereco.longitude,
      };
    }

    await this.apiService.get('lojas', req).then(async (resp) => {
      const loja: any = resp.data['loja'].loja;
      const cardapio: any = resp.data['cardapio'];

      if (resp.status === 'success' && loja !== null && loja !== undefined && ((Array.isArray(loja) && loja.length !== 0) || (!Array.isArray(loja) && Object.keys(loja).length !== 0))) {
        this.atualizarLoja(loja);
        this.bannersService.buscarBanners(loja.marcaId, 'cardapio');

        if (cardapio.length == 0 && endereco == null) {
          this.buscarCardapio(loja, modalidade);
        } else {
          this.atualizarCardapio(cardapio);
        }

        let usuario = this.utilsService.getStorage('usuario');

        if (usuario != null && usuario.jwt != null) {
          const carteira = await this.apiService.get('carteira', { 'metodo': (loja.metodo_pagamento != null) ? loja.metodo_pagamento : 'mercadopago' });

          if (carteira.data) {
            usuario.cartoes = carteira.data
          } else {
            usuario.cartoes = [];
          }

          this.utilsService.setStorage('usuario', usuario);
          this.events.publish('atualizar-cartoes');
        }

        return loja;
      } else {
        this.atualizarLoja(null);
        this.buscarCardapio(cardapio);

        const event = this.analyticsService.address_not_answered(endereco);
        this.analyticsService.registrarEvento(event);

        return null;
      }
    });

    this.carregandoSubject.next(false);
    return this.lojaSubject.value;
  }

  public async buscarLojasEnderecos(endereco: any, modalidade: string = 'DLV'): Promise<any> {
    this.carregandoSubject.next(true);
    let req: any = {
      modalidadeId: modalidade,
    };
    if (endereco !== null) {
      req = {
        ...req,
        lat: endereco.lat || endereco.latitude,
        lng: endereco.lng || endereco.longitude,
      };
    }

    const resp = await this.apiService.get('lojas_home', req)

    const loja: any = resp.data['loja'];

    if (
      resp.status === 'success' &&
      loja !== null &&
      loja !== undefined &&
      ((Array.isArray(loja) && loja.length !== 0) ||
        (!Array.isArray(loja) && Object.keys(loja).length !== 0))
    ) {

      return loja;

    } else {

      const event = this.analyticsService.address_not_answered(endereco);
      this.analyticsService.registrarEvento(event);
      return null;

    }

  }

  public atualizarCardapio(cardapio: any[] | null): any {
    if (
      cardapio !== null &&
      cardapio !== undefined &&
      cardapio.length !== 0 &&
      Object.keys(cardapio).length !== 0
    ) {
      this.cardapioSubject.next(cardapio);
      return cardapio;
    } else {
      this.cardapioSubject.next(null);
      return null;
    }
  }

  public async buscarCardapio(
    loja: any | null,
    modalidade: string = 'DLV'
  ): Promise<any> | null {
    let req: any = {
      lojaId: loja?.id ? loja?.id : loja?.loja_id,
      marcaId: loja?.marcaId ? loja?.marcaId : loja?.marca_id,
      modalidadeId: modalidade,
    };
    console.log('req', req);

    const res = await this.apiService.get('cardapio', req);

    this.carregandoSubject.next(false);

    let cardapio: any[] = res.data.cardapio;

    if (res.status === 'success') {
      this.atualizarCardapio(cardapio);
    } else {
      this.atualizarCardapio(null);
    }
  }

  public async verificarFidelidadeNestaMarca(marcaId: number) {
    const req = {
      jwt: this.utilsService.getStorage('usuario')?.jwt,
      marcaId: marcaId,
    }

    await this.apiService.get('verificar_fidelidade', req);
  }
}
